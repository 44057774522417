import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { OrderManagementTableRow } from "../../Components/PageComponents/Order/ordersUtils";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NewOrderForm from "../../Components/PageComponents/Order/NewOrderForm";
import Modal from "../../Components/MaterialUI/Modal";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import OrderManagementTable from "../../Components/PageComponents/Order/OrderManagementTable";
import { getQueryFetchAllOrders } from "../../Components/PageComponents/Order/ordersApi";
import { GetQueryFetchAllOrdersSnippet } from "../../Components/PageComponents/Order/apiOrdersSnippets";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";

const modalTitleTranslations = {
  "Create New Order": "Create New Order",
} as const;

type ModalTitle = keyof typeof modalTitleTranslations;

const OrdersManagementPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [orderTableRows, setOrderTableRows] = useState<OrderManagementTableRow[]>([]);
  const [ordersData, setOrdersData] = useState<GetQueryFetchAllOrdersSnippet>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const fetchOrderData = async () => {
    try {
      const orders = await callApi<GetQueryFetchAllOrdersSnippet>({
        query: getQueryFetchAllOrders(),
        auth: { setAuthedUser },
      });
      setOrdersData(orders);

      const transformedData: OrderManagementTableRow[] = orders.map((order) => {
        const orderOptionsMap = order.order_options.reduce(
          (acc: { [key: string]: string }, option) => {
            const key = option.option_name.replace(/\s+/g, "_").toLowerCase();
            acc[key] = option.option_value;
            return acc;
          },
          {}
        );

        return {
          order_number: order.order_number,
          sample_name: order.sample_name,
          contact_person_development: order.contact_person_development,
          contact_person_ppm: order.contact_person_ppm,
          quantity_panels: order.quantity_panels,
          me_sampling_needed_panels: order.me_sampling_needed_panels,
          quantity_pcbs: order.quantity_pcbs,
          business_unit: order.business_unit,
          preferred_date: order.preferred_date,
          material_availability_date: order.material_availability_date,
          cost_center: order.cost_center,
          panels: order.panels,
          assembly_board: order.assembly_board,
          number_of_board_bare_pcb: order.number_of_board_bare_pcb,
          number_of_board_bare_panel: order.number_of_board_bare_panel,
          windchill_link: order.windchill_link,
          sap_order_number: order.sap_order_number,
          status: order.status,
          order_new_stencil: orderOptionsMap["order_new_stencil"] || "",
          stencil_order_speed: orderOptionsMap["stencil_order_speed"] || "",
          spi_report: orderOptionsMap["spi_report"] || "",
          thermoprofile_measurement: orderOptionsMap["thermoprofile_measurement"] || "",
          aoi: orderOptionsMap["aoi"] || "",
          coverage_report: orderOptionsMap["coverage_report"] || "",
          spc_report: orderOptionsMap["spc_report"] || "",
          cross_section_analisys: orderOptionsMap["cross_section_analisys"] || "",
        };
      });

      setOrderTableRows(transformedData);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<CreateNewOrder setModalTitle={setModalTitle} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        {t("Orders Management")}
      </Typography>

      <Stack spacing={2}>
        <OrderManagementTable
          orderTableRows={orderTableRows}
          loading={!ordersData.length}
        />
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth="lg"
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalTitle === "Create New Order" ? (
          <NewOrderForm
            setModalTitle={setModalTitle}
            fetchOrderData={fetchOrderData}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OrdersManagementPage;

interface CreateNewOrderProps {
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
}

const CreateNewOrder: React.FC<CreateNewOrderProps> = ({ setModalTitle }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <IconButton
        aria-label="create new order"
        onClick={() => setModalTitle("Create New Order")}
      >
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        {t("Add")}
      </Typography>
    </Stack>
  );
};
