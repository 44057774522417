import { createBrowserRouter } from "react-router-dom";
import ROUTES_MAPPING from "./routesMapping";
import ConnectionsPage from "../../pages/Integrator/Connections/ConnectionsPage";
import OpcDaConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcDaConnectionInformationPage";
import OpcUaConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcUaConnectionInformationPage";
import MyDashboardsPage from "../../pages/MyDashboards/MyDashboardsPage";
import ExcellenceDashboardPage from "../../pages/MyDashboards/ExcellenceDashboardPage";
import MyFormsPage from "../../pages/MyForms/MyFormsPage";
import OperationsFormPage from "../../pages/MyForms/OperationsFormPage";
import OpcMQTTConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcMQTTConnectionInformationPage";
import LabelRecognitionPage from "../../pages/VisionControl/LabelRecognitionPage";
import Profile from "../../pages/Account/Profile";
import OpcModbusConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcModbusConnectionInformationPage";
import AuthLayout from "../AuthLayout";
import MaintenancePage from "../../pages/Maintenance/MachineParkPage";
import MaintenancePlan from "../../pages/Maintenance/MaintenancePlanPage";
import InventoryPage from "../../pages/MyWarehouses/InventoryPage";
import TestPlans from "../../pages/TestPlans/TestPlans";
import TestPlanPage from "../../pages/TestPlans/TestPlanPage";
import CodeScanningPage from "../../pages/ObjectTracking/CodeScanningPage";
import Rules from "../../pages/DataManager/Rules/Rules";
import MaintainLocationsPage from "../../pages/MyWarehouses/MaintainLocationsPage";
import CreateConnection from "../../pages/Integrator/CreateConnection";
import ProjectsPage from "../../pages/MyProjects/ProjectsPage";
import QualityParametersStaticForm from "../../Components/PageComponents/Operations/MyForms/QualityParametersStaticForm";
import PageNotFound from "../../Components/SmallComponents/PageNotFound/PageNotFound";
import ShiftPlanning from "../../pages/ShiftPlanning/ShiftPlanning";
import MyProcesses from "../../pages/MyProcesses/MyProcesses";
import ProcessPage from "../../pages/MyProcesses/ProcessPage";
import ProductsPage from "../../pages/Products/ProductsPage";
import AnomalyDetectionPage from "../../pages/AnomalyDetection/AnomalyDetectionPage";
import ConditionMonitoringPage from "../../pages/Maintenance/ConditionMonitoringPage";
import MaintenanceKPIsPage from "../../pages/StandardKPIs/MaintenanceKPIsPage";
import OpExKPIsPage from "../../pages/StandardKPIs/OpExKPIsPage";
import OEELossesPage from "../../pages/OEE/OEELossesPage";
import OEEAssetsPage from "../../pages/OEE/OEEAssetsPage";
import OEECategoriesPage from "../../pages/OEE/OEECategoriesPage";
import KanBanBoardPage from "../../pages/KanbanWorkflows/KanBanBoardPage/KanBanBoardPage";
import OEEDashboardPage from "../../pages/OEE/OEEDashboardPage";
import TaskManager from "../../pages/TaskManager/TaskManagerPage";
import TaskDesignerPage from "../../pages/TaskManager/TaskDesignerPage";
import OrdersManagementPage from "../../pages/Orders/OrdersManagementPage";
import LongTermPlanning from "../../pages/LongTermPlanning/LongTermPlanning";
import WorkflowsPage from "../../pages/KanbanWorkflows/WorkflowsPage/WorkflowsPage";
import WorkflowSinglePage from "../../pages/KanbanWorkflows/WorkflowsPage/WorkflowSinglePage";
import OrdersDetailsPage from "../../pages/Orders/OrdersDetailsPage";
import OrdersPage3 from "../../pages/Orders/OrdersPage3";
import PredictiveMaintenancePage from "../../pages/Maintenance/PredictiveMaintenancePage";
import MaterialsPage from "../../pages/Materials/MaterialsPage";
import PidCharts from "../../pages/PidCharts/PidWorkflows";
import SinglePidChart from "../../pages/PidCharts/SinglePidWorkflow";
import ComingSoonPage from "../../Components/SmallComponents/ComingSoonPage/ComingSoonPage";
import CurrenciesPage from "../../pages/Elprom/CurrenciesPage";
import ProjectHubDataPage from "../../pages/Elprom/ProjectHubDataPage";
import ElpromClientOrderPage from "../../pages/Elprom/ElpromClientOrderPage";
import ElpromKsoClientOrderPage from "../../pages/Elprom/ElpromKsoClientOrderPage";
import { getCurrentEnv } from "../../Global/Utils/commonFunctions";

const IS_ELRPOM_ENV = getCurrentEnv() === "elprom";

export default createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      //  MY DASHBOARDS
      {
        path: "/",
        element:
          // <ExampleTableGrid />
          IS_ELRPOM_ENV ? <CurrenciesPage /> : <MyDashboardsPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Financial KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Maintenance KPIs"],
        element: <MaintenanceKPIsPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Energy KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-OpEx KPIs"],
        element: <OpExKPIsPage />,
      },
      {
        path: ROUTES_MAPPING["Dashboards"],
        element: <MyDashboardsPage />,
      },
      {
        path: ROUTES_MAPPING.excellenceDashboard,
        element: <ExcellenceDashboardPage />,
      },
      {
        path: ROUTES_MAPPING["Anomaly Detection"],
        element: <AnomalyDetectionPage />,
      },
      {
        path: ROUTES_MAPPING["P&ID Charts"],
        element: <PidCharts />,
      },
      {
        path: ROUTES_MAPPING.pidChart,
        element: <SinglePidChart />,
      },

      // MY FORMS
      {
        path: ROUTES_MAPPING["Forms"],
        element: <MyFormsPage />,
      },
      {
        path: ROUTES_MAPPING.operationsForm,
        element: <OperationsFormPage />,
      },

      // OEE
      {
        path: ROUTES_MAPPING["OEE-Dashboard"],
        element: <OEEDashboardPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Losses"],
        element: <OEELossesPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Assets"],
        element: <OEEAssetsPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Categories"],
        element: <OEECategoriesPage />,
      },

      // MY PROJECTS
      {
        path: ROUTES_MAPPING["Projects"],
        element: <ProjectsPage />,
      },
      // ORDERS
      {
        path: ROUTES_MAPPING["Orders-Orders Information"],
        element: <OrdersPage3 />,
      },
      {
        path: ROUTES_MAPPING["Orders-Orders Management"],
        element: <OrdersManagementPage />,
      },
      {
        path: ROUTES_MAPPING["Orders-Orders Details"],
        element: <OrdersDetailsPage />,
      },
      // SHIFT PLANNING
      {
        path: ROUTES_MAPPING["Shift Planning"],
        element: <ShiftPlanning />,
      },
      // LONG-TERM PLANNING
      {
        path: ROUTES_MAPPING["Long-term Planning"],
        element: <LongTermPlanning />,
      },
      // CAPACITIES
      {
        path: ROUTES_MAPPING["Capacities"],
        element: <ComingSoonPage />,
      },
      // CAPABILITIES
      {
        path: ROUTES_MAPPING["Capabilities"],
        element: <ComingSoonPage />,
      },
      // MY WORKFLOWS
      {
        path: ROUTES_MAPPING["Processes"],
        element: <MyProcesses />,
      },
      {
        path: ROUTES_MAPPING["Workflows-Workflow Modeler"],
        element: <WorkflowsPage />,
      },
      {
        path: ROUTES_MAPPING["Workflows-Task Designer"],
        element: <TaskDesignerPage />,
      },
      {
        path: ROUTES_MAPPING["Workflows-Task Manager"],
        element: <TaskManager />,
      },
      {
        path: ROUTES_MAPPING.process,
        element: <ProcessPage />,
      },
      {
        path: ROUTES_MAPPING.workflow,
        element: <WorkflowSinglePage />,
      },
      // Kanban BOARD
      {
        path: ROUTES_MAPPING["Workflows-Kanban Board"],
        element: <KanBanBoardPage />,
      },

      // INTEGRATOR
      {
        path: ROUTES_MAPPING["GIANT Integrator-Data Sources"],
        element: <CreateConnection />,
      },
      {
        path: ROUTES_MAPPING["GIANT Integrator-Connections"],
        element: <ConnectionsPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Integrator-Connections-opc-da-information"],
        element: <OpcDaConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Integrator-Connections-opc-ua-information"],
        element: <OpcUaConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Integrator-Connections-opc-mqtt-information"],
        element: <OpcMQTTConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Integrator-Connections-opc-modbus-information"],
        element: <OpcModbusConnectionInformationPage />,
      },

      // VISION_CONTROL
      {
        path: ROUTES_MAPPING["Vision Control-Label Recognition"],
        element: <LabelRecognitionPage />,
      },
      // QUALITY DATA
      {
        path: ROUTES_MAPPING["Quality Data"],
        element: <ComingSoonPage />,
      },

      // QUALITY PARAMETERS
      {
        path: ROUTES_MAPPING["Quality Parameters"],
        element: <QualityParametersStaticForm />,
      },

      // TEST PLANS
      {
        path: ROUTES_MAPPING["Test Plans"],
        element: <ComingSoonPage />,
      },

      // RESERVED SAMPLES
      {
        path: ROUTES_MAPPING["Reserve Samples"],
        element: <ComingSoonPage />,
      },

      // PROFILE
      {
        path: ROUTES_MAPPING.accountProfile,
        element: <Profile />,
      },
      // MAINTENANCE
      {
        path: ROUTES_MAPPING["Maintenance-Machine Park"],
        element: <MaintenancePage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Maintenance Plan"],
        element: <MaintenancePlan />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Condition Monitoring"],
        element: <ConditionMonitoringPage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Predictive Maintenance"],
        element: <PredictiveMaintenancePage />,
      },
      // MY WAREHOUSES
      {
        path: ROUTES_MAPPING["Stock"],
        element: <InventoryPage />,
      },
      {
        path: ROUTES_MAPPING["Locations"],
        element: <MaintainLocationsPage />,
      },

      // OBJECT TRACKING
      {
        path: ROUTES_MAPPING["Object Tracking"],
        element: <CodeScanningPage />,
      },
      // SHIPMENTS
      {
        path: ROUTES_MAPPING["Shipments"],
        element: <ComingSoonPage />,
      },
      // MATERIALS
      {
        path: ROUTES_MAPPING["Materials"],
        element: <MaterialsPage />,
      },
      // PRODUCTS
      {
        path: ROUTES_MAPPING["Products"],
        element: <ProductsPage />,
      },
      // GIANT USER MANAGER
      {
        path: ROUTES_MAPPING["GIANT User Manager"],
        element: <ComingSoonPage />,
      },
      // Test Plans
      {
        path: ROUTES_MAPPING["Test Plans"],
        element: <TestPlans />,
      },
      {
        path: ROUTES_MAPPING.testPlan,
        element: <TestPlanPage />,
      },
      // Rules
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Rules"],
        element: <Rules />,
      },

      // MANUAL PARAMETERS
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Manual Entries-Manual Parameters-New"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Manual Entries-Manual Parameters-Edit"],
        element: <ComingSoonPage />,
      },

      // SETPOINT MANAGEMENT
      {
        path: ROUTES_MAPPING[
          "GIANT Data Manager-Manual Entries-Setpoint Management-Edit"
        ],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING[
          "GIANT Data Manager-Manual Entries-Setpoint Management-Approve"
        ],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING[
          "GIANT Data Manager-Manual Entries-Setpoint Management-History"
        ],
        element: <ComingSoonPage />,
      },

      // MEASUREMENT CONFIGURATION
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Measurement Configuration"],
        element: <ComingSoonPage />,
      },

      // VIEW LOGS
      {
        path: ROUTES_MAPPING["GIANT Data Manager-View Logs"],
        element: <ComingSoonPage />,
      },

      // WATCHDOGS
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Watchdogs-New"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Watchdogs-Edit"],
        element: <ComingSoonPage />,
      },

      // ARCHIVING
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Archiving-Move to Archive"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["GIANT Data Manager-Archiving-Restore Archive"],
        element: <ComingSoonPage />,
      },

      {
        path: ROUTES_MAPPING["GIANT Data Manager-Rules"],
        element: <Rules />,
      },
      // Elprom
      {
        path: ROUTES_MAPPING["currencies"],
        element: <CurrenciesPage />,
      },
      {
        path: ROUTES_MAPPING["projectHubData"],
        element: <ProjectHubDataPage />,
      },
      {
        path: ROUTES_MAPPING["projectHubClientOrder"],
        element: <ElpromClientOrderPage />,
      },
      {
        path: ROUTES_MAPPING["projectHubKsoClientOrder"],
        element: <ElpromKsoClientOrderPage />,
      },
      // CATCH ALL PATHS
      {
        path: "/*",
        element: <PageNotFound />,
      },
    ],
  },
]);
