import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Divider, useTheme, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  findTextInNavItems,
  ModalHelpTitle,
  modalHelpTitleTranslations,
  PageKey,
} from "../../Components/PageComponents/Help/helpUtils";
import {
  GetQueryHelpPageKeysSnippet,
  GetQueryHelpSelectionsForPage,
} from "../../Api/Help/apiHelpSnippets";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  getQueryHelpPageKeys,
  getQueryHelpPageSelections,
} from "../../Api/Help/apiHelpGetQueries";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssFontStyles from "../../Global/Styles/font";
import Button from "../../Components/MaterialUI/Button";
import Modal from "../../Components/MaterialUI/Modal";
import AddHelpContentForm from "../../Components/PageComponents/Help/AddHelpContentForm";
import { getModulePermissions } from "../../context/authContextUtils";
import HelpContentSelectionCard from "../../Components/PageComponents/Help/HelpContentSelectionCard";
import UploadHelpFile from "../../Components/PageComponents/Help/UploadHelpFile";
import { useLanguageContext } from "../../context/LanguageContext";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Alert from "../../Components/MaterialUI/Alert";
import ReportBugProposeAnImprovementForm from "../../Components/PageComponents/Help/ReportBugProposeAnImprovementForm";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";

interface HelpContentProps {
  locationPath: string;
}

const HelpPage: React.FC<HelpContentProps> = ({ locationPath }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };
  const [loading, setLoading] = useState<boolean>(true);
  const { authedUser, setAuthedUser } = useAuthedContext();
  const [currentPage, setCurrentPage] = useState<PageKey>();
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageSelections, setPageSelections] = useState<GetQueryHelpSelectionsForPage>([]);
  const userHelpPermissions = getModulePermissions(authedUser, "help");
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string>("");
  const [uploadType, setUploadType] = useState<"video" | "image" | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);

  const canUserEditAddDelete =
    userHelpPermissions?.edit && userHelpPermissions?.add && userHelpPermissions?.delete;
  const [modalTitle, setModalTitle] = useState<ModalHelpTitle | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalHelpTitleTranslations);

  useEffect(() => {
    fetchHelpContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPath]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const fetchHelpContent = async () => {
    setLoading(true);
    try {
      const foundPageText = findTextInNavItems(locationPath);

      if (foundPageText) {
        setPageTitle(foundPageText);
        const pageKeys = await callApi<GetQueryHelpPageKeysSnippet>({
          query: getQueryHelpPageKeys(),
          auth: { setAuthedUser },
        });
        const page = pageKeys.find((key) => key.page_key === foundPageText);

        if (page) {
          setCurrentPage(page);
          const helpSelections = await callApi<GetQueryHelpSelectionsForPage>({
            query: getQueryHelpPageSelections(page.id),
            auth: { setAuthedUser },
          });
          setPageSelections(helpSelections);
        }
      }
    } catch (error) {
      console.log("Failed to load Help content", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleModalOpen = (label: ModalHelpTitle) => {
    if (label === "Add Help Content") {
      setEditorContent("");
    }
    setModalTitle(label);
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        padding={2}
      >
        <Box component="div">
          <Typography variant="h4" mb={2}>
            {pageTitle}
          </Typography>

          {loading ? (
            <Typography variant="body2">{t("Loading...")}</Typography>
          ) : (
            <>
              {pageSelections.length === 0 ? (
                <Typography variant="body2">
                  {t("No help content available at the moment.")}
                </Typography>
              ) : (
                pageSelections.map((pageSelection) => (
                  <HelpContentSelectionCard
                    key={pageSelection.id}
                    helpSelection={pageSelection}
                    canUserEditAddDelete={canUserEditAddDelete}
                    fetchHelpContent={fetchHelpContent}
                    pageKeyId={currentPage ? currentPage.id : undefined}
                    order={
                      pageSelections.length > 0
                        ? Math.max(
                            ...pageSelections.map((selection) => selection.order)
                          ) + 1
                        : 1
                    }
                    setModalTitle={setModalTitle}
                    editorContent={editorContent}
                    setEditorContent={setEditorContent}
                    uploadType={uploadType}
                    setUploadType={setUploadType}
                    currentPage={currentPage}
                    setUnsavedEditChanges={setUnsavedEditChanges}
                  />
                ))
              )}
            </>
          )}
        </Box>

        <Box component="div" marginTop={2}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            mb={1.5}
            spacing={1.5}
          >
            {canUserEditAddDelete ? (
              <>
                <Grid item xs={8} container justifyContent="center" spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      css={[styles.width100, styles.widthLimit15]}
                      variant="outlined"
                      onClick={() => handleModalOpen("Add Help Content")}
                    >
                      {t("Upload Content")}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <UploadHelpFile
                      currentPage={currentPage}
                      order={
                        pageSelections.length > 0
                          ? Math.max(
                              ...pageSelections.map((selection) => selection.order)
                            ) + 1
                          : 1
                      }
                      fetchHelpContent={fetchHelpContent}
                      uploadType={uploadType}
                      setUploadType={setUploadType}
                      setAlertMessage={setAlertMessage}
                      setAlertStatus={setAlertStatus}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container xs={8} justifyContent="center" spacing={2}>
              <Grid item xs={6}>
                <Button
                  css={[styles.width100, styles.widthLimit15]}
                  variant="outlined"
                  onClick={() => handleModalOpen("Report Bug")}
                >
                  {t("Report Bug")}
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  css={[styles.width100, styles.widthLimit15]}
                  variant="outlined"
                  onClick={() => handleModalOpen("Propose an Improvement")}
                >
                  {t("Propose an Improvement")}
                </Button>
              </Grid>
            </Grid>

            {alertMessage && (
              <Box
                component="div"
                mt={2}
                display="flex"
                justifyContent="center"
                width="100%"
              >
                <Alert
                  message={alertMessage}
                  showAlert={!!alertMessage}
                  severity={alertStatus}
                />
              </Box>
            )}
          </Grid>
          <Divider />
          <Box component="div" marginTop={2} paddingLeft={2}>
            <Typography variant="body2" mb={1}>
              <Link
                component={RouterLink}
                to="/release-notes"
                underline="none"
                color="primary"
              >
                {t("Release Notes")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth="md"
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalTitle === "Add Help Content" ? (
          <AddHelpContentForm
            pageKeyId={currentPage ? currentPage.id : undefined}
            order={
              pageSelections.length > 0
                ? Math.max(...pageSelections.map((selection) => selection.order)) + 1
                : 1
            }
            setModalTitle={setModalTitle}
            fetchHelpContent={fetchHelpContent}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            setUnsavedEditChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalTitle === "Report Bug" ? (
          <ReportBugProposeAnImprovementForm
            type={"bug"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalTitle === "Propose an Improvement" ? (
          <ReportBugProposeAnImprovementForm
            type={"feature_request"}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default HelpPage;
