import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { OrderDetailsTableRow } from "../../Components/PageComponents/Order/ordersUtils";

import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { GetQueryOrderDetailsSnippet } from "../../Components/PageComponents/Order/apiOrdersSnippets";
import { getQueryOrderDetails } from "../../Components/PageComponents/Order/ordersApi";
import OrderDetailsTable from "../../Components/PageComponents/Order/OrderDetailsTable";
import { useLanguageContext } from "../../context/LanguageContext";

const OrdersDetailsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [orderDetailsTableRows, setOrderDetailsTableRows] = useState<
    OrderDetailsTableRow[]
  >([]);
  const [ordersDetailsData, setOrdersDetailsData] = useState<GetQueryOrderDetailsSnippet>(
    []
  );

  const fetchOrderDetailsData = async () => {
    try {
      const ordersDetails = await callApi<GetQueryOrderDetailsSnippet>({
        query: getQueryOrderDetails(),
        auth: { setAuthedUser },
      });
      setOrdersDetailsData(ordersDetails);

      const transformedData: OrderDetailsTableRow[] = ordersDetails.map((order) => {
        return {
          sample_name: order.sample_name,
          task_name: order.task_name,
          data_field: order.data_field,
          data_value: order.data_value,
        };
      });

      setOrderDetailsTableRows(transformedData);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOrderDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        {t("Orders Details")}
      </Typography>

      <Stack spacing={2}>
        <OrderDetailsTable
          orderDetailsTableRows={orderDetailsTableRows}
          loading={!ordersDetailsData.length}
        />
      </Stack>
    </>
  );
};

export default OrdersDetailsPage;
