import { useEffect, useState } from "react";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { Stack, IconButton, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../Components/MaterialUI/Modal";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import { SupplyProduct, SupplyProductRow } from "./productsUtils";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import GLTFViewer from "../../Components/SmallComponents/GLTFViewer/GLTFViewer";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";

type ColActionType = {
  type: "View Product" | "View 3D";
  product: SupplyProduct;
} | null;

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "description", label: "Description", type: "string" },
  { id: "id", label: "ID", type: "string" },
  { id: "classification_id", label: "Classification ID", type: "string" },
  { id: "actions", label: "Actions", type: "button" },
];

interface ProductsTableProps {
  tableData: SupplyProduct[];
  loading: boolean;
}

const ProductsTable: React.FC<ProductsTableProps> = ({ tableData, loading }) => {
  const { t } = useLanguageContext();
  const [productRows, setProductRows] = useState<SupplyProductRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);

  useEffect(() => {
    (async () => {
      try {
        setProductRows(
          tableData.map((item) => {
            return {
              id: item.id,
              classification_id: item.classification_id || "",
              name: item.name,
              description: item.description || "",
              actions: (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    onClick={() =>
                      onActionClick({
                        product: item,
                        type: "View Product",
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      onActionClick({
                        product: item,
                        type: "View 3D",
                      })
                    }
                  >
                    <ThreeDRotationIcon />
                  </IconButton>
                </Stack>
              ),
            };
          })
        );
      } catch (error) {
        console.log("There was an error: ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
  };

  return (
    <>
      <ResponsiveTableGrid
        rows={productRows}
        colSchema={useTranslateFields(colSchema, ["label"])}
        loading={loading}
        responsive="responsive"
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
          //   columnVisibility: { id: false },
        }}
      />

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        label={openModal?.type ? t(openModal.type) : ""}
      >
        {openModal?.type === "View Product" ? (
          <ViewProduct product={openModal.product} />
        ) : null}

        {openModal?.type === "View 3D" ? (
          <Box style={{ width: "100%", height: "400px" }} component="div">
            <GLTFViewer modelPath="/camera.gltf" />
          </Box>
        ) : null}
      </Modal>
    </>
  );
};

export default ProductsTable;

interface ViewProductProps {
  product: SupplyProduct;
}

const ViewProduct: React.FC<ViewProductProps> = ({ product }) => {
  const { t } = useLanguageContext();
  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text={t("ID")} bolded={product.id} />
        <LabelWithBoldedPart text={t("Name")} bolded={product.name} />
        <LabelWithBoldedPart text={t("Description")} bolded={product.description || ""} />
        <LabelWithBoldedPart
          text={t("Classification ID")}
          bolded={product.classification_id || ""}
        />
        <LabelWithBoldedPart
          text={t("Material IDs")}
          bolded={product.material_ids.join(", ")}
        />
      </Stack>
    </Box>
  );
};
