import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BOSCH_TASK_NODE_TYPE,
  BoschAllTaskNodesData,
  BoschBnaNodeData,
  timeUnitSchemeOptions,
} from "../boschNodesUtils";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { v4 as uuidv4 } from "uuid";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { useEffect, useRef, useState } from "react";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import Button from "../../../../MaterialUI/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { endOfDay } from "date-fns";
import { getQueryMaintenanceMachines } from "../../../../../Api/Maintenance/apiMaintenanceGetQueries";
import { GetQueryMaintenanceMachinesSnippet } from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { SelectOption } from "../../../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import Select from "../../../../MaterialUI/FormFields/Select";
import DateAndTimePicker from "../../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import { useDetectFormsUnsavedChanges } from "../../../../../Global/Hooks/useDetectFormsUnsavedChanges";

type Condition = {
  key: string;
  value: string;
};
type Material = {
  material: string;
  quantity: string;
};

const allocationOption: SelectOption[] = handleGetSelectOption([
  "Dynamically",
  "Manually",
]);

interface BoschGeneralFormProps {
  handleCreateSubmit?: (data: BoschAllTaskNodesData) => void;
  handleEditSubmit?: (data: BoschBnaNodeData) => void;
  data?: BoschBnaNodeData;
  nodeType: BOSCH_TASK_NODE_TYPE;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoschGeneralForm: React.FC<BoschGeneralFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  nodeType,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [machineTypeOptions, setMachineTypeOptions] = useState<SelectOption[]>([]);
  const [machineOptions, setMachineOptions] = useState<SelectOption[]>([]);

  const [preConditions, setPreConditions] = useState<Condition[]>(
    data?.preConditions || []
  );
  const [postConditions, setPostConditions] = useState<Condition[]>(
    data?.postConditions || []
  );
  const [name, setName] = useState<string>(data?.name || "");
  const [duration, setDuration] = useState<string>(data?.duration || "");
  const [timeUnit, setTimeUnit] = useState<string>(data?.timeUnit || "");
  const [cycles, setCycles] = useState<string>(data?.cycles || "");
  //
  const [plannedStartDate, setPlannedStartDate] = useState<Date | null>(
    data?.plannedStartDate ? new Date(data.plannedStartDate) : null
  );
  const [plannedEndDate, setPlannedEndDate] = useState<Date | null>(
    data?.plannedEndDate ? new Date(data.plannedEndDate) : null
  );
  const [committedStartDate, setCommittedStartDate] = useState<Date | null>(
    data?.committedStartDate ? new Date(data.committedStartDate) : null
  );
  const [committedEndDate, setCommittedEndDate] = useState<Date | null>(
    data?.committedEndDate ? new Date(data.committedEndDate) : null
  );
  const [realStartDate, setRealStartDate] = useState<Date | null>(
    data?.realStartDate ? new Date(data.realStartDate) : null
  );
  const [realEndDate, setRealEndDate] = useState<Date | null>(
    data?.realEndDate ? new Date(data.realEndDate) : null
  );
  //
  const [machineType, setMachineType] = useState<string>(data?.machineType || "");
  const [machineAllocation, setMachineAllocation] = useState<string>(
    data?.machineAllocation || ""
  );
  const [machine, setMachine] = useState<string>(data?.machine || "");
  const [hrRole, setHrRole] = useState<string>(data?.hrRole || "");
  const [hrQuantity, setHrQuantity] = useState<string>(data?.hrQuantity || "");
  const [hrAllocation, setHrAllocation] = useState<string>(data?.hrAllocation || "");
  const [humanResources, setHumanResources] = useState<string>(
    data?.humanResources || ""
  );
  const [materialsAllocation, setMaterialsAllocation] = useState<string>(
    data?.materialsAllocation || ""
  );
  const [materials, setMaterials] = useState<Material[]>(data?.materials || []);

  const initialValues = useRef({
    preConditions,
    postConditions,
    name,
    duration,
    timeUnit,
    cycles,
    plannedStartDate,
    plannedEndDate,
    committedStartDate,
    committedEndDate,
    realStartDate,
    realEndDate,
    machineType,
    machineAllocation,
    machine,
    hrRole,
    hrQuantity,
    hrAllocation,
    humanResources,
    materialsAllocation,
    materials,
  }).current;

  const currentValues = {
    preConditions,
    postConditions,
    name,
    duration,
    timeUnit,
    cycles,
    plannedStartDate,
    plannedEndDate,
    committedStartDate,
    committedEndDate,
    realStartDate,
    realEndDate,
    machineType,
    machineAllocation,
    machine,
    hrRole,
    hrQuantity,
    hrAllocation,
    humanResources,
    materialsAllocation,
    materials,
  };

  useDetectFormsUnsavedChanges(initialValues, currentValues, handleSetUnsavedChanges);

  useEffect(() => {
    (async () => {
      try {
        const data = await callApi<GetQueryMaintenanceMachinesSnippet>({
          query: getQueryMaintenanceMachines,
          auth: { setAuthedUser },
        });

        const options = getMachineOptions(data);
        setMachineOptions(options.machineOptions);
        setMachineTypeOptions(options.typeOptions);
      } catch (error) {
        console.log("There was an error fetching machines ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = () => {
    const current = new Date().toISOString();
    const result = {
      id: uuidv4().split("-")[0],
      type: nodeType,
      name: name,
      createdOn: current,
      updatedOn: current,
      preConditions,
      postConditions,
      duration,
      timeUnit,
      cycles,
      plannedStartDate: plannedStartDate?.toISOString(),
      plannedEndDate: plannedEndDate?.toISOString(),
      committedStartDate: committedStartDate?.toISOString(),
      committedEndDate: committedEndDate?.toISOString(),
      realStartDate: realStartDate?.toISOString(),
      realEndDate: realEndDate?.toISOString(),
      machineType,
      machineAllocation,
      machine,
      hrRole,
      hrQuantity,
      hrAllocation,
      humanResources,
      materialsAllocation,
      materials,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit({
        [nodeType]: result,
      });
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  return (
    <Stack css={styles.textBreak} spacing={4}>
      <Box component="div">
        <Typography css={styles.textBreak} variant="h3">
          Pre-conditions
        </Typography>
        <ConditionsList
          conditions={preConditions}
          setConditions={setPreConditions}
          fieldLabe="Pre-condition"
        />
      </Box>

      <Divider />

      <Stack spacing={3}>
        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            Metadata
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="cycles"
                label="Cycles"
                onChange={(e) => setCycles(e.target.value)}
                value={cycles}
                numberField
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="duration"
                label="Duration"
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
                numberField
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                selectOptions={timeUnitSchemeOptions}
                name="timeUnit"
                label="Time Unit"
                onChange={(e) => setTimeUnit(e.target.value)}
                value={timeUnit}
              />
            </Grid>
            {/*  */}
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="plannedStartDate"
                label="Planned start date"
                onChange={(value) => setPlannedStartDate(value)}
                value={plannedStartDate}
                maxDate={plannedEndDate ? endOfDay(plannedEndDate) : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="plannedEndDate"
                label="Planned end date"
                onChange={(value) => setPlannedEndDate(value)}
                value={plannedEndDate}
                minDate={plannedStartDate ? endOfDay(plannedStartDate) : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="committedStartDate"
                label="Committed start date"
                onChange={(value) => setCommittedStartDate(value)}
                value={committedStartDate}
                maxDate={committedEndDate ? endOfDay(committedEndDate) : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="committedEndDate"
                label="Committed end date"
                onChange={(value) => setCommittedEndDate(value)}
                value={committedEndDate}
                minDate={committedStartDate ? endOfDay(committedStartDate) : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="realStartDate"
                label="Real start date"
                onChange={(value) => setRealStartDate(value)}
                value={realStartDate}
                maxDate={realEndDate ? endOfDay(realEndDate) : undefined}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                css={styles.width100}
                name="realEndDate"
                label="Real end date"
                onChange={(value) => setRealEndDate(value)}
                value={realEndDate}
                minDate={realEndDate ? endOfDay(realEndDate) : undefined}
              />
            </Grid>
          </Grid>
        </Box>

        <Box component="div">
          <Typography css={styles.textBreak} variant="h3">
            Resources
          </Typography>
          <Grid css={styles.contentBreak} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Select
                selectOptions={machineTypeOptions}
                label="Machine Type"
                onChange={(e) => setMachineType(e.target.value)}
                value={machineType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                selectOptions={allocationOption}
                label="Machine Allocation"
                onChange={(e) => setMachineAllocation(e.target.value)}
                value={machineAllocation}
              />
            </Grid>
            {machineAllocation === "Manually" ? (
              <Grid item xs={12} sm={6}>
                <Select
                  selectOptions={machineOptions}
                  label="Machine Allocation"
                  onChange={(e) => setMachine(e.target.value)}
                  value={machine}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid css={styles.contentBreak} container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="HR Role"
                value={hrRole}
                onChange={(e) => setHrRole(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="HR Quantity"
                value={hrQuantity}
                onChange={(e) => setHrQuantity(e.target.value)}
                numberField
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                selectOptions={allocationOption}
                label="HR Allocation"
                onChange={(e) => setHrAllocation(e.target.value)}
                value={hrAllocation}
              />
            </Grid>
            {hrAllocation === "Manually" ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="HR"
                  onChange={(e) => setHumanResources(e.target.value)}
                  value={humanResources}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Select
                selectOptions={allocationOption}
                label="Materials Allocation"
                onChange={(e) => setMaterialsAllocation(e.target.value)}
                value={materialsAllocation}
              />
            </Grid>
            {materialsAllocation === "Manually" ? (
              <Grid item xs={12}>
                <MaterialList materials={materials} setMaterials={setMaterials} />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Stack>
      <Divider />

      <Typography css={styles.textBreak} variant="h3">
        Post-conditions
      </Typography>
      <ConditionsList
        conditions={postConditions}
        setConditions={setPostConditions}
        fieldLabe="Post-condition"
      />

      <Box component="div" css={styles.flexCenter}>
        <Button css={[styles.width100, styles.widthLimit20]} onClick={handleFormSubmit}>
          Save Changes
        </Button>
      </Box>
    </Stack>
  );
};

export default BoschGeneralForm;

interface ConditionsListProps {
  conditions: Condition[];
  setConditions: React.Dispatch<React.SetStateAction<Condition[]>>;
  fieldLabe: string;
}

const ConditionsList: React.FC<ConditionsListProps> = ({
  conditions,
  setConditions,
  fieldLabe,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const handleChange = (key: string, value: string, index: number) => {
    setConditions((prev) =>
      prev.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  const handleAdd = () => {
    setConditions((prev) => [
      ...prev,
      {
        key: "",
        value: "",
      },
    ]);
  };
  const handleRemove = (index: number) => {
    setConditions((prev) => prev.filter((_, itemIndex) => index !== itemIndex));
  };

  return (
    <Box component="div" css={styles.textBreak}>
      <Stack
        css={styles.labelBreak}
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body1">Specify conditions</Typography>

        <Button onClick={handleAdd}>Add New</Button>
      </Stack>
      <Divider css={styles.labelBreak} />

      {conditions.length ? (
        <Box component="div">
          {conditions.map((item, index) => (
            <Grid container spacing={3}>
              <Grid item sm={5}>
                <TextField
                  label={fieldLabe}
                  value={item.key}
                  onChange={(e) => handleChange("key", e.target.value, index)}
                />
              </Grid>
              <Grid item sm={5}>
                <TextField
                  label="Required Value"
                  value={item.value}
                  onChange={(e) => handleChange("value", e.target.value, index)}
                />
              </Grid>
              <Grid css={styles.heightAuto} item sm={2}>
                <Box component="div" css={[styles.flexCenter, styles.height100]}>
                  <IconButton onClick={() => handleRemove(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" textAlign="center">
          You haven't added any conditions yet
        </Typography>
      )}
    </Box>
  );
};

type MachineOptions = {
  typeOptions: SelectOption[];
  machineOptions: SelectOption[];
};

const getMachineOptions = (
  machineData: GetQueryMaintenanceMachinesSnippet
): MachineOptions => {
  const typesSet = new Set<string>();
  const machinesSet = new Set<string>();

  machineData.forEach((machine) => {
    typesSet.add(machine.machine_type);
    machinesSet.add(machine.machine);
  });

  const typeArr = Array.from(typesSet);
  const machinesArr = Array.from(machinesSet);

  return {
    typeOptions: handleGetSelectOption(typeArr),
    machineOptions: handleGetSelectOption(machinesArr),
  };
};

interface MaterialListProps {
  materials: Material[];
  setMaterials: React.Dispatch<React.SetStateAction<Material[]>>;
}

const MaterialList: React.FC<MaterialListProps> = ({ materials, setMaterials }) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const handleChange = (key: string, value: string, index: number) => {
    setMaterials((prev) =>
      prev.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  const handleAdd = () => {
    setMaterials((prev) => [
      ...prev,
      {
        material: "",
        quantity: "",
      },
    ]);
  };
  const handleRemove = (index: number) => {
    setMaterials((prev) => prev.filter((_, itemIndex) => index !== itemIndex));
  };

  return (
    <Box component="div" css={styles.textBreak}>
      <Stack
        css={styles.labelBreak}
        spacing={2}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="body1">Specify Materials</Typography>

        <Button onClick={handleAdd}>Add New</Button>
      </Stack>
      <Divider css={styles.labelBreak} />

      {materials.length ? (
        <Box component="div">
          {materials.map((item, index) => (
            <Grid container spacing={3}>
              <Grid item sm={5}>
                <TextField
                  label="Material"
                  value={item.material}
                  onChange={(e) => handleChange("material", e.target.value, index)}
                />
              </Grid>
              <Grid item sm={5}>
                <TextField
                  label="Quantity"
                  value={item.quantity}
                  onChange={(e) => handleChange("quantity", e.target.value, index)}
                  numberField
                />
              </Grid>
              <Grid css={styles.heightAuto} item sm={2}>
                <Box component="div" css={[styles.flexCenter, styles.height100]}>
                  <IconButton onClick={() => handleRemove(index)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" textAlign="center">
          You haven't added any materials yet
        </Typography>
      )}
    </Box>
  );
};
