import { ReactNode, useEffect, useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { Box, Typography } from "@mui/material";
import ContentBox from "../../../Components/MaterialUI/ContentBox";
import Alert from "../../../Components/MaterialUI/Alert";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  IntegratorConnectionType,
  IntegratorConnectionTypeReadable,
  handleConnectionClickOpcDa,
  handleConnectionClickOpcModbus,
  handleConnectionClickOpcMqtt,
  handleConnectionClickOpcUa,
} from "../../../Components/PageComponents/Integrator/Connections/connectionsPageUtils";
import callApi from "../../../Api/callApi";
import { getQueryAllConnections } from "../../../Api/DataSources/apiDSGetQueries";
import { GetQueryAllConnectionsSnippet } from "../../../Api/DataSources/apiDSSnippets";
import ResponsiveTableGrid from "../../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../../Components/SmallComponents/TableGrid/constructTableGrid";
import { useNavigate } from "react-router-dom";
import {
  OpcSubscriptionStatus,
  OpcSubscriptionStatusReadable,
} from "../../../Api/DataSources/apiDSDataTypes";
import Button from "../../../Components/MaterialUI/Button";
import Checkbox from "../../../Components/MaterialUI/FormFields/Checkbox";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";

const statusMapping: Record<OpcSubscriptionStatus, OpcSubscriptionStatusReadable> = {
  active: "Active",
  inactive: "Inactive",
  reconnecting: "Reconnecting",
};
const typeMapping: Record<IntegratorConnectionType, IntegratorConnectionTypeReadable> = {
  opc_ua: "OPC UA",
  opc_da: "OPC DA",
  mqtt: "MQTT",
  modbus: "Modbus",
};

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "status", label: "Status", type: "string" },
  { id: "action", label: "Action", type: "button" },
];

type ConnectionRow = {
  status: OpcSubscriptionStatusReadable;
  id: string;
  name: string;
  type: IntegratorConnectionTypeReadable;
  action: ReactNode;
};

type SubRows = {
  stopped: ConnectionRow[];
  started: ConnectionRow[];
};

const ConnectionsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>("loading");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rows, setRows] = useState<SubRows | null>(null);
  const [stopStartSub, setStopStartSub] = useState<string | null>(null);
  const [stopStartAlert, setStopStartAlert] = useState<FormStatuses>(null);
  const [filterStoppedSubs, setFilterStoppedSubs] = useState<boolean>(true);
  const [stopStartLoading, setStopStartLoading] = useState<boolean>(false);

  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  const rowsToUse = filterStoppedSubs
    ? rows?.started || []
    : [...(rows?.started || []), ...(rows?.stopped || [])];

  useEffect(() => {
    (async () => {
      try {
        const data = await callApi<GetQueryAllConnectionsSnippet>({
          query: getQueryAllConnections,
          auth: { setAuthedUser },
        });
        const dataRows: ConnectionRow[] = [];

        Object.entries(data).forEach(([key, valueArr]) => {
          valueArr.forEach((sub) => {
            const connection: ConnectionRow = {
              id: sub.id,
              name: sub.name || sub.client_name!,
              status: statusMapping[sub.status],
              type: typeMapping[key as IntegratorConnectionType],
              action: (
                <Button
                  css={styles.width100}
                  onClick={(e) => {
                    e.stopPropagation();
                    setStopStartSub(sub.id);
                  }}
                  variant="text"
                  disabled={sub.status === "reconnecting"}
                  color={sub.status !== "active" ? "primary" : "error"}
                >
                  {sub.status === "active" ? t("Deactivate") : t("Activate")}
                </Button>
              ),
            };

            dataRows.push(connection);
          });
        });

        const stoppedSubs: ConnectionRow[] = [];
        const startedSubs: ConnectionRow[] = [];
        dataRows.forEach((sub) => {
          if (sub.status === "Inactive") {
            stoppedSubs.push(sub);
          } else {
            startedSubs.push(sub);
          }
        });

        setRows({ started: startedSubs, stopped: stoppedSubs });
        setPageStatus(null);
        setAlertMessage(null);
      } catch (err) {
        console.log("err: ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (stopStartSub) {
        const allSubs = [...(rows?.started || []), ...(rows?.stopped || [])];
        const foundSub = allSubs.find((row) => row.id === stopStartSub);
        if (foundSub?.id) {
          setStopStartLoading(true);
          const isActive = foundSub.status === "Active";
          const successfulUpdate = await handleStopStartConnection(
            foundSub.type,
            isActive,
            foundSub.id
          );

          if (successfulUpdate) {
            setStopStartAlert(null);

            setRows((prev) => {
              const stopped: ConnectionRow[] = [];
              const started: ConnectionRow[] = [];

              [...(prev?.started || []), ...(prev?.stopped || [])].forEach((sub) => {
                if (sub.id === foundSub.id) {
                  const newStatus = isActive ? "Inactive" : "Active";
                  const updatedSub: ConnectionRow = {
                    ...sub,
                    status: newStatus,
                    action: (
                      <Button
                        css={styles.width100}
                        onClick={(e) => {
                          e.stopPropagation();
                          setStopStartSub(foundSub.id);
                        }}
                        variant="text"
                        color={newStatus !== "Active" ? "primary" : "error"}
                      >
                        {newStatus === "Active" ? t("Deactivate") : t("Activate")}
                      </Button>
                    ),
                  };

                  if (newStatus === "Inactive") {
                    stopped.push(updatedSub);
                  } else {
                    started.push(updatedSub);
                  }
                } else {
                  if (sub.status === "Inactive") {
                    stopped.push(sub);
                  } else {
                    started.push(sub);
                  }
                }
              });

              return {
                started: started,
                stopped: stopped,
              };
            });
          } else {
            setStopStartAlert("error");
          }
          setStopStartLoading(false);
          setStopStartSub(null);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopStartSub]);

  const handleFilterSubs = (val: boolean) => {
    setFilterStoppedSubs(val);
  };

  const handleOnRowClick = async (type: IntegratorConnectionTypeReadable, id: string) => {
    let subUrl = "";
    switch (type) {
      case "OPC UA": {
        subUrl = "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-ua-information";
        break;
      }
      case "OPC DA": {
        subUrl = "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-da-information";
        break;
      }
      case "MQTT": {
        subUrl =
          "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-mqtt-information";
        break;
      }
      case "Modbus": {
        subUrl =
          "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections/opc-modbus-information";
        break;
      }
    }

    navigate({
      pathname: `${subUrl}/${id}`,
    });
  };

  const handleStopStartConnection = async (
    type: IntegratorConnectionTypeReadable,
    isActive: boolean,
    subID: string
  ) => {
    try {
      switch (type) {
        case "MQTT": {
          await handleConnectionClickOpcMqtt(isActive, subID, setAuthedUser);
          break;
        }
        case "Modbus": {
          await handleConnectionClickOpcModbus(isActive, subID, setAuthedUser);
          break;
        }
        case "OPC DA": {
          await handleConnectionClickOpcDa(isActive, subID, setAuthedUser);
          break;
        }
        case "OPC UA": {
          await handleConnectionClickOpcUa(isActive, subID, setAuthedUser);
          break;
        }
      }

      return true;
    } catch (err) {
      console.log("err ", err);
      return false;
    }
  };

  return (
    <ContentBox
      label={
        <Typography variant="h3" align="left">
          {t("Integrated Connections")}
        </Typography>
      }
    >
      <Box component="div" css={styles.contentBreak} />
      <Alert
        css={styles.contentBreak}
        severity={pageStatus}
        message={alertMessage}
        showAlert={!!alertMessage}
      />

      <Alert
        css={styles.contentBreak}
        severity={"error"}
        message={t("Something went wrong")}
        showAlert={!!stopStartAlert}
      />

      <Box component="div" css={styles.labelBreak}>
        <Checkbox
          label={t("Filter out Inactive Connections")}
          checked={filterStoppedSubs}
          onChange={(e) => handleFilterSubs(e.target.checked)}
          disabled={pageStatus === "loading" || stopStartLoading}
        />
      </Box>

      <ResponsiveTableGrid
        rows={rowsToUse}
        colSchema={useTranslateFields(colSchema, ["label"])}
        editMode={false}
        responsive="desktop"
        onRowClick={(row) => handleOnRowClick(row.type, row.id)}
        loading={pageStatus === "loading"}
        configuration={{ density: "compact" }}
      />
    </ContentBox>
  );
};

export default ConnectionsPage;
