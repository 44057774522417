import { IconButton, Stack, Typography } from "@mui/material";
import {
  GetQueryOEEAddedAssetsToCategoriesSnippet,
  GetQueryOEEMappedCategoriesWithSubcategoriesSnippet,
} from "../../../Api/OEE/apiOEESnippets";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../MaterialUI/Modal";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useAuthedContext } from "../../../context/AuthContext";
import Button from "../../MaterialUI/Button";
import { postQueryAssetDelete } from "../../../Api/OEE/apiOEEPostQueries";
import callApi from "../../../Api/callApi";
import OEEAddAssetForm from "./OEEAddAssetForm";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
} from "../../../Global/Types/commonTypes";
import { ModalTitle } from "./oEEUtils";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../context/LanguageContext";

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Asset", type: "string" },
  { id: "functional_location", label: "Functional Location", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "maincategory", label: "Main Category", type: "string" },
  { id: "subcategory", label: "Subcategory", type: "string" },
  { id: "actions", label: "Actions", type: "button" },
];

interface OEEAssetsTableProps {
  addedAssetsToCategories: GetQueryOEEAddedAssetsToCategoriesSnippet;
  fetchOEEData: () => void;
  assetTypeOptions: AutocompleteOption[];
  oEEMainCategoriesOptions: AutocompleteGroupedOption[];
  oEEMappedCategoriesWithSubcategories: GetQueryOEEMappedCategoriesWithSubcategoriesSnippet;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
}

const OEEAssetsTable: React.FC<OEEAssetsTableProps> = ({
  addedAssetsToCategories,
  fetchOEEData,
  assetTypeOptions,
  oEEMainCategoriesOptions,
  oEEMappedCategoriesWithSubcategories,
  setModalTitle,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const getModalLabel = (modalData: ModalLayoutData | null): string => {
    if (!modalData) return "";
    switch (modalData.type) {
      case "AssetForm":
        return t("Edit Asset");
      case "deleteForm":
        return t("Delete Asset");
      default:
        return "";
    }
  };

  const handleDeleteAsset = async (assetId: string, mappingId: string) => {
    try {
      const deletedAsset = await callApi({
        query: postQueryAssetDelete(assetId, mappingId),
        auth: { setAuthedUser },
      });

      if (deletedAsset) {
        fetchOEEData();
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("OEESubcategoryDelete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {addedAssetsToCategories ? (
        <ResponsiveTableGrid
          rows={handleFilterData(setOpenInfo, setModalData, addedAssetsToCategories)}
          colSchema={useTranslateFields(colSchema, ["label"])}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
        />
      ) : (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "AssetForm" ? (
            <OEEAddAssetForm
              assetTypeOptions={assetTypeOptions}
              oEEMainCategoriesOptions={oEEMainCategoriesOptions}
              oEEMappedCategoriesWithSubcategories={oEEMappedCategoriesWithSubcategories}
              fetchOEEData={fetchOEEData}
              setModalTitle={setModalTitle}
              assetData={modalData.data.asset}
              mappingId={modalData.data.mappingId}
              subcategory={modalData.data.subcategory}
              mainCategory={modalData.data.mainCategory}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this asset?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() =>
                    handleDeleteAsset(modalData.data.assetId, modalData.data.mappingId)
                  }
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default OEEAssetsTable;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  addedAssetsToCategories: GetQueryOEEAddedAssetsToCategoriesSnippet | undefined
) => {
  if (!addedAssetsToCategories) return [];

  const rows = addedAssetsToCategories.flatMap((asset) =>
    asset.main_categories.flatMap((mainCategory) =>
      mainCategory.sub_categories.map((subcategory) => ({
        name: asset.name,
        functional_location: asset.functional_location,
        type: capitalizeFirstLetterOfEachWord(subcategory.type),
        maincategory: mainCategory.name,
        subcategory: subcategory.name,
        actions: (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({
                  type: "AssetForm",
                  data: {
                    asset: asset,
                    mappingId: subcategory.mapping_id,
                    subcategory: subcategory,
                    mainCategory: mainCategory,
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenInfo(true);
                setModalData({
                  type: "deleteForm",
                  data: { assetId: asset.asset_id, mappingId: subcategory.mapping_id },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      }))
    )
  );

  return rows;
};
