import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MaterialReactTable,
} from "material-react-table";
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import useTheme from "@mui/material/styles/useTheme";
import { TableGridProps } from "./tableGridUtils";
import { memo } from "react";
import { css } from "@emotion/react";
import cssFontStyles from "../../../Global/Styles/font";
import { useLanguageContext } from "../../../context/LanguageContext";

const cssStyles = {
  staticContainer: css({
    overflow: "hidden",
    width: "100%",
    height: "100%",
    position: "relative",
  }),
  staticOverlap: css({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
  }),
};

const TableGrid = <T extends Record<string, any>>({
  className,
  rows,
  columns,
  editMode,
  setEditMode,
  configuration,
  isStatic,
  onRowClick,
  loading,
}: TableGridProps<T>) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssStyles,
    ...cssFontStyles,
  };
  const grouping = configuration?.grouping;
  const density = configuration?.density;

  console.log("configuration ", configuration);

  return (
    <Box
      component="div"
      className={className}
      css={isStatic ? styles.staticContainer : undefined}
    >
      {isStatic ? <Box component="div" css={styles.staticOverlap} /> : null}
      <MaterialReactTable
        columns={columns}
        data={rows}
        enableColumnFilterModes
        enableColumnOrdering
        enableGrouping
        enableColumnPinning
        enableRowPinning
        rowPinningDisplayMode="top"
        enableStickyHeader={configuration?.enableStickyHeader}
        muiTableContainerProps={
          configuration?.maxTableHeight
            ? { sx: { maxHeight: `${configuration.maxTableHeight}px` } }
            : undefined
        }
        enableRowActions={Boolean(editMode)}
        getRowId={(row) => row.id}
        // onRowPinningChange={
        //   setConfiguration && configuration?.pinnedRows
        //     ? (val) => {
        //         console.log("val ", val());
        //         const top = val().top;
        //         const bottom = val().bottom;
        //         const updatedConfig = {
        //           ...configuration,
        //           pinnedRows: {
        //             top: [...configuration.pinnedRows.top, ...top],
        //             bottom: [...configuration.pinnedRows.bottom, ...bottom],
        //           },
        //         };
        //         setConfiguration(updatedConfig);
        //         return val;
        //       }
        //     : undefined
        // }
        // onDensityChange={
        //   setConfiguration
        //     ? (val) => {
        //         const updatedConfig = {
        //           ...configuration,
        //           density: val as MRT_DensityState,
        //         };

        //         setConfiguration(updatedConfig);
        //       }
        //     : undefined
        // }
        initialState={{
          density: density,
          grouping: grouping,
          // @ts-ignore
          expanded: !!configuration?.expandedGrouping,
          pagination: configuration?.initialRowsPerPage
            ? {
                pageSize: configuration.initialRowsPerPage,
                pageIndex: 0,
              }
            : undefined,
          ...(configuration?.columnVisibility !== undefined && {
            columnVisibility: configuration.columnVisibility,
          }),
          rowPinning: configuration?.pinnedRows
            ? configuration.pinnedRows
            : {
                top: [],
                bottom: [],
              },
        }}
        muiSearchTextFieldProps={{
          placeholder: `${t("Search in")} ${rows.length} ${t("rows")}`,
          sx: { minWidth: "200px" },
          variant: "standard",
          size: "small",
        }}
        muiPaginationProps={{
          rowsPerPageOptions: editMode ? [5, 10] : [5, 10, 15, 20, 25, 30, 50],
        }}
        enableColumnResizing
        enablePagination={!configuration?.disablePagination}
        renderEmptyRowsFallback={() => (
          <Typography
            css={[styles.italicText, styles.sectionBreak, styles.reverseContentBreak]}
            variant="body1"
            align="center"
            color="textSecondary"
          >
            {loading ? t("Fetching data...") : t("No records to display")}
          </Typography>
        )}
        renderTopToolbar={
          isStatic ? (
            <Box component="div" />
          ) : (
            ({ table }) => (
              <Stack
                css={styles.leftRightPadding1}
                spacing={1}
                alignItems="center"
                direction="row"
                justifyContent={
                  table.getState()?.showGlobalFilter ? "space-between" : "flex-start"
                }
              >
                {table.getState()?.showGlobalFilter ? (
                  <Stack spacing={2} direction="row" alignItems="center">
                    <MRT_GlobalFilterTextField table={table} />
                  </Stack>
                ) : null}

                {setEditMode ? (
                  <Stack alignItems="center">
                    <Tooltip title={t("Edit")}>
                      <IconButton onClick={() => setEditMode((prev) => !prev)}>
                        <SaveAsIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography variant="caption">{t("Edit")}</Typography>
                  </Stack>
                ) : null}

                {!configuration?.disableHeader ? (
                  <Stack
                    css={styles.width100}
                    spacing={1}
                    alignItems="center"
                    direction="row"
                    justifyContent="flex-end"
                    pb={1}
                  >
                    <>
                      {editMode ? (
                        <>
                          {configuration?.hideAddButton ? null : (
                            <Stack alignItems="center">
                              <Tooltip title={t("Add new record")}>
                                <IconButton onClick={editMode.handleAddNewRow}>
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>
                              <Typography variant="caption">{t("Add")}</Typography>
                            </Stack>
                          )}

                          <Stack alignItems="center">
                            <Tooltip title={t("Save changes")}>
                              <IconButton onClick={() => editMode.handleSaveButton()}>
                                <SaveAsIcon />
                              </IconButton>
                            </Tooltip>
                            <Typography variant="caption">{t("Save")}</Typography>
                          </Stack>

                          <Divider flexItem orientation="vertical" />
                        </>
                      ) : null}

                      <Stack alignItems="center">
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <Typography variant="caption">{t("Search")}</Typography>
                      </Stack>
                      <Stack alignItems="center">
                        <MRT_ToggleFiltersButton table={table} />
                        <Typography variant="caption">{t("Filters")}</Typography>
                      </Stack>
                      <Stack alignItems="center">
                        <MRT_ShowHideColumnsButton table={table} />
                        <Typography variant="caption">{t("Columns")}</Typography>
                      </Stack>
                      <Stack alignItems="center">
                        <MRT_ToggleDensePaddingButton table={table} />
                        <Typography variant="caption">{t("Density")}</Typography>
                      </Stack>
                      <Stack alignItems="center">
                        <MRT_ToggleFullScreenButton table={table} />
                        <Typography variant="caption">{t("Full")}</Typography>
                      </Stack>
                    </>
                  </Stack>
                ) : null}
              </Stack>
            )
          )
        }
        // Edit Mode Props Config
        positionActionsColumn={editMode ? "last" : undefined}
        renderRowActions={
          editMode && !configuration?.hideDeleteRowButton
            ? ({ row }) => (
                <>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        color="error"
                        onClick={() => editMode.handleDeleteRow(row.index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </>
              )
            : undefined
        }
        editDisplayMode={editMode ? "table" : undefined}
        enableEditing={Boolean(editMode)}
        // enableColumnVirtualization
        // enableRowVirtualization
        muiTableBodyRowProps={
          onRowClick
            ? ({ row }) => ({
                onClick: () => {
                  onRowClick(row.original);
                },
                sx: {
                  cursor: "pointer",
                },
              })
            : undefined
        }
      />
    </Box>
  );
};

export default memo(TableGrid);
