import { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import GanttChartViewSwitcher from "./GanttChartViewSwitcher";
import Modal from "../../../MaterialUI/Modal";
import OpExGanttChartEditTask from "./GanttChartEditTask";
import { css } from "@emotion/react";
import "gantt-task-react/dist/index.css";
import { formatDateAndTime } from "../../../../Global/Utils/commonFunctions";
import { OpExEditProject } from "../../MyOpExProjects/opExProjectUtils";
import isBefore from "date-fns/isBefore";
import Button from "../../../MaterialUI/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { GanttTask } from "./ganttChartUtils";
import { subMonths } from "date-fns";
import { useLanguageContext } from "../../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  ganttWrapper: css({
    "._34SS0": {
      backgroundColor: theme.palette.background.default,
    },
    "._3lLk3": { borderTop: "1px solid #7E8082", borderBottom: "1px solid #7E8082" },
    "._2dZTy": {
      fill: theme.palette.background.default,
    },
    "._35nLX": {
      fill: theme.palette.background.default,
    },
    "._9w8d5": {
      fill: theme.palette.common.black,
    },
    "._2QjE6": {
      fill: theme.palette.common.black,
    },
    "._3zRJQ": {
      fontWeight: "bold",
    },
    "._3KcaM": {
      fill: theme.palette.common.black,
      fontWeight: "bold",
    },
    "._2q1Kt": {
      fill: theme.palette.common.black,
    },
  }),
  chartInfo: css({
    backgroundColor: theme.palette.background.default,
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    border: "2px solid",
    borderColor: theme.palette.grey[800],
  }),
});

export type GanttChartDateType = "date" | "date-time" | "time";

interface GanttChartProps {
  ganttChartData: Task[];
  setGanttChartData?: React.Dispatch<React.SetStateAction<Task[] | undefined>>;
  initialViewMode?: ViewMode;
  defaultColumnWidth?: number;
  listCellWidth?: string;
  dateType?: GanttChartDateType;
  isTaskListChecked?: boolean;
  smallWidth?: boolean;
  showPeriodSwitcher?: boolean;
  isEditable?: {
    project?: Task;
    setProject?: React.Dispatch<React.SetStateAction<OpExEditProject | null>>;
    editTaskModal: boolean;
    setEditTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
    onTaskEdit: (task: Task) => void;
    onTaskDelete: (task: Task) => void;
  };
  TooltipContent?: React.FC<{
    task: GanttTask;
  }>;
}

const GanttChart: React.FC<GanttChartProps> = ({
  ganttChartData,
  setGanttChartData,
  initialViewMode,
  defaultColumnWidth,
  listCellWidth = "155px",
  dateType = "date",
  isEditable,
  isTaskListChecked = true,
  smallWidth,
  showPeriodSwitcher,
  TooltipContent,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme) };
  const [view, setView] = useState<ViewMode>(
    initialViewMode ? initialViewMode : ViewMode.Day
  );
  const [isChecked, setIsChecked] = useState<boolean>(isTaskListChecked);
  const [selectedTask, setSelectedTask] = useState<GanttTask>();
  const [openChangeProjectDate, setOpenChangeProjectDate] = useState<boolean>(false);
  const [changeProjectDateMessage, setChangeProjectDateMessage] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);

  let columnWidth = defaultColumnWidth ? defaultColumnWidth : 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Year) {
    columnWidth = 200;
  }

  const handleChangeStartEndDate = (task: Task) => {
    setOpenChangeProjectDate(false);
    if (!isEditable) return;
    let newTasks = ganttChartData.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
      }
    }
    if (setGanttChartData) {
      setGanttChartData(newTasks);
      isEditable.onTaskEdit(task);
    }
  };

  const handleUpdateTaskAndProjectStartEndDate = () => {
    if (isEditable && isEditable.project && isEditable.setProject && selectedTask) {
      handleChangeStartEndDate(selectedTask);
      isEditable.setProject({
        ...isEditable.project,
        startDate: isBefore(isEditable.project?.end, selectedTask.start)
          ? isEditable.project.start
          : selectedTask.start,
        endDate: isBefore(isEditable.project?.end, selectedTask.start)
          ? selectedTask.end
          : isEditable.project.end,
      });
      isEditable.setEditTaskModal(false);
    }
  };

  const handleTaskChange = (task: Task) => {
    if (!isEditable) return;
    if (!isEditable.project) return;

    const project = ganttChartData.find(
      (t) => t.type === "project" && t.name === task.project
    );

    if (project && isBefore(task.start, project.start)) {
      setOpenChangeProjectDate(true);
      setChangeProjectDateMessage(
        t(
          "Task start date is before project start date. By updating the task date duration, project start date will also be updated. Do you want to update project start date?"
        )
      );
      return;
    }

    if (project && !isBefore(task.start, project.end)) {
      setOpenChangeProjectDate(true);
      setChangeProjectDateMessage(
        t(
          "Task start date is after project end date. By updating the task date duration, project end date will also be updated. Do you want to update project end date?"
        )
      );
      return;
    }

    handleChangeStartEndDate(task);
  };

  const handleProgressChange = async (task: Task) => {
    if (setGanttChartData) {
      setGanttChartData(ganttChartData.map((t) => (t.id === task.id ? task : t)));

      if (isEditable) isEditable.onTaskEdit(task);
    }
  };

  const handleExpanderClick = (task: Task) => {
    if (setGanttChartData) {
      setGanttChartData(ganttChartData.map((t) => (t.id === task.id ? task : t)));
    }
  };

  const getStartEndDateForProject = (tasks: Task[], projectId: string) => {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  };

  const handleDblClick = (project: Task) => {
    if (project.type === "project") {
      setSelectedTask(project);

      if (isEditable) isEditable.setEditTaskModal(true);
    }
  };

  const handleTaskClick = (task: GanttTask) => {
    if (task.hideChildren !== undefined) return;

    if (isEditable) {
      isEditable.setEditTaskModal(true);
      setSelectedTask(task);
    }
  };

  const getDuration = (taskStart: Date, taskEnd: Date) => {
    const duration = taskEnd.getTime() - taskStart.getTime();
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));

    return `${days + 1} ${days + 1 > 1 ? t("days") : t("day")}`;

    // this can be used in feature if more accurate duration is needed

    // const duration = taskEnd.getTime() - taskStart.getTime();
    // const seconds = Math.floor(duration / 1000);
    // const minutes = Math.floor(duration / (1000 * 60));
    // const hours = Math.floor(duration / (1000 * 60 * 60));
    // const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    // const weeks = Math.floor(duration / (1000 * 60 * 60 * 24 * 7));
    // if (weeks > 0) {
    //   return `${weeks} week${weeks > 1 ? "s" : ""}`;
    // } else if (days > 0) {
    //   return `${days} day${days > 1 ? "s" : ""}`;
    // } else if (hours > 0) {
    //   return `${hours} hour${hours > 1 ? "s" : ""}`;
    // } else if (minutes > 0) {
    //   return `${minutes} min${minutes > 1 ? "s" : ""}`;
    // } else {
    //   return `${seconds} sec${seconds > 1 ? "s" : ""}`;
    // }
  };

  const handleSearch = (searchText: string) => {
    if (!setGanttChartData) return;

    const newTasks = ganttChartData.map((task) => {
      if (task.type === "project" && !task.project) {
        if (task.name.toLowerCase().includes(searchText)) {
          return { ...task, hideChildren: false };
        } else {
          return { ...task, hideChildren: true };
        }
      } else {
        return task;
      }
    });

    setGanttChartData(newTasks);
  };

  return (
    <Box
      component="div"
      css={{
        width: smallWidth ? "50%" : "100%",
        backgroundColor: theme.palette.background.paper,
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      }}
    >
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
      >
        <GanttChartViewSwitcher
          view={view}
          onViewModeChange={(viewMode: ViewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
          showPeriodSwitcher={showPeriodSwitcher}
        />

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          {search ? (
            <TextField
              label={t("Search")}
              placeholder={`${t("Search for")} ${
                ganttChartData.filter((item) => item.type === "project" && !item.project)
                  .length
              } ${t("project(s)")}`}
              onChange={(e) => handleSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              type="search"
            />
          ) : null}

          <IconButton onClick={() => setSearch(!search)}>
            {!search ? (
              <Tooltip title={t("Search for project")}>
                <SearchIcon />
              </Tooltip>
            ) : null}
            {search ? (
              <Tooltip title={t("Hide search")}>
                <SearchOffIcon />
              </Tooltip>
            ) : null}
          </IconButton>
        </Stack>
      </Stack>

      <Stack direction="row">
        <Box
          component="div"
          className="gantt"
          css={styles.ganttWrapper}
          overflow="hidden"
        >
          <Gantt
            tasks={ganttChartData}
            viewMode={view}
            viewDate={
              initialViewMode === ViewMode.Month ? subMonths(new Date(), 2) : new Date()
            }
            onDateChange={isEditable && handleTaskChange}
            onProgressChange={isEditable && handleProgressChange}
            onClick={handleTaskClick}
            onDoubleClick={handleDblClick}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? listCellWidth : ""}
            columnWidth={columnWidth}
            headerHeight={50}
            rowHeight={57}
            todayColor={
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[700]
            }
            barBackgroundColor={theme.palette.grey[400]}
            barProgressColor={theme.palette.secondary.light400}
            barProgressSelectedColor={theme.palette.primary.main}
            projectBackgroundColor={theme.palette.secondary.main}
            projectProgressColor={
              theme.palette.mode === "light"
                ? theme.palette.secondary.light100
                : theme.palette.secondary.light100
            }
            projectBackgroundSelectedColor={theme.palette.primary.main}
            projectProgressSelectedColor={theme.palette.primary.light400}
            milestoneBackgroundColor={theme.palette.secondary.main}
            milestoneBackgroundSelectedColor={theme.palette.primary.main}
            arrowColor={
              theme.palette.mode === "light"
                ? theme.palette.grey[800]
                : theme.palette.common.black
            }
            TooltipContent={({ task }) => (
              <Stack css={styles.chartInfo}>
                {TooltipContent ? (
                  <TooltipContent task={task} />
                ) : (
                  <>
                    <Typography component="p" variant="h3" mb={1}>
                      {task.name}
                    </Typography>

                    <Box component="div">
                      <Typography component="p" variant="body1" mb={1}>
                        {t("Start Date")}: {formatDateAndTime(task.start)}
                      </Typography>
                      <Typography component="p" variant="body1" mb={1}>
                        {t("End Date")}: {formatDateAndTime(task.end)}
                      </Typography>
                    </Box>

                    <Box component="div">
                      <Typography component="span" variant="body2" mb={1}>
                        {t("Progress")}: {task.progress}%
                      </Typography>
                    </Box>
                  </>
                )}
              </Stack>
            )}
            TaskListHeader={() => {
              return (
                <Stack
                  width="100%"
                  height="50px"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  px={1}
                  borderTop="1px solid"
                  borderBottom="1px solid"
                  borderLeft="1px solid"
                  borderColor={theme.palette.mode === "light" ? "#F5F7F9" : "#7E8082"}
                >
                  <Typography
                    width="200px"
                    component="p"
                    variant="body1"
                    css={{ fontSize: "0.9rem" }}
                  >
                    {t("Name")}
                  </Typography>
                  <Typography
                    width="110px"
                    component="p"
                    variant="body1"
                    css={{ fontSize: "0.9rem" }}
                  >
                    {t("Start Date")}
                  </Typography>
                  <Typography
                    width="110px"
                    component="p"
                    variant="body1"
                    css={{ fontSize: "0.9rem" }}
                  >
                    {t("End Date")}
                  </Typography>
                  <Typography
                    width="70px"
                    component="p"
                    variant="body1"
                    css={{ fontSize: "0.9rem" }}
                  >
                    {t("Duration")}
                  </Typography>
                </Stack>
              );
            }}
            TaskListTable={(data) => {
              return (
                <Stack>
                  {data.tasks.map((task, index) => {
                    return (
                      <Stack
                        key={task.id}
                        width="100%"
                        height="57px"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={1}
                        borderTop="1px solid"
                        borderBottom="1px solid"
                        borderLeft="1px solid"
                        borderColor={
                          theme.palette.mode === "light" ? "#F5F7F9" : "#7E8082"
                        }
                      >
                        <Stack
                          width="200px"
                          direction="row"
                          css={{
                            paddingLeft: task.type === "project" && task.project ? 15 : 0,
                          }}
                        >
                          {task.type === "project" ? (
                            <IconButton
                              css={{ padding: 0, marginRight: 1 }}
                              onClick={() => {
                                data.onExpanderClick(task);
                              }}
                            >
                              {!task.hideChildren ? <KeyboardArrowDownIcon /> : null}
                              {task.hideChildren ? <KeyboardArrowRightIcon /> : null}
                            </IconButton>
                          ) : null}
                          <Tooltip title={task.name}>
                            <Typography
                              component="p"
                              variant="body1"
                              css={{ paddingLeft: task.type !== "project" ? 40 : 0 }}
                            >
                              {`${index + 1}. ${
                                task.name.length > 15
                                  ? `${task.name.slice(0, 15)}...`
                                  : task.name
                              }`}
                            </Typography>
                          </Tooltip>
                        </Stack>
                        <Typography width="110px" component="p" variant="body1">
                          {formatDateAndTime(task.start, "date")}
                        </Typography>
                        <Typography width="110px" component="p" variant="body1">
                          {formatDateAndTime(task.end, "date")}
                        </Typography>
                        <Typography width="70px" component="p" variant="body1">
                          {`${getDuration(task.start, task.end)}`}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            }}
          />
        </Box>
      </Stack>

      {isEditable ? (
        <Modal
          open={isEditable.editTaskModal}
          fullWidth
          label={`${t("Edit")} ${selectedTask ? selectedTask.name : ""}`}
          onClose={() => {
            isEditable.setEditTaskModal(false);
            setOpenChangeProjectDate(false);
          }}
        >
          {selectedTask && setGanttChartData && !openChangeProjectDate ? (
            <OpExGanttChartEditTask
              selectedTask={selectedTask}
              setEditTaskModal={isEditable.setEditTaskModal}
              tasks={ganttChartData}
              setTasks={setGanttChartData}
              onTaskEdit={isEditable.onTaskEdit}
              onTaskDelete={isEditable.onTaskDelete}
              dateType={dateType}
              project={isEditable.project}
            />
          ) : null}

          {openChangeProjectDate ? (
            <Stack width="100%" justifyContent="center" alignItems="flex-start" gap={2}>
              <Typography component="p" variant="body1">
                {changeProjectDateMessage}
              </Typography>
              <Stack
                width="100%"
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={1}
              >
                <Button
                  onClick={() => {
                    setOpenChangeProjectDate(false);
                    isEditable.setEditTaskModal(false);
                  }}
                  color="error"
                >
                  {t("Cancel")}
                </Button>
                <Button onClick={handleUpdateTaskAndProjectStartEndDate} autoFocus>
                  {t("Update")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </Box>
  );
};
export default GanttChart;
