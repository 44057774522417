import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useAuthedContext } from "../../context/AuthContext";
import Modal from "../../Components/MaterialUI/Modal";
import callApi from "../../Api/callApi";
import {
  getQueryElpromCollateralNotDistributed,
  getQueryElpromDetails,
  getQueryElpromFilesMetadata,
  getQueryElpromKso,
  getQueryElpromPrices,
  getQueryElpromSupco,
} from "./API/apiElpromGetQueries";
import {
  ElpromFileMeta,
  ElpromTableData,
  ElpromTableDataCols,
} from "./API/apiElpromSnippets";
import cssFontStyles from "../../Global/Styles/font";
import ElpromFilesModal from "./Components/ElpromFilesModal";
import {
  calcTableFooterAggregations,
  TableAggregationFns,
  TableDynamicCondition,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useLanguageContext } from "../../context/LanguageContext";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

const TABLE_HEIGHT = 1150;
const INITIAL_ROWS = 25;

type TableCols = {
  kso: TableGridColumnSchema[];
  supco: TableGridColumnSchema[];
  prices: TableGridColumnSchema[];
  details: TableGridColumnSchema[];
  notDist: TableGridColumnSchema[];
};

type TableRows = {
  kso: Record<string, any>[];
  supco: Record<string, any>[];
  prices: Record<string, any>[];
  details: Record<string, any>[];
  notDist: Record<string, any>[];
};

const ProjectHubDataPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tabView, setTabView] = useState<number>(0);
  const [filesMeta, setFilesMeta] = useState<ElpromFileMeta[]>([]);

  const [colsData, setColsData] = useState<TableCols | null>(null);
  const [rowsData, setRowsData] = useState<TableRows | null>(null);

  console.log("colsData ", colsData);
  console.log("rowsData ", rowsData);

  const { setAuthedUser } = useAuthedContext();
  const { language, t } = useLanguageContext();
  const navigate = useNavigate();

  const cellBgStaticColor = theme.palette.mode === "dark" ? "#303030" : "whiteSmoke";

  const columnsStaticColors: Record<string, string> = {
    production_site: cellBgStaticColor,
    unit_price: cellBgStaticColor,
    total_value_in_bgn: cellBgStaticColor,
    total_nomenclatures: cellBgStaticColor,
    paint: theme.palette.primary.light,
    need_closure_source_with_overdue_zp: theme.palette.primary.light,
    remaining_stock_on_MS105: theme.palette.primary.light,
    remaining_stock_excluding_MS105: theme.palette.primary.light,
    total_deficit_consumption: theme.palette.secondary.light,
    minimum_deficit_required: theme.palette.secondary.light,
    total_consumption: theme.palette.primary.light,
    norm_per_detail: theme.palette.primary.light,
    base_unit_cooperation: theme.palette.primary.light,
    extended_material_name_cooperation: theme.palette.primary.light,
    cooperation_material: theme.palette.primary.light,
  };

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        await handleFetchFiles();
        await handleFetchTableData();
        setPageStatus(null);
        setAlertMessage(null);
      } catch (err) {
        console.log("err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleFetchFiles = async () => {
    const meta = await callApi<ElpromFileMeta[]>({
      query: getQueryElpromFilesMetadata(),
      auth: { setAuthedUser },
    });
    setFilesMeta(meta);
  };

  const handleFetchTableData = async () => {
    const ksoLimit = 1000;
    const limit = 5000;
    const offset = 0;
    const language_code = language as any;

    const kso = await callApi<ElpromTableData>({
      query: getQueryElpromKso({ limit: ksoLimit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const supco = await callApi<ElpromTableData>({
      query: getQueryElpromSupco({ limit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const prices = await callApi<ElpromTableData>({
      query: getQueryElpromPrices({ limit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const details = await callApi<ElpromTableData>({
      query: getQueryElpromDetails(language_code),
      auth: { setAuthedUser },
    });
    const notDistributed = await callApi<ElpromTableData>({
      query: getQueryElpromCollateralNotDistributed(language_code),
      auth: { setAuthedUser },
    });

    setColsData({
      kso: handleColsData(kso.columns, kso.rows, columnsStaticColors, theme, []),
      supco: handleColsData(supco.columns, supco.rows, columnsStaticColors, theme, []),
      prices: handleColsData(prices.columns, prices.rows, columnsStaticColors, theme, []),
      details: handleColsData(
        details.columns,
        details.rows,
        columnsStaticColors,
        theme,
        ["collateral_percentage", "in_delivery_percentage", "non_collateral_percentage"],
        true
      ),
      notDist: handleColsData(
        notDistributed.columns,
        notDistributed.rows,
        columnsStaticColors,
        theme,
        [],
        false
      ),
    });
    setRowsData({
      kso: kso.rows,
      supco: supco.rows,
      prices: prices.rows,
      details: handleDetailsRows(details.rows),
      notDist: handleNotDistRows(notDistributed.rows),
    });
  };

  const handleDetailsRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleNotDistRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/kso/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.labelBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Typography variant="h2" textAlign="center">
          {t("Collateral")}
        </Typography>

        <Stack spacing={1} alignItems="center">
          <IconButton
            aria-label="sync currencies"
            onClick={() => setOpenModal(true)}
            disabled={pageStatus === "loading"}
          >
            <PublishedWithChangesIcon color="primary" />
          </IconButton>
        </Stack>
      </Stack>

      <Alert
        css={[styles.widthLimit20, styles.labelBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <Stack
        css={[styles.width100, styles.sectionBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Tabs
          css={styles.contentBreak}
          value={tabView}
          onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
          aria-label="configure tabs"
        >
          <Tab label={t("Collateral Summary")} />
          <Tab label="Supco" />
          <Tab label="Kso" />
          <Tab label={t("Prices")} />
          <Tab label={t("Collateral Not Distributed")} />
        </Tabs>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : rowsData && colsData ? (
        <>
          {tabView === 0 ? (
            <ResponsiveTableGrid
              rows={rowsData.details}
              colSchema={colsData.details}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: {
                  id: false,
                  project_lines: false,
                  incomming_control_value_in_bgn: false,
                  available_on_stock_value_in_bgn: false,
                  placed_po_nomenclatures: false,
                  placed_po_value_in_bgn: false,
                  expedition: false,
                },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
                grouping: ["priority"],
              }}
            />
          ) : null}
          {tabView === 1 ? (
            <ResponsiveTableGrid
              rows={rowsData.supco}
              colSchema={colsData.supco}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
            />
          ) : null}
          {tabView === 2 ? (
            <ResponsiveTableGrid
              rows={rowsData.kso}
              colSchema={colsData.kso}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
            />
          ) : null}
          {tabView === 3 ? (
            <ResponsiveTableGrid
              rows={rowsData.prices}
              colSchema={colsData.prices}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
            />
          ) : null}
          {tabView === 4 ? (
            <ResponsiveTableGrid
              rows={rowsData.notDist}
              colSchema={colsData.notDist}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
                grouping: ["category"],
              }}
            />
          ) : null}
        </>
      ) : null}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth={"md"}
        label={t("Manage Files")}
      >
        <ElpromFilesModal
          filesMeta={filesMeta}
          handleFetchTableData={handleFetchTableData}
          handleFetchFiles={handleFetchFiles}
        />
      </Modal>
    </Box>
  );
};

export default ProjectHubDataPage;

const aggregations = [TableAggregationFns.sum];
const aggAndAvg = [TableAggregationFns.mean];

const handleColsData = (
  cols: ElpromTableDataCols[],
  rows: Record<string, any>[],
  columnsStaticColors: Record<string, string>,
  theme: Theme,
  colsWithAverage: string[],
  withActions?: boolean,
  width?: number
): TableGridColumnSchema[] => {
  const action = { id: "actions", label: "Actions", type: "button" };
  const result: TableGridColumnSchema[] = cols.map((item) => {
    const isNumb = item.type === "number";
    const hasAvg = colsWithAverage.includes(item.id);

    return {
      id: item.id,
      label: item.name,
      type: item.type,
      colBgColor: columnsStaticColors[item.id],
      formatNumb: true,
      aggregationFn: isNumb ? (hasAvg ? aggAndAvg : aggregations) : undefined,
      footerAggregations: isNumb
        ? calcTableFooterAggregations(
            hasAvg ? aggAndAvg : aggregations,
            rows.map((row) => row[item.id]),
            item.decimal_points
          )
        : undefined,
      dynamicBgColor: dynamicBgColors(item, theme),
      ...(width && { width: width }),
      alignCol: item.alignment,
      formatNumbDecimals: item.decimal_points,
      symbol: {
        align: item.symbol_alignment === "before" ? "left" : "right",
        value: item.symbol || "",
      },
    };
  });

  if (withActions) {
    return [...result, action] as TableGridColumnSchema[];
  } else {
    return result as TableGridColumnSchema[];
  }
};

const dynamicBgColors = (cols: ElpromTableDataCols, theme: Theme) => {
  const operation: TableDynamicCondition = {
    operation: "always",
    cellValue: 0,
  };

  if (cols.id === "collateral_percentage") {
    return {
      ...operation,
      bgColor: theme.palette.success.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "in_delivery_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.warning.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "non_collateral_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.error.main,
      partialFillBasedOnVal: true,
    };
  }

  return undefined;
};
