import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useAuthedContext } from "../../context/AuthContext";
import Alert from "../../Components/MaterialUI/Alert";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import CameraOrDeviceUpload from "../../Components/PageComponents/VisionControl/LabelRecognition/CameraOrDeviceUpload";
import { FileWithPath } from "react-dropzone";
import { css } from "@emotion/react";
import Collapse from "../../Components/MaterialUI/Collapse";
import { PostQueryCodeScanningReadCodeInput } from "../../Api/VisionControl/apiVisionControlInputs";
import callApi, { ResponseError } from "../../Api/callApi";
import { PostQueryCodeScanningReadCodeSnippet } from "../../Api/VisionControl/apiVisionControlSnippets";
import { postQueryCodeScanningReadCode } from "../../Api/VisionControl/apiVisionControlPostQueries";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import ObjectTrackingTable from "../../Components/PageComponents/ObjectTracking/ObjectTrackingTable";
import {
  GetQueryTTLocationsSnippet,
  GetQueryTTObjectSnippet,
  GetQueryTTObjectsAvailabilitySnippet,
  PostQueryCreateTTObjectSnippet,
  PostQueryUpdateTTObjectSnippet,
} from "../../Api/TrackAndTrace/apiTTSnippets";
import {
  getQueryTTLocations,
  getQueryTTObject,
  getQueryTTObjectsAvailability,
} from "../../Api/TrackAndTrace/apiTTGetQueries";
import Select from "../../Components/MaterialUI/FormFields/Select";
import Button from "../../Components/MaterialUI/Button";
import {
  postQueryCreateMap,
  postQueryCreateObject,
  postQueryUpdateObject,
} from "../../Api/TrackAndTrace/apiTTPostQueries";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import { ObjectAvailability } from "../../Api/TrackAndTrace/apiTTDataTypes";
import { PostQueryCreateTTMapInput } from "../../Api/TrackAndTrace/apiTTInputs";
import { useLanguageContext } from "../../context/LanguageContext";

type ScannedImage = {
  data: PostQueryCodeScanningReadCodeSnippet;
  imageUrl: string;
};

const cssStyles = {
  scannedImageContainer: css({
    width: "100%",
    height: "100%",
    maxHeight: "400px",
    position: "relative",
  }),
  scannedImage: css({
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute",
  }),
};

const CodeScanningPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssStyles,
  };
  const { authedUser, setAuthedUser } = useAuthedContext();
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [imgScanStatus, setImgScanStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [scannedImage, setScannedImage] = useState<ScannedImage | null>(null);
  const [objectsAvailabilityData, setObjectsAvailabilityData] =
    useState<GetQueryTTObjectsAvailabilitySnippet>([]);
  const [originalObjectsAvailabilityData, setOriginalObjectsAvailabilityData] =
    useState<GetQueryTTObjectsAvailabilitySnippet>([]);
  const [locationSelectData, setLocationSelectData] = useState<string>("");
  const [locationsData, setLocationsData] = useState<GetQueryTTLocationsSnippet>();
  const [isObjectExists, setIsObjectExists] = useState<boolean>(false);
  const [isCreateNewObject, setIsCreateNewObject] = useState<boolean>(false);
  const [isUpdateObject, setIsUpdateObject] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const objectData = await callApi<GetQueryTTObjectsAvailabilitySnippet>({
          query: getQueryTTObjectsAvailability,
          auth: { setAuthedUser },
        });
        const locationData = await callApi<GetQueryTTLocationsSnippet>({
          query: getQueryTTLocations,
          auth: { setAuthedUser },
        });

        setObjectsAvailabilityData(objectData);
        setOriginalObjectsAvailabilityData(objectData);
        setLocationsData(locationData);
      } catch (error) {
        console.log("Error fetching object availability data:", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (file) {
        try {
          setImgScanStatus("loading");
          setAlertMessage(t("Loading..."));

          const input: PostQueryCodeScanningReadCodeInput = {
            image: file,
          };
          const scannedData = await callApi<
            PostQueryCodeScanningReadCodeSnippet | ResponseError
          >({
            query: postQueryCodeScanningReadCode(input),
            auth: { setAuthedUser },
          });
          if ("detail" in scannedData) {
            setImgScanStatus("warning");
            setAlertMessage(t("No code found to read its data"));
            return;
          }
          const imageUrl = URL.createObjectURL(file);

          setScannedImage({
            data: scannedData,
            imageUrl: imageUrl,
          });
          setImgScanStatus("success");
          setAlertMessage(null);
        } catch (err) {
          console.log("useEffect err ", err.message);
          setImgScanStatus("error");
          setAlertMessage(t("Something went wrong"));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    (async () => {
      if (scannedImage && scannedImage.data) {
        const scannedObjectCodes = scannedImage.data.map((data) => data.data);

        const existingObjects = objectsAvailabilityData?.filter((object) =>
          scannedObjectCodes.includes(object.object_code)
        );

        if (existingObjects.length > 0) {
          setIsObjectExists(true);
          setObjectsAvailabilityData(existingObjects);
        }
      } else {
        setIsObjectExists(false);
        setObjectsAvailabilityData(originalObjectsAvailabilityData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedImage]);

  const handleCreateNewObjectAvailability = async () => {
    setImgScanStatus("loading");
    setAlertMessage(t("Loading..."));

    if (!authedUser) return;

    if (!locationSelectData) {
      setImgScanStatus("error");
      setAlertMessage(t("Please select a location"));
      return;
    }

    if (!scannedImage) return;

    const newObjects = scannedImage.data.filter((imageData) => {
      const objectCode = imageData.data;
      return !objectsAvailabilityData?.some(
        (object) => object.object_code === objectCode
      );
    });

    if (newObjects.length === 0) return;

    const newAvailabilityObjectsData: ObjectAvailability[] = [];
    try {
      await Promise.all(
        newObjects.map(async (object) => {
          const input = {
            id: "",
            object_code: object.data,
            object_type: object.type,
            vendor: "Vendor Name",
            quantity_in_stock: 1,
            last_restock_date: new Date().toISOString(),
            min_quantity: 5,
            unit_price: 20,
            total_value: 20,
            purchase_date: new Date().toISOString(),
            location: locationSelectData,
            is_deleted: false,
            is_location: false,
          };

          try {
            const newObject = await callApi<PostQueryCreateTTObjectSnippet>({
              query: postQueryCreateObject(input),
              auth: { setAuthedUser },
            });

            const selectedLocationCode = locationsData?.find(
              (object) => object.location === locationSelectData
            );

            if (!newObject || !selectedLocationCode) {
              throw new Error("Failed to create object");
            }

            const objectMappingInput: PostQueryCreateTTMapInput = {
              parent_id: selectedLocationCode.id,
              child_id: newObject.id,
            };

            const objectMapping = await callApi({
              query: postQueryCreateMap(objectMappingInput),
              auth: { setAuthedUser },
            });

            if (newObject && objectMapping) {
              const newAvailabilityObject: ObjectAvailability = {
                object_code: object.data,
                object_type: object.type,
                last_scan: newObject.last_restock_date,
                date_time: newObject.purchase_date,
                last_location: newObject.location,
                current_location: newObject.location,
                status: "Available",
                comment: "",
                history_track: "",
              };

              newAvailabilityObjectsData.push(newAvailabilityObject);
            }
          } catch (error) {
            console.log("Error creating object:", error);
            throw new Error("Failed to create object");
          }
        })
      );

      setOriginalObjectsAvailabilityData([
        ...objectsAvailabilityData,
        ...newAvailabilityObjectsData,
      ]);

      handleCancel();

      setImgScanStatus("success");
      setAlertMessage(t("Object(s) created and saved successfully"));
    } catch (error) {
      setImgScanStatus("error");
      setAlertMessage(t("Error creating object(s)"));
      console.log("Error filtering new objects:", error);
      throw new Error("Failed to filter new objects");
    }
  };

  const handleUpdateObjectAvailability = async () => {
    setImgScanStatus("loading");
    setAlertMessage(t("Loading..."));

    if (!locationSelectData) {
      setImgScanStatus("error");
      setAlertMessage(t("Please select a location"));
      return;
    }

    if (!scannedImage) return;

    try {
      await Promise.all(
        scannedImage.data.map(async (object) => {
          try {
            const oldObject = await callApi<GetQueryTTObjectSnippet>({
              query: getQueryTTObject(object.data),
              auth: { setAuthedUser },
            });

            if (!oldObject?.id) return;

            const input = {
              id: oldObject.id,
              object_code: object.data,
              object_type: object.type,
              vendor: oldObject.vendor,
              quantity_in_stock: oldObject.quantity_in_stock,
              last_restock_date: oldObject.last_restock_date,
              min_quantity: oldObject.min_quantity,
              unit_price: oldObject.unit_price,
              total_value: oldObject.total_value,
              purchase_date: oldObject.purchase_date,
              location: locationSelectData,
              attachments: [],
              is_deleted: false,
              is_location: false,
            };

            await callApi<PostQueryUpdateTTObjectSnippet>({
              query: postQueryUpdateObject(oldObject?.id, {
                ...input,
                attachments: [],
              }),
              auth: { setAuthedUser },
            });
          } catch (error) {
            console.log("Error updating object:", error);
            throw new Error("Failed to update object");
          }
        })
      );

      const updatedObjectsAvailabilityData = originalObjectsAvailabilityData.map(
        (availabilityObject) => {
          const isObjectToUpdate = scannedImage.data.some(
            (scannedObject) => scannedObject.data === availabilityObject.object_code
          );
          return isObjectToUpdate
            ? {
                ...availabilityObject,
                current_location: locationSelectData,
              }
            : availabilityObject;
        }
      );

      setOriginalObjectsAvailabilityData(updatedObjectsAvailabilityData);

      handleCancel();

      setImgScanStatus("success");
      setAlertMessage(t("Object(s) updated and saved successfully"));
    } catch (error) {
      setImgScanStatus("error");
      setAlertMessage(t("Error updating object(s)"));
      console.log("Error filtering new objects:", error);
      throw new Error("Failed to filter new objects");
    }
  };

  const handleCancel = async () => {
    setScannedImage(null);
    setFile(null);
    setLocationSelectData("");
    setIsCreateNewObject(false);
    setIsUpdateObject(false);
  };

  return (
    <ContentBox css={[styles.width100, styles.height100]}>
      {!scannedImage ? (
        <Stack spacing={2} ml={1} direction="row" alignItems="center">
          <Typography variant="h3" color="textSecondary">
            {t("Scan a Code")}
          </Typography>
          <CameraOrDeviceUpload
            file={file}
            setFile={setFile}
            buttonText={t("Upload Code")}
          />
        </Stack>
      ) : (
        <Stack css={{ width: "100%" }} spacing={2}>
          <Collapse in={!!scannedImage?.imageUrl}>
            {scannedImage?.imageUrl ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box component="div" css={styles.scannedImageContainer}>
                    <img
                      css={styles.scannedImage}
                      src={scannedImage.imageUrl}
                      alt="scanned image"
                      loading="lazy"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Stack spacing={3}>
                    <Typography component="p" variant="h2" mb={2}>
                      {t("Scanned Code Data")}
                    </Typography>

                    {scannedImage.data.map((item, index) => (
                      <Stack spacing={0.5} key={`code-data-${index}`}>
                        <LabelWithBoldedPart text="Type" bolded={item.type} />
                        <LabelWithBoldedPart text="Data" bolded={item.data} />
                      </Stack>
                    ))}

                    <Divider />

                    {!isObjectExists && (
                      <>
                        <Typography component="p" variant="h2">
                          {t("Object(s) do(es) not exist")}
                        </Typography>

                        <Stack>
                          <Typography
                            component="p"
                            style={theme.customizedTextStyles.labelStyles}
                          >
                            {t("The scanned code data doesn't exist.")}
                          </Typography>

                          <Typography
                            component="p"
                            style={theme.customizedTextStyles.labelStyles}
                          >
                            {t("Do you want to create a new object(s)?")}
                          </Typography>
                        </Stack>

                        {!isCreateNewObject && (
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            gap={2}
                          >
                            <Button
                              color="error"
                              onClick={handleCancel}
                              css={[styles.width100, styles.widthLimit10]}
                            >
                              {t("Cancel")}
                            </Button>

                            <Button
                              color="secondary"
                              onClick={() => setIsCreateNewObject(true)}
                              css={[styles.width100, styles.widthLimit10]}
                            >
                              {t("Create")}
                            </Button>
                          </Stack>
                        )}
                      </>
                    )}

                    {isCreateNewObject && !isObjectExists && (
                      <>
                        <Typography component="p" variant="h2">
                          {t("Before creating a new object(s), please choose a location")}
                        </Typography>

                        {locationsData ? (
                          <Select
                            css={styles.widthLimit30}
                            selectOptions={handleGetSelectOption(
                              locationsData.map((location) => location.location)
                            )}
                            label={t("Choose a location")}
                            value={locationSelectData}
                            onChange={(e) => setLocationSelectData(e.target.value)}
                            disabled={imgScanStatus === "loading"}
                          />
                        ) : null}

                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={2}
                        >
                          <Button
                            color="error"
                            onClick={handleCancel}
                            fullWidth
                            disabled={imgScanStatus === "loading"}
                          >
                            {t("Cancel")}
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleCreateNewObjectAvailability}
                            fullWidth
                            disabled={imgScanStatus === "loading"}
                          >
                            {t("Create")}
                          </Button>
                        </Stack>
                      </>
                    )}

                    {isObjectExists && (
                      <>
                        <Typography component="p" variant="h2">
                          {t("Object(s) already exist(s)")}
                        </Typography>

                        <Typography component="p" variant="body1">
                          {t("The scanned code data already exist.")}
                        </Typography>

                        {!isUpdateObject && (
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            gap={2}
                          >
                            <Button
                              color="secondary"
                              onClick={() => setIsUpdateObject(true)}
                              fullWidth
                            >
                              {t("Update")}
                            </Button>

                            <Button color="primary" onClick={handleCancel} fullWidth>
                              Ok
                            </Button>
                          </Stack>
                        )}
                      </>
                    )}

                    {isObjectExists && isUpdateObject && (
                      <>
                        <Typography component="p" variant="h2">
                          {t("To update the object(s), please choose a new location")}
                        </Typography>

                        {locationsData ? (
                          <Select
                            css={styles.widthLimit30}
                            selectOptions={handleGetSelectOption(
                              locationsData.map((location) => location.location)
                            )}
                            label={t("Choose a location")}
                            value={locationSelectData}
                            onChange={(e) => setLocationSelectData(e.target.value)}
                            disabled={imgScanStatus === "loading"}
                          />
                        ) : null}

                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={2}
                        >
                          <Button color="error" onClick={handleCancel} fullWidth>
                            {t("Cancel")}
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleUpdateObjectAvailability}
                            fullWidth
                          >
                            {t("Update")}
                          </Button>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ) : null}
          </Collapse>
          <Divider />
        </Stack>
      )}

      <Alert
        css={[styles.reverseLabelBreak, styles.widthLimit20]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={imgScanStatus}
      />

      <Typography variant="h2" color="textSecondary" ml={1} mt={2}>
        {t("Objects Availability Table")}
      </Typography>

      <Box component="div" css={[styles.topBottomMargin3]}>
        {objectsAvailabilityData ? (
          <ObjectTrackingTable objectsAvailabilityData={objectsAvailabilityData} />
        ) : (
          <Typography component="p" variant="body1">
            {t("Loading...")}
          </Typography>
        )}
      </Box>
    </ContentBox>
  );
};

export default CodeScanningPage;
