import { Stack, Typography, Divider, IconButton, useTheme } from "@mui/material";
import FileDropzone from "../../../Components/SmallComponents/Dropzone/FileDropzone";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import { ElpromFileMeta, ElpromFileTypes } from "../API/apiElpromSnippets";
import cssFontStyles from "../../../Global/Styles/font";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import DownloadIcon from "@mui/icons-material/Download";
import { FileWithPath } from "react-dropzone";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryElpromUploadSupcoInput } from "../API/apiElpromInputs";
import {
  postQueryElpromUploadKso,
  postQueryElpromUploadSupco,
  postQueryElpromUploadPrices,
  postQueryElpromUploadPriorities,
} from "../API/apiElpromPostQueries";
import Alert from "../../../Components/MaterialUI/Alert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  getQueryElpromCollateralDetailsFile,
  getQueryElpromCollateralSummaryFile,
} from "../API/apiElpromGetQueries";

type ExtraFileType = "details" | "summary";
const EXTRA_FILES: {
  file_name: string;
  type: ExtraFileType;
}[] = [
  {
    file_name: "Collateral Summary",
    type: "summary",
  },
  {
    file_name: "Collateral Details",
    type: "details",
  },
];

interface ElpromFilesModalProps {
  filesMeta: ElpromFileMeta[];
  handleFetchTableData: () => Promise<void>;
  handleFetchFiles: () => Promise<void>;
}

const ElpromFilesModal: React.FC<ElpromFilesModalProps> = ({
  filesMeta,
  handleFetchTableData,
  handleFetchFiles,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);
  const [fileType, setFileType] = useState<ElpromFileTypes | null>(null);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      if (file && fileType) {
        setAlertMessage(null);
        setAlertStatus(null);
        try {
          setAlertMessage(t("Uploading File..."));
          setAlertStatus("loading");

          await handleReplace(fileType, file);

          await handleFetchTableData();
          await handleFetchFiles();
          setAlertMessage(t("File uploaded successfully"));
          setAlertStatus("success");
        } catch (error) {
          console.log("err: ", error);
          setAlertMessage(t("File has not been processed - incorrect file data"));
          setAlertStatus("error");
        }
      }
      setFile(null);
      setFileType(null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, fileType]);

  const handleDownloadFile = async (link: string) => {
    try {
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = `https://${link}`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  const handleReplace = async (type: ElpromFileTypes, file: FileWithPath) => {
    try {
      switch (type) {
        case "kso": {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadKso({ file }),
            auth: { setAuthedUser },
          });
          break;
        }
        case "supco": {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadSupco({ file }),
            auth: { setAuthedUser },
          });
          break;
        }
        case "prices": {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadPrices({ file }),
            auth: { setAuthedUser },
          });
          break;
        }
        case "priorities": {
          await callApi<PostQueryElpromUploadSupcoInput>({
            query: postQueryElpromUploadPriorities({ file }),
            auth: { setAuthedUser },
          });
          await handleFetchTableData();
          break;
        }
      }
    } catch (err) {
      setAlertMessage(t("Something went wrong"));
      setAlertStatus("error");
    }
    setFile(null);
    setFileType(null);
  };

  const handleDownloadExtra = async (type: ExtraFileType) => {
    try {
      let result: any = null;
      switch (type) {
        case "details": {
          result = await callApi<any>({
            query: getQueryElpromCollateralDetailsFile(),
            auth: { setAuthedUser },
          });
          break;
        }
        case "summary": {
          result = await callApi<any>({
            query: getQueryElpromCollateralSummaryFile(),
            auth: { setAuthedUser },
          });
          break;
        }
      }

      const blob = await result.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `collateral_${type}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log("err ", err);
    }
  };

  return (
    <Stack spacing={2}>
      <Alert
        css={[styles.widthLimit20, styles.marginHorizontalAuto, styles.contentBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />

      {filesMeta.map((item) => (
        <Stack
          key={`${item.id}-${item.file_type}`}
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography css={styles.bolderText} variant="body1">
            {item.file_type}
          </Typography>
          <Card
            item={item}
            handleDownloadFile={handleDownloadFile}
            setAlertMessage={setAlertMessage}
            alertStatus={alertStatus}
            setFileType={setFileType}
            setFile={setFile}
          />
        </Stack>
      ))}

      <Divider flexItem />

      {EXTRA_FILES.map((item) => (
        <Stack
          key={item.file_name}
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography css={styles.bolderText} variant="body1">
            {item.file_name}
          </Typography>

          <IconButton
            aria-label="download file"
            onClick={() => handleDownloadExtra(item.type)}
            disabled={alertStatus === "loading"}
          >
            <DownloadIcon color="secondary" />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  );
};

export default ElpromFilesModal;

interface CardProps {
  item: ElpromFileMeta;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  alertStatus: FormStatuses;
  handleDownloadFile: (link: string) => Promise<void>;
  setFileType?: React.Dispatch<React.SetStateAction<ElpromFileTypes | null>>;
  setFile?: React.Dispatch<React.SetStateAction<FileWithPath | null>>;
}

const Card: React.FC<CardProps> = ({
  item,
  handleDownloadFile,
  setAlertMessage,
  alertStatus,
  setFileType,
  setFile,
}) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      divider={<Divider flexItem orientation="vertical" />}
    >
      <Typography variant="body1">{item.file_name}</Typography>
      <Typography variant="body1">{formatDateAndTime(item.uploaded_at)}</Typography>

      {setFile && setFileType ? (
        <FileDropzone
          setFiles={(files) => setFile(files[0])}
          setErrorMessage={setAlertMessage}
          handleOnDrop={() => setFileType(item.file_type)}
          accept={{
            ".xlsx, .xls, csv": [
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "text/csv",
            ],
          }}
        >
          <IconButton aria-label="replace file" disabled={alertStatus === "loading"}>
            <FileUploadIcon color="primary" />
          </IconButton>
        </FileDropzone>
      ) : null}

      <IconButton
        aria-label="download file"
        onClick={() => handleDownloadFile(item.file_url)}
        disabled={alertStatus === "loading"}
        sx={
          item.file_type !== "priorities"
            ? undefined
            : {
                zIndex: "-1",
              }
        }
      >
        <DownloadIcon color="secondary" />
      </IconButton>
    </Stack>
  );
};
