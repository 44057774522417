import { Box, Stack, Theme, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import { useEffect, useState } from "react";
import Alert from "../../Components/MaterialUI/Alert";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import RadioGroup from "../../Components/MaterialUI/FormFields/RadioGroup";
import callApi from "../../Api/callApi";
import {
  GetQueryAllLabelImagesSnippet,
  GetQueryLabelLanguagesSnippet,
} from "../../Api/VisionControl/apiVisionControlSnippets";
import {
  getQueryAllLabelImages,
  getQueryLabelLanguages,
} from "../../Api/VisionControl/apiVisionControlGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { FormStatuses, SelectOption } from "../../Global/Types/commonTypes";
import cssFontStyles from "../../Global/Styles/font";
import Modal from "../../Components/MaterialUI/Modal";
import { css } from "@emotion/react";
import {
  AllLabelImages,
  LabelRecognitionTestedModalData,
  LabelRecognitionTestedModalDataNoMaster,
} from "../../Components/PageComponents/VisionControl/LabelRecognition/labelRecognitionUtils";
import LabelImageList from "../../Components/PageComponents/VisionControl/LabelRecognition/LabelImageList";
import UploadMasterLabel from "../../Components/PageComponents/VisionControl/LabelRecognition/UploadMasterLabel";
import UploadSampleLabel from "../../Components/PageComponents/VisionControl/LabelRecognition/UploadSampleLabel";
import LabelTestedImageModal from "../../Components/PageComponents/VisionControl/LabelRecognition/LabelTestedImageModal";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateSchemeArray } from "../../Global/Hooks/useTranslations";

const cssStyles = (theme: Theme) => ({
  imageContainer: css({
    boxShadow: theme.shadows[7],
    width: "fit-content",
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1.5),
  }),
  modalImageContainer: css({
    width: "100%",
    maxWidth: theme.breakpoints.values.xl,
  }),
  imageText: css({
    padding: theme.spacing(2),
    background:
      theme.palette.mode === "dark"
        ? theme.palette.customColors.darkBackgroundColor
        : theme.palette.grey[100],
    width: "100%",
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  }),
});

type FormRadio = "Upload original" | "Upload sample";
const FORM_RADIO_OPTIONS = handleGetSelectOption(["Upload original", "Upload sample"]);

const LabelRecognitionPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssFontStyles,
    ...cssStyles(theme),
  };
  const [initialPageLoad, setInitialPageLoad] = useState<FormStatuses>("loading");
  const [allLabelImages, setAllLabelImages] = useState<AllLabelImages | null>(null);
  const [allLanguage, setAllLanguage] = useState<SelectOption[]>([]);
  // Modal state
  const [newImageModal, setNewImageModal] = useState<boolean>(false);
  const [testedModalDataNoMaster, setTestedModalDataNoMaster] =
    useState<LabelRecognitionTestedModalDataNoMaster | null>(null);
  const [uploadForm, setUploadForm] = useState<FormRadio>("Upload original");
  const [testedModalData, setTestedModalData] =
    useState<LabelRecognitionTestedModalData | null>(null);

  const { setAuthedUser } = useAuthedContext();

  /**
   * Fetches languages and labels on page load
   */
  useEffect(() => {
    (async () => {
      try {
        setInitialPageLoad("loading");
        const langs = await callApi<GetQueryLabelLanguagesSnippet>({
          query: getQueryLabelLanguages,
          auth: { setAuthedUser },
        });

        const langOptions: SelectOption[] = Object.entries(langs).map(([key, value]) => ({
          value: key,
          description: value,
        }));

        await fetchAllLabels("all");

        setAllLanguage(langOptions);
        setInitialPageLoad("success");
      } catch (err) {
        console.log("LabelRecognition err ", err);
        setInitialPageLoad("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllLabels = async (type: "all" | "master" | "tested") => {
    if (type === "all" || type === "master") {
      const allMasterLabels = await callApi<GetQueryAllLabelImagesSnippet[]>({
        query: getQueryAllLabelImages("master"),
        auth: { setAuthedUser },
      });
      setAllLabelImages((prev) => ({
        master: allMasterLabels.map((item) => ({
          url: item.url,
          image_name: item.image_name,
          bucket: "master",
          last_modified: item.last_modified,
          languages: item.languages,
        })),
        tested: prev?.tested || [],
      }));
    }

    if (type === "all" || type === "tested") {
      const allTestedLabels = await callApi<GetQueryAllLabelImagesSnippet[]>({
        query: getQueryAllLabelImages("tested"),
        auth: { setAuthedUser },
      });
      setAllLabelImages((prev) => ({
        master: prev?.master || [],
        tested: allTestedLabels.map((item) => ({
          url: item.url,
          image_name: item.image_name,
          bucket: "tested",
          last_modified: item.last_modified,
          languages: item.languages,
        })),
      }));
    }
  };

  const MASTER_LABEL_INFO = t(
    "First phase, upload an original label image. This is a label image with correct text for a given product. Our system will compare the original label against sample labels and reveal any discrepancies or inaccuracies in the product text."
  );
  const SAMPLE_LABEL_INFO = t(
    "Second phase, upload a sample label image which will initiate a comprehensive evaluation process. This sample label, is compared against the original label you have selected. The outcome is an image with highlighting on any deviations in text and providing you with a clear assessment of the accuracy of your product labels."
  );

  const handleOnClose = () => {
    setTestedModalData(null);
    setNewImageModal(false);
    setTestedModalDataNoMaster(null);
  };

  if (initialPageLoad === "loading") {
    return <Typography variant="h1">{t("Loading...")}</Typography>;
  }
  if (initialPageLoad === "error") {
    return (
      <Alert message={t("Something went wrong")} showAlert={true} severity="error" />
    );
  }

  return (
    <Box component="div">
      <LabelImageList
        allLabelImages={allLabelImages}
        fetchAllLabels={fetchAllLabels}
        setTestedModalData={setTestedModalData}
        stylesImageContainer={styles.imageContainer}
        stylesImageText={styles.imageText}
        setNewImageModal={setNewImageModal}
        setTestedModalDataNoMaster={setTestedModalDataNoMaster}
        allLanguages={allLanguage}
      />

      <Modal
        open={Boolean(testedModalData || newImageModal || testedModalDataNoMaster)}
        onClose={handleOnClose}
        maxWidth={newImageModal ? "sm" : "xl"}
        label={newImageModal ? t("Upload New Label Image") : t("Labels")}
        fullWidth={!!newImageModal}
      >
        {testedModalDataNoMaster ? (
          <Box component="div" css={styles.width100}>
            <Alert
              css={styles.contentBreak}
              message={t("Original label for this sample has been deleted")}
              showAlert={true}
              severity="warning"
            />
            <Box component="div" css={styles.imageContainer}>
              <img
                src={testedModalDataNoMaster.testedLabelUrl}
                alt="sample label"
                loading="lazy"
              />
            </Box>
          </Box>
        ) : null}

        {newImageModal ? (
          <Stack spacing={4}>
            <RadioGroup
              radioOptions={useTranslateSchemeArray(FORM_RADIO_OPTIONS)}
              label={t("Label Type")}
              value={uploadForm}
              onChange={(e) => setUploadForm(e.target.value as FormRadio)}
              row
            />
            <Typography variant="body1">
              {uploadForm === "Upload original" ? MASTER_LABEL_INFO : SAMPLE_LABEL_INFO}
            </Typography>

            {uploadForm === "Upload original" ? (
              <UploadMasterLabel
                allLanguage={allLanguage}
                fetchAllLabels={fetchAllLabels}
              />
            ) : (
              <UploadSampleLabel
                allLabelImages={allLabelImages}
                fetchAllLabels={fetchAllLabels}
                setTestedModalData={setTestedModalData}
                setNewImageModal={setNewImageModal}
              />
            )}
          </Stack>
        ) : null}

        {testedModalData ? (
          <LabelTestedImageModal
            css={styles.modalImageContainer}
            testedModalData={testedModalData}
            stylesImageContainer={styles.imageContainer}
            stylesImageText={styles.imageText}
          />
        ) : null}
      </Modal>
    </Box>
  );
};

export default LabelRecognitionPage;
