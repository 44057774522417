import { useCallback, useEffect, useState } from "react";
import {
  MQTTSubMapping,
  MQTTSubscription,
} from "../../../../../Api/DataSources/apiDSDataTypes";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import MqttConnectionParameters from "./MqttConnectionParameters";
import { MqttConnectionParametersData } from "./mqttConnectionTypes";
import { getQueryMQTTBrokerConnect } from "../../../../../Api/DataSources/apiDSGetQueries";
import {
  GetQueryMQTTBrokerConnectSnippet,
  PostQueryMQTTStartSubscriptionSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import callApi from "../../../../../Api/callApi";
import { useAuthedContext } from "../../../../../context/AuthContext";
import MqttTopics from "./MqttTopics";
import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import MqttMappingConfiguration from "./MqttMappingConfiguration";
import {
  PostQueryMQTTStartSubscriptionInput,
  PostQueryMQTTSubscriptionMappingInput,
} from "../../../../../Api/DataSources/apiDSInputs";
import {
  postQueryMQTTDeleteSubscription,
  postQueryMQTTStartSubscription,
  postQueryMQTTSubscriptionMapping,
} from "../../../../../Api/DataSources/apiDSPostQueries";
import { useNavigate } from "react-router-dom";
import { getCurrentEnv } from "../../../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateArray } from "../../../../../Global/Hooks/useTranslations";

const IS_PARTNER_ENV = getCurrentEnv() === "partner";

const EMPTY_SUB_DATA: MQTTSubscription = {
  id: "",
  client_name: "",
  broker_host: IS_PARTNER_ENV ? "mqtt-example-broker" : "giant-mqtt-broker",
  broker_port: 1883,
  topics: [],
  status: "active",
  mapping: [],
  start_time: "",
  end_time: "",
};

const STAGES = ["Connection Parameters", "Broker Topics", "Mapping Configuration"];

interface MqttConnectionProps {
  handleStartOver?: () => void;
  editSubForm?: {
    sub: MQTTSubscription;
  };
  onSuccessUrl: string;
  title: string;
}

const MqttConnection: React.FC<MqttConnectionProps> = ({
  handleStartOver,
  editSubForm,
  onSuccessUrl,
  title,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  // activeStep === 1
  const [sub, setSub] = useState<MQTTSubscription>(editSubForm?.sub || EMPTY_SUB_DATA);

  // activeStep === 2
  const [allTopics, setAllTopics] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>(
    editSubForm?.sub.topics || []
  );

  // activeStep === 3
  const [subMapping, setSubMapping] = useState<MQTTSubMapping[]>(sub?.mapping || []);
  // initial values
  const [initialSubMapping, setInitialSubMapping] =
    useState<MQTTSubMapping[]>(subMapping);
  const [initialSelectedTopics, setInitialSelectedTopics] =
    useState<string[]>(selectedTopics);
  const [initialSub, setInitialSub] = useState<MQTTSubscription>(sub);

  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    const areEqual = JSON.stringify(initialSub) === JSON.stringify(sub);
    const mappingsAreEqual =
      JSON.stringify(subMapping) === JSON.stringify(initialSubMapping);
    const topicsAreEqual =
      JSON.stringify(selectedTopics) === JSON.stringify(initialSelectedTopics);
    if (areEqual && mappingsAreEqual && topicsAreEqual) {
      setHasUnsavedChanges(false);
    } else {
      setHasUnsavedChanges(true);
    }
  }, [
    sub,
    initialSub,
    initialSubMapping,
    subMapping,
    selectedTopics,
    initialSelectedTopics,
  ]);

  const handleMakeMqttRequest = async () => {
    if (editSubForm?.sub.id) {
      if (sub.status === "active") {
        // cannot update an active sub
        await handleStopSub();
      }
      await handleUpdateSub(sub.id);
      await handleStartSub();
    }
  };

  const handleMqttStageOperations = (): boolean => {
    setAlertStatus("loading");

    if (activeStep === 0) {
      if (!sub.broker_host || !sub.broker_port || !sub.client_name) {
        setAlertStatus("warning");
        setAlertMessage(
          t("You must provide broker host, broker port, and connection name")
        );
        return false;
      }
    }

    if (activeStep === 1) {
      if (!selectedTopics.length) {
        setAlertStatus("warning");
        setAlertMessage(t("You must select at least one topic"));
        return false;
      }
    }

    return true;
  };

  const handleOnNextStage = async () => {
    try {
      const operations = handleMqttStageOperations();
      if (!operations) {
        return false;
      }

      if (activeStep === 0) {
        const topics = await callApi<GetQueryMQTTBrokerConnectSnippet>({
          query: getQueryMQTTBrokerConnect(sub.broker_host, sub.broker_port),
          auth: {
            setAuthedUser,
          },
        });

        setAllTopics(topics);
      }

      if (activeStep === 2) {
        handleUpdateInitials();
        if (editSubForm?.sub.id) {
          await handleMakeMqttRequest();
        } else {
          const startedSub = await handleStartSub();
          await handleUpdateSub(startedSub.id);
        }

        setAlertStatus("success");
        setAlertMessage(
          editSubForm?.sub.id
            ? t("Connection successfully updated")
            : t("Connection successfully created")
        );
        navigate({ pathname: onSuccessUrl });
        return false;
      }

      setAlertStatus(null);
      setAlertMessage(null);
      return true;
    } catch (err) {
      console.log("err ", err);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
      return false;
    }
  };

  const handleUpdateConnectionParamsData = useCallback(
    (data: MqttConnectionParametersData) => {
      setSub((prev) => ({
        ...prev,
        broker_host: data.broker,
        broker_port: +data.port,
        client_name: data.name,
      }));
    },
    []
  );

  const handleStartSub = async () => {
    // if no ID, a new sub is created, else its updated
    const thisSubID = sub.id;

    const input: PostQueryMQTTStartSubscriptionInput = {
      client_name: sub.client_name,
      broker_host: sub.broker_host,
      broker_port: sub.broker_port,
      topics: selectedTopics,
      ...(thisSubID && { id: thisSubID }),
    };

    return await callApi<PostQueryMQTTStartSubscriptionSnippet>({
      query: postQueryMQTTStartSubscription(input),
      auth: {
        setAuthedUser,
      },
    });
  };

  /** we cannot update an active sub data */
  const handleStopSub = async () => {
    await callApi({
      query: postQueryMQTTDeleteSubscription(sub.id),
      auth: { setAuthedUser },
    });
  };

  const handleUpdateSub = async (subID: string) => {
    const mappingInput: PostQueryMQTTSubscriptionMappingInput = subMapping;
    await callApi({
      query: postQueryMQTTSubscriptionMapping(subID, mappingInput),
      auth: { setAuthedUser },
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (!editSubForm?.sub.id) {
        return;
      }
      const operations = handleMqttStageOperations();
      if (operations) {
        handleUpdateInitials();
        await handleMakeMqttRequest();
        navigate({ pathname: onSuccessUrl });
      }
    } catch (err) {
      console.log("handleSaveChanges ", err);
    }
  };

  const handleCancelForm = () => {
    navigate({ pathname: onSuccessUrl });
  };

  const handleUpdateInitials = () => {
    setInitialSub(sub);
    setInitialSubMapping(subMapping);
    setInitialSelectedTopics(selectedTopics);
  };

  return (
    <MultiStageForm
      steps={useTranslateArray(STAGES)}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
      firstBack={handleStartOver}
      lastNextButtonLabel={
        editSubForm?.sub.id ? "Confirm and Apply Changes" : "Create New Connection"
      }
      title={title}
      saveCurrentStageData={editSubForm?.sub.id ? handleSaveChanges : undefined}
      handleExitForm={handleCancelForm}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <>
        {activeStep === 0 ? (
          <Box component="div" css={styles.flexCenter}>
            <Box component="div">
              <MqttConnectionParameters
                data={{
                  broker: sub.broker_host,
                  port: `${sub.broker_port}`,
                  name: sub.client_name,
                }}
                isLoading={alertStatus === "loading"}
                handleUpdateData={handleUpdateConnectionParamsData}
                isEditSub={!!editSubForm?.sub.id}
              />
            </Box>
          </Box>
        ) : null}

        {activeStep === 1 ? (
          <MqttTopics
            allTopics={allTopics}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            broker={sub.broker_host}
            port={sub.broker_port}
          />
        ) : null}

        {activeStep === 2 ? (
          <Box component="div" css={styles.flexCenter}>
            <Box component="div" css={styles.widthLimit50}>
              <MqttMappingConfiguration
                selectedTopics={selectedTopics}
                subMapping={subMapping}
                setSubMapping={setSubMapping}
                broker={sub.broker_host}
                port={sub.broker_port}
              />
            </Box>
          </Box>
        ) : null}
      </>
    </MultiStageForm>
  );
};

export default MqttConnection;
