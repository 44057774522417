import { Box, IconButton, Stack, Theme, Typography } from "@mui/material";
import ReactFlowCustom from "../../Components/SmallComponents/ReactFlow/ReactFlowCustom";
import { css } from "@emotion/react";
import {
  AUTH_LAYOUT_PADDING,
  AppRouterProps,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
} from "../../Layout/layoutVariables";
import React, { useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Modal from "../../Components/MaterialUI/Modal";
import EditWorkflowForm from "../../Components/PageComponents/MyWorkflows/EditWorkflowForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "../../Components/MaterialUI/Button";
import Alert from "../../Components/MaterialUI/Alert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ReactCustomFlowType } from "../../Components/SmallComponents/ReactFlow/reactFlowUtils";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import callApi from "../../Api/callApi";
import {
  deleteQueryBoschProcess,
  putQueryBoschProcess,
} from "../../Api/BoschWorkflows/apiBoschWorkflowsPostQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { PutQueryBoschWorkflowInput } from "../../Api/BoschWorkflows/apiBoschWorkflowsInputs";
import { AuthedUser } from "../../context/authContextTypes";
import { GetQueryBoschWorkflowSnippet } from "../../Api/BoschWorkflows/apiBoschWorkflowsSnippets";
import { getQueryBoschProcess } from "../../Api/BoschWorkflows/apiBoschWorkflowsGetQueries";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";

const cssStyles = (theme: Theme) => ({
  flowWrapper: css({
    width: "100%",
    // minHeight: parent's minHeight - the padding
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING})`,
    [theme.breakpoints.down("sm")]: {
      // minHeight: parent's minHeight - the padding - extra_nav_menu
      minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING} - 75px)`,
    },
  }),
  flow: css({
    minHeight: "inherit",
    "& > div": {
      minHeight: "inherit",
    },
  }),
});

const modalTitleTranslations = {
  "Edit workflow": "Edit workflow",
  "Delete workflow": "Delete workflow",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

const ProcessPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssSpacingStyles(theme) };
  const [workflow, setWorkflow] = useState<ReactCustomFlowType | null>(null);
  const [initialWorkflow, setInitialWorkflow] = useState<ReactCustomFlowType | null>(
    null
  );
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [modalType, setModalType] = useState<ModalType | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [initialNodeUpdated, setInitialNodeUpdated] = useState<boolean>(false);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraTopNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const navigate = useNavigate();
  const initialNode = workflow?.nodes.find((node) => node?.type === "initial");
  const { setAuthedUser } = useAuthedContext();
  const { id } = useParams();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <ManageWorkflow
          setModalType={setModalType}
          workflow={workflow}
          setAuthedUser={setAuthedUser}
          setUnsavedChanges={setUnsavedChanges}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, workflow]);

  useEffect(() => {
    (async () => {
      try {
        const res: GetQueryBoschWorkflowSnippet = await callApi({
          query: getQueryBoschProcess(id!),
          auth: { setAuthedUser },
        });

        setWorkflow(res.data);
        setInitialWorkflow(res.data);
      } catch (err) {
        console.error("error - fetch workflow", err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workflow && initialWorkflow) {
      const areEqual = JSON.stringify(workflow) === JSON.stringify(initialWorkflow);
      setUnsavedChanges(!areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleDeleteWorkflow = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      if (workflow?.nodes.length) {
        await callApi({
          query: deleteQueryBoschProcess(workflow.id),
          auth: { setAuthedUser },
        });
      }

      setFormStatus("success");
      setAlertMessage(null);
      navigate({
        pathname: `${ROUTES_MAPPING["Processes"]}`,
      });
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = (updatedName: string) => {
    if (workflow?.nodes.length) {
      const updatedNodes = workflow.nodes.map((item) => {
        if (item.type === "initial") {
          return {
            ...item,
            data: {
              ...item.data,
              name: updatedName,
            },
          };
        }
        return item;
      });
      const updatedWorkflow: ReactCustomFlowType = {
        ...workflow,
        name: updatedName,
        nodes: updatedNodes,
        updatedOn: new Date().toISOString(),
      };

      callApi({
        query: putQueryBoschProcess({ data: updatedWorkflow }, workflow.id),
        auth: { setAuthedUser },
      });

      setWorkflow(updatedWorkflow);
      setInitialNodeUpdated((prev) => !prev);
      setModalType(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  return (
    <Box component="div" css={styles.flowWrapper}>
      {smMediaQuery ? (
        <Box component="div" css={styles.labelBreak}>
          <ManageWorkflow
            setModalType={setModalType}
            workflow={workflow}
            setAuthedUser={setAuthedUser}
            setUnsavedChanges={setUnsavedChanges}
          />
        </Box>
      ) : null}

      {workflow?.id ? (
        <ReactFlowCustom
          css={styles.flow}
          workflow={workflow}
          setWorkflow={setWorkflow}
          initialNodeUpdated={initialNodeUpdated}
        />
      ) : null}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth="sm"
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
      >
        {modalType === "Edit workflow" ? (
          <EditWorkflowForm
            currentName={initialNode?.data.name || ""}
            handleEdit={handleEdit}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedEditChanges}
          />
        ) : null}

        {modalType === "Delete workflow" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete your workflow")}
            </Typography>
            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Button onClick={handleDeleteWorkflow}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default ProcessPage;

interface ManageWorkflowProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  workflow: ReactCustomFlowType | null;
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManageWorkflow: React.FC<ManageWorkflowProps> = ({
  setModalType,
  workflow,
  setAuthedUser,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const handleSaveWorkflow = async () => {
    try {
      if (workflow?.nodes.length) {
        const input: PutQueryBoschWorkflowInput = {
          data: { ...workflow, updatedOn: new Date().toISOString() },
        };

        await callApi({
          query: putQueryBoschProcess(input, workflow.id),
          auth: { setAuthedUser },
        });
        setUnsavedChanges(false);
      }
    } catch (err) {
      console.error("error - handleSaveWorkflow()", err);
    }
  };

  return (
    <Stack spacing={1} justifyContent="flex-end" direction="row">
      <Stack alignItems="center">
        <IconButton
          aria-label="edit workflow"
          onClick={() => setModalType("Edit workflow")}
        >
          <EditOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Edit")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton aria-label="export" disabled>
          <FileDownloadOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textSecondary">
          {t("Export")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton aria-label="save changes" onClick={() => handleSaveWorkflow()}>
          <SaveOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Save")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton
          aria-label="delete workflow"
          onClick={() => setModalType("Delete workflow")}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Delete")}
        </Typography>
      </Stack>
    </Stack>
  );
};
