import { Box, Card, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { HelpSelection } from "../../../Api/Help/apiHelpSnippets";
import { useState } from "react";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import Modal from "../../MaterialUI/Modal";
import Button from "../../MaterialUI/Button";
import callApi from "../../../Api/callApi";
import { deleteQueryHelpSelection } from "../../../Api/Help/apiHelpPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import AddHelpContentForm from "./AddHelpContentForm";
import { ModalHelpTitle, PageKey } from "./helpUtils";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import Alert from "../../MaterialUI/Alert";
import UploadHelpFile from "./UploadHelpFile";
import { useLanguageContext } from "../../../context/LanguageContext";

interface HelpContentSelectionCardProps {
  helpSelection: HelpSelection;
  canUserEditAddDelete: boolean | undefined;
  fetchHelpContent: () => void;
  pageKeyId: string | undefined;
  order: number;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
  editorContent: string;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  uploadType: "video" | "image" | null;
  setUploadType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;
  currentPage: PageKey | undefined;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpContentSelectionCard: React.FC<HelpContentSelectionCardProps> = ({
  helpSelection,
  canUserEditAddDelete,
  fetchHelpContent,
  pageKeyId,
  order,
  setModalTitle,
  editorContent,
  setEditorContent,
  uploadType,
  setUploadType,
  currentPage,
  setUnsavedEditChanges,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const handleDeleteSelection = async (selectionId: string) => {
    try {
      setAlertStatus("loading");
      setAlertMessage(t("Loading..."));
      await callApi({
        query: deleteQueryHelpSelection(selectionId),
        auth: { setAuthedUser },
      });

      fetchHelpContent();
      setOpenInfo(false);
      setAlertStatus("success");
      setAlertMessage(t("Successfully deleted"));
    } catch (err) {
      console.log("HelpSelectionDelete err: ", err.message);
      setAlertStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Card css={[styles.width100, styles.fullPadding2, styles.textBreak]}>
        {canUserEditAddDelete ? (
          <Box component="div" style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="widget setting menu"
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              size="small"
            >
              <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
            </IconButton>
          </Box>
        ) : null}

        <Box component="div">
          {helpSelection.type === "text" ? (
            <div dangerouslySetInnerHTML={{ __html: helpSelection.content }} />
          ) : null}

          {helpSelection.type === "image" ? (
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                backgroundColor: "black",
              }}
            >
              <img
                src={`https://${helpSelection.content}`}
                alt="Help Image"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ) : null}

          {helpSelection.type === "video" ? (
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
                maxWidth: "100%",
                backgroundColor: "black",
              }}
            >
              <video
                controls
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <source src={`https://${helpSelection.content}`} />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : null}

          {alertMessage && (
            <Box
              component="div"
              mt={2}
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <Alert
                message={alertMessage}
                showAlert={!!alertMessage}
                severity={alertStatus}
              />
            </Box>
          )}
        </Box>

        <Menu
          anchorEl={menuAnchor}
          open={openMenu}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
            <Stack alignItems="center">
              <IconButton
                aria-label="edit selection"
                onClick={() => {
                  setMenuAnchor(null);
                  setOpenInfo(true);
                  setModalData({
                    type: "editSelection",
                    data: { data: helpSelection, id: helpSelection.id },
                  });
                  setEditorContent(helpSelection.content);
                  if (helpSelection.type === "video") {
                    setUploadType("video");
                  } else if (helpSelection.type === "image") {
                    setUploadType("image");
                  }
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                {t("Edit")}
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <IconButton
                aria-label="delete selection"
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "deleteSelection", data: helpSelection.id });
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                {t("Delete")}
              </Typography>
            </Stack>
          </Stack>
        </Menu>
      </Card>

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          maxWidth={
            modalData.type === "deleteSelection" ||
            modalData.data.data.type === "image" ||
            modalData.data.data.type === "video"
              ? "sm"
              : "md"
          }
          label={getModalLabel(modalData)}
          onClose={() => {
            setOpenInfo(false);
            setModalTitle(null);
          }}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "editSelection" ? (
            modalData.data.data.type === "text" ? (
              <AddHelpContentForm
                fetchHelpContent={fetchHelpContent}
                handleSetUnsavedChanges={handleSetUnsavedChanges}
                pageKeyId={pageKeyId}
                order={order}
                setModalTitle={setModalTitle}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                helpSelection={helpSelection}
                setUnsavedEditChanges={setUnsavedEditChanges}
                setOpenInfo={setOpenInfo}
              />
            ) : modalData.data.data.type === "image" ||
              modalData.data.data.type === "video" ? (
              <UploadHelpFile
                currentPage={currentPage}
                order={order}
                fetchHelpContent={fetchHelpContent}
                uploadType={uploadType}
                setUploadType={setUploadType}
                helpSelectionId={helpSelection.id}
                setAlertMessage={setAlertMessage}
                setAlertStatus={setAlertStatus}
                setOpenInfo={setOpenInfo}
                setModalTitle={setModalTitle}
              />
            ) : null
          ) : null}

          {modalData.type === "deleteSelection" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this help selection?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleDeleteSelection(modalData.data)}
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default HelpContentSelectionCard;

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  const { t } = useLanguageContext();

  if (!modalData) return "";

  switch (modalData.type) {
    case "deleteSelection":
      return t("Delete Selection");
    case "editSelection":
      return t("Edit Selection");
    default:
      return "";
  }
};
