import { useEffect, useState } from "react";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { MaterialRow, MaterialSupplyChain } from "./materialsUtils";
import { Stack, IconButton, Box, useTheme, Card, Typography } from "@mui/material";
import { GetQueryMaterialsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../Components/MaterialUI/Modal";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import cssSpacingStyles from "../../Global/Styles/spacing";
import DownloadIcon from "@mui/icons-material/Download";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Alert from "../../Components/MaterialUI/Alert";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";

type ColActionType = {
  type: "View Material" | "Material Files";
  material: MaterialSupplyChain;
} | null;

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "pdm_card", label: "PDM Card", type: "string" },
  { id: "classification_id", label: "Classification ID", type: "string" },
  { id: "material_id", label: "ID", type: "string" },
  { id: "actions", label: "Actions", type: "button" },
];

interface MaterialsTableProps {
  tableData: GetQueryMaterialsSnippet;
  loading: boolean;
}

const MaterialsTable: React.FC<MaterialsTableProps> = ({ tableData, loading }) => {
  const { t } = useLanguageContext();
  const [materialsRows, setMaterialsRows] = useState<MaterialRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);

  useEffect(() => {
    (async () => {
      try {
        setMaterialsRows(
          tableData.map((item) => {
            return {
              material_id: item.material_id,
              classification_id: item.classification_id || "",
              name: item.name,
              pdm_card: item.pdm_card,
              type: item.type,
              actions: (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    onClick={() =>
                      onActionClick({
                        material: item,
                        type: "View Material",
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      onActionClick({
                        material: item,
                        type: "Material Files",
                      })
                    }
                  >
                    <AttachFileIcon />
                  </IconButton>
                </Stack>
              ),
            };
          })
        );
      } catch (error) {
        console.log("There was an error: ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
  };

  return (
    <>
      <ResponsiveTableGrid
        rows={materialsRows}
        colSchema={useTranslateFields(colSchema, ["label"])}
        loading={loading}
        responsive="responsive"
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
          columnVisibility: { material_id: false },
        }}
      />

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        label={openModal?.type ? t(openModal.type) : ""}
      >
        {openModal?.type === "View Material" ? (
          <ViewMaterial material={openModal.material} />
        ) : null}

        {openModal?.type === "Material Files" ? (
          <MaterialFiles material={openModal.material} />
        ) : null}
      </Modal>
    </>
  );
};

export default MaterialsTable;

interface ViewMaterialProps {
  material: MaterialSupplyChain;
}

const ViewMaterial: React.FC<ViewMaterialProps> = ({ material }) => {
  const { t } = useLanguageContext();
  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text={t("ID")} bolded={material.material_id} />
        <LabelWithBoldedPart text={t("Name")} bolded={material.name} />
        <LabelWithBoldedPart text={t("PDM Card")} bolded={material.pdm_card} />
        <LabelWithBoldedPart text={t("Type")} bolded={material.type} />
        <LabelWithBoldedPart
          text={t("Classification ID")}
          bolded={material.classification_id || ""}
        />
      </Stack>
    </Box>
  );
};

interface MaterialFilesProps {
  material: MaterialSupplyChain;
}

const MaterialFiles: React.FC<MaterialFilesProps> = ({ material }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);

  const handleDownloadFile = async (link: string) => {
    try {
      setAlertStatus(null);
      setAlertMessage(null);
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = link;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage(t("Something went wrong"));
      setAlertStatus("error");
    }
  };

  const handleOpenFile = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Box component="div">
      {material.files.length ? (
        <Stack css={[styles.sectionBreak, styles.leftRightPadding1]} spacing={3}>
          {material.files.map((item) => (
            <Card key={item.id}>
              <Box component="div" css={styles.fullPadding1}>
                <Stack direction={"row"} spacing={3} alignItems="center">
                  <Typography style={{ flex: 1 }} variant="body1">
                    {item.file_name}
                  </Typography>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <IconButton
                      aria-label="open file"
                      onClick={() => handleOpenFile(item.url)}
                      color="primary"
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      aria-label="download file"
                      onClick={() => handleDownloadFile(item.url)}
                    >
                      <DownloadIcon color="secondary" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Box>
            </Card>
          ))}
        </Stack>
      ) : null}

      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Alert
          css={styles.reverseContentBreak}
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={alertStatus}
        />
      </Stack>
    </Box>
  );
};
