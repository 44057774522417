import { createContext, useContext, useEffect, useState } from "react";
import { getCookie, getCurrentEnv } from "../Global/Utils/commonFunctions";
import { COOKIE_REFRESH_TOKEN } from "../Global/Constants/commonConstants";
import { AuthedUser, ProfilePicture } from "./authContextTypes";
import {
  fetchAlerts,
  fetchProfilePic,
  handleFetchUserAccessToken,
} from "./authContextUtils";
import callApi from "../Api/callApi";
import { GetQueryUsersGetCurrentUserSnippet } from "../Api/Auth/apiAuthSnippets";
import { getQueryUsersGetCurrentUser } from "../Api/Auth/apiAuthGetQueries";
import { Alert } from "../Api/Rules/apiRulesSnippets";

export type AlertData = {
  read: Alert[];
  unread: Alert[];
};

const IS_ELRPOM_ENV = getCurrentEnv() === "elprom";

interface UserContextType {
  authedUser: AuthedUser | null;
  setAuthedUser: (value: React.SetStateAction<AuthedUser | null>) => void;
  authedUserLoading: boolean;
  setUserSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
  profilePicture: ProfilePicture;
  setProfilePicture: (value: React.SetStateAction<ProfilePicture>) => void;
  alertsData: AlertData;
  setAlertsData: (value: React.SetStateAction<AlertData>) => void;
  reFetchAlerts: boolean;
  setReFetchAlerts: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

interface AuthContextProps {
  children: JSX.Element | JSX.Element[];
}

const AuthContext = ({ children }: AuthContextProps): React.ReactElement => {
  const [authedUser, setAuthedUser] = useState<AuthedUser | null>(null);
  const [userSignedIn, setUserSignedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [profilePicture, setProfilePicture] = useState<ProfilePicture>({ url: "" });
  const [alertsData, setAlertsData] = useState<AlertData>({ read: [], unread: [] });
  const [reFetchAlerts, setReFetchAlerts] = useState<boolean>(false);

  useEffect(() => {
    if (authedUser === null && userSignedIn) {
      setUserSignedIn(false);
    } else if (authedUser !== null && !userSignedIn) {
      setUserSignedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedUser?.id]);

  useEffect(() => {
    (async () => {
      try {
        if (!authedUser?.id) {
          setLoading(true);
          await checkIfUserIsSignedIn();
        }
      } catch (err) {
        console.log("Authed user error");
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSignedIn]);

  const checkIfUserIsSignedIn = async () => {
    const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
    if (refreshToken) {
      // 1. fetch the accessToken and save it as a cookie
      await handleFetchUserAccessToken(refreshToken);

      // 2. fetch and save userData
      const signedInUser = await callApi<GetQueryUsersGetCurrentUserSnippet>({
        query: getQueryUsersGetCurrentUser(),
        auth: { setAuthedUser },
      });

      await fetchProfilePic(setAuthedUser, setProfilePicture);

      if (!IS_ELRPOM_ENV) {
        await fetchAlerts(setAlertsData, setAuthedUser);
      }

      setAuthedUser({
        ...signedInUser.user,
      });

      return signedInUser.user;
    }
  };

  return (
    <UserContext.Provider
      value={{
        authedUser,
        setAuthedUser,
        authedUserLoading: loading,
        setUserSignedIn,
        profilePicture,
        setProfilePicture,
        alertsData,
        setAlertsData,
        reFetchAlerts,
        setReFetchAlerts,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default AuthContext;

export const useAuthedContext = (): UserContextType => useContext(UserContext);
