import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  GRAPHQL_FILTER_OPERATIONS_STRING,
  GRAPHQL_FILTER_OPERATIONS_NUMBER,
  GraphqlFilter,
  GraphqlFiltersData,
} from "./graphqlFiltersUtils";
import { useEffect, useMemo, useState } from "react";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Select from "../../MaterialUI/FormFields/Select";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import { AutocompleteOption } from "../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Button from "../../MaterialUI/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "../../MaterialUI/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "../../MaterialUI/Alert";

const EMPTY_FILTER: GraphqlFilter = {
  paramID: "",
  paramName: "",
  filterOperation: "",
  filterValue: "",
  type: "",
};

interface GraphqlFiltersProps {
  data: GraphqlFiltersData[];
  selectedParams: string[];
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
}

const GraphqlFilters: React.FC<GraphqlFiltersProps> = ({
  data,
  selectedParams,
  graphqlFilters,
  setGraphqlFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [stateFilters, setStateFilters] = useState<GraphqlFilter[]>(graphqlFilters);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const paramOptions = useMemo(() => {
    const result: AutocompleteOption[] = [];
    data.forEach((item) => {
      const isSelected = selectedParams.includes(item.paramID!);
      if (isSelected) {
        result.push({ description: item.paramName, value: item.paramID });
      }
    });

    return result;
  }, [data, selectedParams]);

  useEffect(() => {
    setStateFilters(graphqlFilters);
  }, [graphqlFilters]);

  const handleAddNewFilter = () => {
    setStateFilters((prev) => [...prev, EMPTY_FILTER]);
  };

  const handleOnChange = (
    fieldIndex: number,
    field: "param" | "operation" | "value",
    value: any
  ) => {
    setStateFilters((prev) =>
      prev.map((item, index) => {
        let updatedItem = { ...item };
        switch (field) {
          case "param": {
            updatedItem = {
              ...EMPTY_FILTER,
              paramID: value.value,
              paramName: value.description,
            };
            break;
          }
          case "operation": {
            updatedItem = {
              ...updatedItem,
              filterOperation: value,
            };
            break;
          }
          case "value": {
            updatedItem = {
              ...updatedItem,
              filterValue: value,
            };
            break;
          }
        }

        if (index === fieldIndex) {
          return { ...updatedItem };
        }
        return item;
      })
    );
  };

  const handleRemoveFilter = (fieldIndex: number) => {
    setStateFilters((prev) => prev.filter((_, index) => index !== fieldIndex));
  };

  const handleSaveChanges = () => {
    const checkFilters = stateFilters.every(
      (item) =>
        !!item.filterOperation && !!item.filterValue && !!item.paramID && !!item.paramName
    );
    if (!checkFilters) {
      setAlertMessage("All filter fields are mandatory");
      return;
    } else {
      setAlertMessage(null);
    }

    setGraphqlFilters(stateFilters);
    setOpenFilters(false);
  };

  const handleCloseModal = () => {
    setOpenFilters(false);
    setStateFilters(graphqlFilters);
  };

  return (
    <Box component="div">
      <Stack direction="row" alignItems="center" spacing={3}>
        <Button onClick={() => setOpenFilters(true)} color="secondary" size="small">
          Apply Filters
        </Button>
        <Typography variant="caption">{graphqlFilters.length} Filters Applied</Typography>
      </Stack>

      <Modal
        open={openFilters}
        onClose={handleCloseModal}
        fullWidth
        label={"Apply Filters"}
        maxWidth="md"
      >
        {stateFilters.map((item, index) => (
          <Stack
            css={styles.width100}
            spacing={2}
            direction="row"
            key={`${item.paramID}-${index}`}
            alignItems="center"
          >
            <Autocomplete
              css={styles.width100}
              value={{ value: item.paramID, description: item.paramName }}
              label="Parameter"
              options={paramOptions}
              handleOnChange={(val: AutocompleteOption) =>
                handleOnChange(index, "param", val)
              }
            />
            <Box sx={{ maxWidth: "200px", width: "100%" }} component="div">
              <Select
                fullWidth
                label="Operation"
                selectOptions={
                  item.type === "number"
                    ? GRAPHQL_FILTER_OPERATIONS_NUMBER
                    : GRAPHQL_FILTER_OPERATIONS_STRING
                }
                value={item.filterOperation}
                onChange={(e) => handleOnChange(index, "operation", e.target.value)}
              />
            </Box>
            <Box sx={{ maxWidth: "200px", width: "100%" }} component="div">
              <TextField
                fullWidth
                label="Value"
                value={item.filterValue}
                onChange={(e) => handleOnChange(index, "value", e.target.value)}
              />
            </Box>
            <Box component="div">
              <IconButton onClick={() => handleRemoveFilter(index)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        ))}
        <Button
          css={styles.reverseTextBreak}
          variant="text"
          onClick={handleAddNewFilter}
          color="secondary"
          endIcon={<AddOutlinedIcon color="secondary" />}
        >
          Add New Filter
        </Button>

        <Box
          css={[
            styles.reverseContentBreak,
            styles.flexCenter,
            styles.flexColumn,
            styles.textBreak,
          ]}
          component="div"
        >
          <Button
            css={[styles.width100, styles.widthLimit15]}
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>

          <Alert
            css={styles.reverseTextBreak}
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={"warning"}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default GraphqlFilters;
