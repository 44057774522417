import { useEffect, useState } from "react";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import Modal from "../../Components/MaterialUI/Modal";
import DocumentsModalLayout from "../../Components/PageComponents/Maintenance/DocumentsModalLayout";
import CellActionButton, {
  ModalLayoutData,
} from "../../Components/SmallComponents/TableGrid/CellActionButton";
import callApi from "../../Api/callApi";
import { getQueryTTObjects } from "../../Api/TrackAndTrace/apiTTGetQueries";
import { GetQueryTTObjectsSnippet } from "../../Api/TrackAndTrace/apiTTSnippets";
import { useAuthedContext } from "../../context/AuthContext";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarehousesObjectForm from "../../Components/PageComponents/Warehouses/WarehousesObjectForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { postQueryDeleteObject } from "../../Api/TrackAndTrace/apiTTPostQueries";
import Button from "../../Components/MaterialUI/Button";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";

export interface InventoryObject {
  id?: string;
  objectCode: string;
  objectType: string;
  vendor: string;
  quantityInStock: number;
  lastRestockDate: string;
  minQuantity: number;
  unitPrice: number;
  totalValue: number;
  purchaseDate: string;
  location: React.ReactNode;
  attachments: React.ReactNode;
}

const colSchema: TableGridColumnSchema[] = [
  { id: "objectCode", label: "Object Code", type: "string" },
  { id: "objectType", label: "Object Type", type: "string" },
  { id: "vendor", label: "Vendor", type: "string" },

  { id: "quantityInStock", label: "Quantity In Stock", type: "number", marked: true },
  { id: "lastRestockDate", label: "Last Restock Date", type: "dateTime" },
  {
    id: "minQuantity",
    label: "Min Quantity",
    type: "number",
  },
  {
    id: "unitPrice",
    label: "Unit Price",
    type: "number",
  },
  {
    id: "totalValue",
    label: "Total Value",
    type: "number",
  },

  {
    id: "purchaseDate",
    label: "Purchase Date",
    type: "dateTime",
  },
  {
    id: "location",
    label: "Location",
    type: "button",
  },
  { id: "attachments", label: "Attachments", type: "button" },
  { id: "actions", label: "Actions", type: "button" },
];

const InventoryPage = () => {
  const { t } = useLanguageContext();
  const [objectsData, setObjectsData] = useState<GetQueryTTObjectsSnippet>();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        const data = await callApi<GetQueryTTObjectsSnippet>({
          query: getQueryTTObjects,
          auth: { setAuthedUser },
        });

        setObjectsData(data);
      } catch (error) {
        console.log("There was an error fetcing machines ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteObject = async (objectId: string) => {
    try {
      const deletedObject = await callApi({
        query: postQueryDeleteObject(objectId),
        auth: { setAuthedUser },
      });

      if (deletedObject) {
        setObjectsData((prevData) => {
          if (prevData) {
            const updatedData = prevData.filter(
              (object) => object.id !== modalData?.data
            );
            return updatedData;
          }
          return prevData;
        });
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("MaintenanceMachineDelete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {objectsData ? (
        <ResponsiveTableGrid
          rows={handleFilterData(setOpenInfo, setModalData, objectsData)}
          colSchema={useTranslateFields(colSchema, ["label"]).filter(
            (col) =>
              ![
                "vendor",
                "lastRestockDate",
                "minQuantity",
                "purchaseDate",
                "unitPrice",
                "status",
              ].includes(col.id)
          )}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
        />
      ) : (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={
            modalData.type === "documents"
              ? t("Attachments")
              : modalData.type === "metaData"
              ? t("Information")
              : modalData.type === "deleteForm"
              ? t("Delete Object")
              : t("Update Object")
          }
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "documents" ? (
            <DocumentsModalLayout modalType="object" setupId={modalData.data} />
          ) : null}
          {modalData.type === "warehouseObjectForm" ? (
            <WarehousesObjectForm
              objectData={modalData.data}
              setObjectsData={setObjectsData}
              setOpenInfo={setOpenInfo}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData.type === "metaData" ? (
            <ResponsiveTableGrid
              rows={handleFilterData(setOpenInfo, setModalData, [modalData.data])}
              colSchema={useTranslateFields(colSchema, ["label"]).filter(
                (col) => col.id !== "actions"
              )}
              editMode={false}
              responsive="mobile"
              configuration={{
                density: "compact",
                hideMobilePagination: true,
                hideMobileSearch: true,
              }}
            />
          ) : null}
          {modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this object?")}
              </Typography>

              <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Button onClick={() => setOpenInfo(false)} fullWidth>
                  {t("Cancel")}
                </Button>
                <Button
                  onClick={() => handleDeleteObject(modalData.data)}
                  color="error"
                  fullWidth
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default InventoryPage;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  objectsData: GetQueryTTObjectsSnippet | undefined
): InventoryObject[] => {
  const { t } = useLanguageContext();
  if (!objectsData || !objectsData.length) {
    return [];
  }

  return objectsData.map((data) => ({
    objectCode: data.object_code ?? "",
    objectType: data.object_type,
    vendor: data.vendor,
    quantityInStock: data.quantity_in_stock,
    lastRestockDate: data.last_restock_date,
    minQuantity: data.min_quantity,
    unitPrice: data.unit_price,
    totalValue: data.total_value,
    purchaseDate: data.purchase_date,
    location: (
      <Tooltip title={data.location}>
        <Typography>
          {data.location.includes("/") && data.location.split("/").length > 2
            ? `.../${data.location.split("/").slice(2).join("/")}`
            : data.location}
        </Typography>
      </Tooltip>
    ),
    attachments: (
      <CellActionButton
        text={t("View All")}
        icon={<VisibilityIcon />}
        handleOnClick={() => {
          setOpenInfo(true);
          setModalData({ type: "documents", data: data.id });
        }}
      />
    ),
    actions: (
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <IconButton
          onClick={() => {
            setOpenInfo(true);
            setModalData({ type: "metaData", data: data });
          }}
        >
          <VisibilityIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            setOpenInfo(true);
            setModalData({ type: "warehouseObjectForm", data: data });
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            setOpenInfo(true);
            setModalData({ type: "deleteForm", data: data.id });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    ),
  }));
};
