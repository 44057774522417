import {
  ExcellenceChartProps,
  ExcellenceTableData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import {
  ExcellenceTableConfiguration,
  ExcellenceTableDataSchema,
} from "../../../../ExcellenceWidgets/ExcellenceTable/editExcellenceTabletypes";
import { Box, useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import {
  EXCELLENCE_TABLE_DEFAULT_COLUMNS,
  EXCELLENCE_TABLE_DEFAULT_ROWS,
} from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/excellenceTableDefaultData";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceTableData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import Alert from "../../../../MaterialUI/Alert";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { buildGraphqlFilters } from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellenceTableProps extends ExcellenceChartProps {
  data: ExcellenceTableData | null;
  configuration: ExcellenceTableConfiguration;
  schema: ExcellenceTableDataSchema | null;
}
const ExcellenceTable: React.FC<ExcellenceTableProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  dataFetchFromParent,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  const [updatedData, setUpdatedData] = useState<ExcellenceTableData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [getTableData, { loading: loadingTableData }] = useLazyQuery(
    graphQlQueryExcellenceTableData
  );

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        try {
          setFetchStatus("loading");
          const result = await getTableData({
            variables: {
              input: {
                ...schema,
                filters: buildGraphqlFilters(configuration?.graphqlFilters),
              },
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.tableData.chartData;

          setUpdatedData(resultData);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetChartFetch, dataFetchFromParent, schema]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  return (
    <Box
      component="div"
      css={[
        styles.height100,
        styles.overflowYAuto,
        styles.rightSpacer1,
        styles.leftSpacer1,
      ]}
    >
      <ResponsiveTableGrid
        className={className}
        responsive="desktop"
        rows={updatedData?.rows || EXCELLENCE_TABLE_DEFAULT_ROWS}
        colSchema={updatedData?.columns || EXCELLENCE_TABLE_DEFAULT_COLUMNS}
        configuration={configuration.tableGridConfig}
        isStatic={isStatic}
      />
      {isStatic ? null : <LoadingBackdrop loading={loadingTableData} />}
      <Box component="div" style={{ height: "5rem" }} />
      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </Box>
  );
};

export default ExcellenceTable;
