import { DaSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import { useEffect, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import callApi from "../../../../../Api/callApi";
import {
  GetQueryDACanonicalDataTypesSnippet,
  PostQueryDaNodesSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { getQueryDACanonicalDataTypes } from "../../../../../Api/DataSources/apiDSGetQueries";
import { postQueryDaNodes } from "../../../../../Api/DataSources/apiDSPostQueries";
import {
  compareArrayVersions,
  compareStringArrayVersions,
} from "../../../../../Global/Utils/commonFunctions";
import Alert from "../../../../MaterialUI/Alert";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { PostQueryDaNodesInput } from "../../../../../Api/DataSources/apiDSInputs";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";
import { handleConnectionOldNewString } from "../opcConnectionUtils";
import {
  formatDaCanonicalData,
  prepareDaSubColumns,
  prepareDaSubRows,
} from "../../CreateOrEditConnection/DaConnection/daUtils";
import { useLanguageContext } from "../../../../../context/LanguageContext";

type OldNew = {
  old: string;
  new: string;
};
type PreviousVersionDiff = {
  nodes?: {
    added: string[];
    removed: string[];
  };
  attributes?: {
    added: string[];
    removed: string[];
  };
  serverName?: OldNew;
  name?: OldNew;
  updateRate?: OldNew;
};

interface OpcDaHistoryModalContentProps {
  sub: DaSubscription;
  isShowDifference: boolean;
  previousSub: DaSubscription | null;
}

const OpcDaHistoryModalContent: React.FC<OpcDaHistoryModalContentProps> = ({
  sub,
  isShowDifference,
  previousSub,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [columns, setColumns] = useState<TableGridColumnSchema[]>([]);
  const [previousVersionDiff, setPreviousVersionDiff] =
    useState<PreviousVersionDiff | null>(null);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setFetchStatus("loading");
        setAlertMessage(t("Loading..."));
        if (isShowDifference) {
          handleDiffFromPreviousVersion();
        } else {
          await handleFetchItemAndProps();
        }
        setFetchStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("OpcDaHistoryModalContent, useEffect on mount err ", err);
        setFetchStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchItemAndProps = async () => {
    // 1. prepare columns
    const canonical = await callApi<GetQueryDACanonicalDataTypesSnippet>({
      query: getQueryDACanonicalDataTypes,
      auth: { setAuthedUser },
    });
    const formattedCanonical = formatDaCanonicalData(canonical);

    const columns: TableGridColumnSchema[] = prepareDaSubColumns(sub.attributes);
    setColumns(columns);

    // 2. prepare rows
    const input: PostQueryDaNodesInput = {
      server_name: sub.server_name,
      nodes: sub.nodes,
    };
    const itemDetails = await callApi<PostQueryDaNodesSnippet>({
      query: postQueryDaNodes(input),
      auth: { setAuthedUser },
    });
    const formattedItemDetails = prepareDaSubRows(itemDetails, formattedCanonical);
    setRows(formattedItemDetails);
  };

  const handleDiffFromPreviousVersion = () => {
    if (previousSub) {
      const nodesDiff = compareStringArrayVersions(sub.nodes, previousSub.nodes);
      const attributesDiff = compareArrayVersions(sub.attributes, previousSub.attributes);

      const addedNodes = nodesDiff.added.map((node) => node);
      const removedNodes = nodesDiff.removed.map((node) => node);

      const addedAttributes = attributesDiff.added.map((node) => node.name);
      const removedAttributes = attributesDiff.removed.map((node) => node.name);

      const serverNameDiff = handleConnectionOldNewString(
        sub.server_name,
        previousSub.server_name
      );
      const nameDiff = handleConnectionOldNewString(sub.name, previousSub.name);
      const updateRateDiff = handleConnectionOldNewString(
        sub.update_rate,
        previousSub.update_rate
      );

      setPreviousVersionDiff({
        nodes: {
          added: addedNodes,
          removed: removedNodes,
        },
        attributes: {
          added: addedAttributes,
          removed: removedAttributes,
        },
        serverName: serverNameDiff,
        name: nameDiff,
        updateRate: updateRateDiff,
      });
    }
  };

  if (fetchStatus !== "success") {
    return (
      <Alert
        css={styles.widthLimit25}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={fetchStatus}
      />
    );
  }

  if (!isShowDifference) {
    return (
      <ResponsiveTableGrid
        css={styles.sectionBreak}
        responsive="desktop"
        rows={rows}
        setRows={setRows}
        colSchema={columns}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack
          css={styles.height100}
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="new" />
          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="old" />
      </Grid>
    </Grid>
  );
};

export default OpcDaHistoryModalContent;

const DifferenceList: React.FC<{
  previousVersionDiff: PreviousVersionDiff | null;
  keyValue: keyof OldNew;
}> = ({ previousVersionDiff, keyValue }) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const itemsValue =
    keyValue === "new"
      ? previousVersionDiff?.nodes?.added.join(", ")
      : previousVersionDiff?.nodes?.removed.join(", ");
  const groupPropsValue =
    keyValue === "new"
      ? previousVersionDiff?.attributes?.added.join(", ")
      : previousVersionDiff?.attributes?.removed.join(", ");

  const itemsText = keyValue === "new" ? t("New Nodes Added") : t("Old Nodes Removed");
  const groupPropsText =
    keyValue === "new" ? t("New Attributes Added") : t("Old Attributes Removed");

  return (
    <Box component="div" css={styles.width100}>
      <Typography css={styles.contentBreak} variant="h3" color="primary">
        {keyValue === "new" ? "Current Version" : "Previous Version"}
      </Typography>
      <Stack spacing={2}>
        <LabelWithBoldedPart
          text={t("Server")}
          bolded={
            previousVersionDiff?.serverName
              ? previousVersionDiff.serverName[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart
          text={t("Name")}
          bolded={
            previousVersionDiff?.name
              ? previousVersionDiff.name[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart
          text={t("Update Rate")}
          bolded={
            previousVersionDiff?.updateRate
              ? previousVersionDiff.updateRate[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart text={itemsText} bolded={itemsValue || t("No Change")} />
        <LabelWithBoldedPart
          text={groupPropsText}
          bolded={groupPropsValue || t("No Change")}
        />
      </Stack>
    </Box>
  );
};
