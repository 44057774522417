import { Box, Typography, useTheme, Stack, IconButton } from "@mui/material";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import cssSpacingStyles from "../../Global/Styles/spacing";
import {
  createPredictiveMaintenanceConfiguration,
  predictiveMaintenanceChartMargin,
  PredictiveMeintenanceStatisticsType,
} from "../../Components/PageComponents/Maintenance/PredictiveMeintenance/predictiveMeintenanceUtils";
import { useEffect, useState } from "react";
import {
  predictiveMeintenanceChartData,
  predictiveMeintenanceStatistics,
} from "../../Components/PageComponents/Maintenance/PredictiveMeintenance/predictiveMeintananceData";

import cssLayoutStyles from "../../Global/Styles/layout";
import cssComponentsStyles from "../../Global/Styles/components";
import PredictiveMaintenanceCustomLegend from "../../Components/PageComponents/Maintenance/PredictiveMeintenance/PredictiveMaintenanceCustomLegend";
import PredictiveMeintenanceStatisticsCards from "../../Components/PageComponents/Maintenance/PredictiveMeintenance/PredictiveMeintenanceStatisticsCards";
import { LineAreaHeatMapScatterPlotData } from "../../Components/ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import TimeChart from "../../Components/ExcellenceWidgets/TimeChart/TimeChart";
import { TimeChartConfiguration } from "../../Components/ExcellenceWidgets/TimeChart/timeChartTypes";
import PredictiveMaintenanceSliceTooltip from "../../Components/PageComponents/Maintenance/PredictiveMeintenance/PredictiveMaintenanceSliceTooltip";
import { CustomLayerProps } from "@nivo/line";
import Button from "../../Components/MaterialUI/Button";
import ChartPeriodViewer from "../../Components/SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import ChartScopeMode from "../../Components/SmallComponents/DynamicGridLayout.tsx/ChartScopeMode";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { useLanguageContext } from "../../context/LanguageContext";

const PredictiveMaintenancePage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [chartData, setChartData] = useState<LineAreaHeatMapScatterPlotData>([]);
  const [statiscticsData, setStatiscticsData] =
    useState<PredictiveMeintenanceStatisticsType | null>(null);
  const [periodPreviewMode, setPeriodPreviewMode] = useState<boolean>(false);
  const [scopeMode, setScopeMode] = useState<boolean>(false);
  const [dates, setDates] = useState<{ startDate: Date; endDate: Date } | null>(null);

  const finalData = chartData.filter(
    (data) => data.id !== "upperBound" && data.id !== "lowerBound"
  );
  const predictionData = finalData?.find((d) => d.id === "prediction")?.data;
  const maxYValue = predictionData ? Math.max(...predictionData.map((d) => d.y)) : 300;

  const chartSettings = {
    chartMargin: predictiveMaintenanceChartMargin,
    axisBottomFormat: "%Y-%m-%d",
    axisBottomTickValues: "every 1 day",
    maxYValue,
  };

  useEffect(() => {
    setChartData(predictiveMeintenanceChartData);
    setStatiscticsData(predictiveMeintenanceStatistics);
  }, []);

  const predictiveMeintenanceConfiguration: TimeChartConfiguration = {
    ...createPredictiveMaintenanceConfiguration(theme),
    sliceTooltip: (props) => (
      <PredictiveMaintenanceSliceTooltip
        slice={props.slice}
        chartData={chartData}
        axis={props.axis}
      />
    ),
  };

  const handleExit = () => {
    setScopeMode(false);
    setPeriodPreviewMode(false);
    setDates(null);
  };

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        {t("Predictive Maintenance")}
      </Typography>
      <ContentBox>
        <PredictiveMeintenanceStatisticsCards statiscticsData={statiscticsData} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            mr: 2,
            mt: 1,
            flexDirection: "column",
          }}
          component="div"
        >
          {periodPreviewMode || scopeMode ? (
            <Button variant="text" onClick={handleExit} size="small">
              {t("Exit Mode")}
            </Button>
          ) : (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                aria-label="scope mode toggle"
                onClick={() => setScopeMode(true)}
              >
                <SearchIcon css={styles.greyIcon} />
              </IconButton>
              <IconButton
                aria-label="period mode toggle"
                onClick={() => {
                  const currentDate = new Date();
                  setPeriodPreviewMode(true);
                  setDates({
                    startDate: new Date(currentDate.setHours(currentDate.getHours() - 1)),
                    endDate: new Date(),
                  });
                }}
              >
                <SwapHorizIcon css={styles.greyIcon} />
              </IconButton>
            </Stack>
          )}
          {periodPreviewMode && dates ? (
            <ChartPeriodViewer dates={dates} setDates={setDates} />
          ) : null}

          {scopeMode ? <ChartScopeMode /> : null}
        </Box>

        <Box
          sx={{
            width: "100%",
            height: 500,
          }}
          component="div"
        >
          <TimeChart
            css={[styles.width100, styles.height100]}
            configuration={predictiveMeintenanceConfiguration}
            data={finalData}
            customLayers={[
              (props) => <ShadedAreaLayer {...props} data={chartData} />,
              CustomLinesLayer,
            ]}
            isStatic={false}
            hideLegend={true}
            chartSettings={chartSettings}
          />
        </Box>
        <PredictiveMaintenanceCustomLegend />
      </ContentBox>
    </>
  );
};

export default PredictiveMaintenancePage;

const ShadedAreaLayer = ({ xScale, yScale, data }: CustomLayerProps) => {
  const theme = useTheme();

  const xScaleTyped = xScale as (value: Date) => number;
  const yScaleTyped = yScale as (value: number) => number;

  const upperBoundData =
    data
      .find((d) => d.id === "upperBound")
      ?.data.filter((d) => d.y !== null)
      .map((d) => ({ ...d, x: new Date(d.x as string) })) || [];

  const lowerBoundData =
    data
      .find((d) => d.id === "lowerBound")
      ?.data.filter((d) => d.y !== null)
      .map((d) => ({ ...d, x: new Date(d.x as string) })) || [];

  const areaPath = `
    M${xScaleTyped(upperBoundData[0].x as Date)},${yScaleTyped(
    upperBoundData[0].y as number
  )}
    ${upperBoundData
      .map((d) => `L${xScaleTyped(d.x as Date)},${yScaleTyped(d.y as number)}`)
      .join(" ")}
    L${xScaleTyped(lowerBoundData[lowerBoundData.length - 1].x as Date)},${yScaleTyped(
    lowerBoundData[lowerBoundData.length - 1].y as number
  )}
    ${lowerBoundData
      .map((d) => `L${xScaleTyped(d.x as Date)},${yScaleTyped(d.y as number)}`)
      .reverse()
      .join(" ")}
    Z
  `;

  return <path d={areaPath} fill={theme.palette.error.main} opacity={0.3} />;
};

const CustomLinesLayer = ({
  xScale,
  yScale,
  innerWidth,
  innerHeight,
}: CustomLayerProps) => {
  const theme = useTheme();
  const yScaleTyped = yScale as (value: number) => number;

  const todayDate = new Date();

  const xScaleTyped = xScale as (value: Date) => number;

  const thresholdMin = 240;
  const thresholdMax = 300;

  return (
    <>
      <rect
        x={0}
        y={yScaleTyped(thresholdMax)}
        width={innerWidth}
        height={yScaleTyped(thresholdMin) - yScaleTyped(thresholdMax)}
        fill={theme.palette.error.main}
        opacity={0.3}
      />
      <text
        x={innerWidth - 10}
        y={yScaleTyped(thresholdMax) - 34}
        textAnchor="end"
        dominantBaseline="hanging"
        style={{
          fill: theme.palette.error.main,
          fontSize: "12px",
        }}
      >
        Threshold
      </text>

      <line
        x1={xScaleTyped(todayDate)}
        x2={xScaleTyped(todayDate)}
        y1={0}
        y2={innerHeight}
        stroke={theme.palette.common.black}
        strokeWidth={1}
        opacity={0.5}
      />
      <text
        x={xScaleTyped(todayDate) + 5}
        y={10}
        textAnchor="start"
        dominantBaseline="hanging"
        style={{
          fill: theme.palette.text.primary,
          fontSize: "12px",
        }}
      >
        Today
      </text>
    </>
  );
};
