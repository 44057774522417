import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { BasicTableRow } from "../../Components/MaterialUI/BasicTable/basicTableUtils";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { AppRouterProps } from "../../Layout/layoutVariables";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import Button from "../../Components/MaterialUI/Button";
import Alert from "../../Components/MaterialUI/Alert";
import Modal from "../../Components/MaterialUI/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useAuthedContext } from "../../context/AuthContext";
import { getQueryPidWorkflows } from "../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import callApi from "../../Api/callApi";
import { GetQueryPidWorkflowsSnippet } from "../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import CreatePidWorkflowForm from "./Components/PidWorkflow/Forms/CreatePidWorkflowForm";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import {
  deleteQueryPidWorkflow,
  putQueryPidWorkflow,
} from "../../Api/PidWorkflows/apiPidWorkflowsPostQueries";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PidWorkflowType } from "./Components/pidWorkflowUtils";
import EditPidWorkflowForm from "./Components/PidWorkflow/Forms/EditPidWorkflowForm";
import {
  PidWorkflowDataFieldInput,
  PutQueryPidWorkflowInput,
} from "../../Api/PidWorkflows/apiPidWorkflowsInputs";
import { PidWorkflowsInputNodeData } from "./Components/PidWorkflow/Nodes/Input/pidInputNodeUtils";
import { PID_FLOW_NODE_TYPE } from "./Components/PidWorkflow/Nodes/pidNodesUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";

type ModalType = {
  workflow: PidWorkflowType;
  label: string;
  isDelete: boolean;
  id: string;
  title: string;
};

const colSchema: TableGridColumnSchema[] = [
  { id: "title", label: "Title", type: "string" },
  { id: "plantArea", label: "Name", type: "string" },
  { id: "name", label: "Area", type: "string" },
  { id: "details", label: "Description", type: "string" },
  { id: "createdAt", label: "Created At", type: "date" },
  { id: "lastUpdated", label: "Updated At", type: "date" },
  { id: "id", label: "ID", type: "string" },
  { id: "actions", label: "Actions", type: "button" },
];
const PidCharts: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [workflowRows, setWorkflowRows] = useState<BasicTableRow[]>([]);
  const [open, setOpen] = useState(false);

  const [isDeleteModal, setIsDeleteModal] = useState<ModalType | null>(null);
  const [deleteMessage, setDeleteMessage] = useState<string | null>(null);
  const [deleteStatus, setDeleteStatus] = useState<FormStatuses>(null);

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await handleFetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<CreateNewWorkflow handleOpenModal={handleOpenModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setIsDeleteModal(null);
  };
  const handleOpenDeleteModal = (modal: ModalType) => {
    setIsDeleteModal(modal);
    setOpen(true);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  const handleFetch = async () => {
    try {
      setPageStatus("loading");
      setAlertMessage(t("Loading..."));

      const res = await callApi<GetQueryPidWorkflowsSnippet>({
        query: getQueryPidWorkflows(),
        auth: { setAuthedUser },
      });

      const rows: BasicTableRow[] = res.map((item) => ({
        id: item.id,
        title: `${item.workflow.name} ${item.workflow.plantArea}`,
        name: item.workflow.name,
        lastUpdated: item.workflow.updatedOn,
        createdAt: item.workflow.createdOn,
        details: item.workflow.details || "",
        plantArea: item.workflow.plantArea,
        actions: (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => {
                navigate({
                  pathname: `${ROUTES_MAPPING["P&ID Charts"]}/${item.id}`,
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                handleOpenDeleteModal({
                  workflow: item.workflow,
                  label: "Edit Diagram",
                  isDelete: false,
                  id: item.id,
                  title: `${item.workflow.name} ${item.workflow.plantArea}`,
                })
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                handleOpenDeleteModal({
                  workflow: item.workflow,
                  label: "Delete Diagram",
                  isDelete: true,
                  id: item.id,
                  title: `${item.workflow.name} ${item.workflow.plantArea}`,
                })
              }
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Stack>
        ),
      }));

      setWorkflowRows(rows);
      setPageStatus("success");
      setAlertMessage(null);
    } catch (err) {
      console.log("useEffect err ", err);
      setPageStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleDeleteWorkflow = async () => {
    try {
      if (!isDeleteModal) {
        return;
      }
      setDeleteStatus("loading");
      setDeleteMessage(t("Loading..."));

      await callApi({
        query: deleteQueryPidWorkflow(isDeleteModal.id),
        auth: { setAuthedUser },
      });

      await handleFetch();

      setDeleteStatus(null);
      setDeleteMessage(null);
      handleCloseModal();
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setDeleteStatus("error");
      setDeleteMessage(t("Something went wrong"));
    }
  };

  const handleEdit = async (
    updatedName: string,
    updatedPlant: string,
    updatedDetails: string
  ) => {
    try {
      const workflow = isDeleteModal?.workflow;
      if (workflow?.nodes.length) {
        const dataFields: PidWorkflowDataFieldInput[] = workflow.nodes
          .filter((node) => node.data.type === PID_FLOW_NODE_TYPE.Input)
          .map((item) => {
            const data = item.data as PidWorkflowsInputNodeData;
            return {
              container_id: data.dataField.container_id,
              node_id: data.dataField.node_id,
              parameter_id: data.dataField.parameter_id,
            };
          });

        const updatedNodes = workflow.nodes.map((item) => {
          if (item.type === "initial") {
            return {
              ...item,
              data: {
                ...item.data,
                name: updatedName,
              },
            };
          }
          return item;
        });
        const updatedWorkflow: PidWorkflowType = {
          ...workflow,
          name: updatedName,
          details: updatedDetails,
          plantArea: updatedPlant,
          nodes: updatedNodes,
          updatedOn: new Date().toISOString(),
        };

        const putInput: PutQueryPidWorkflowInput = {
          workflow: updatedWorkflow,
          data_fields: dataFields,
        };

        await callApi({
          query: putQueryPidWorkflow(putInput, workflow.id),
          auth: { setAuthedUser },
        });
        setUnsavedChanges(false);
        await handleFetch();
        handleCloseModal();
      }
    } catch (err) {
      console.error("error - handleEdit()", err);
    }
  };

  return (
    <Box component="div">
      <Typography
        css={styles.sectionBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        P&ID {t("Workflows")}
      </Typography>
      {smMediaQuery ? (
        <Button css={styles.sectionBreak} onClick={handleOpenModal}>
          {t("Create New Workflow")}
        </Button>
      ) : null}

      <Alert
        css={[styles.width100, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <ResponsiveTableGrid
        css={styles.sectionBreak}
        rows={workflowRows}
        colSchema={useTranslateFields(colSchema, ["label"])}
        responsive="responsive"
        configuration={{
          initialRowsPerPage: 15,
          columnVisibility: { id: false, title: false },
        }}
        loading={pageStatus === "loading"}
        // onRowClick={(row) => {
        //   navigate({
        //     pathname: `${ROUTES_MAPPING["P&ID Charts"]}/${row.id}`,
        //   });
        // }}
      />

      <Modal
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        label={isDeleteModal ? isDeleteModal.label : t("Create New Diagram")}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {isDeleteModal ? (
          <>
            {isDeleteModal.isDelete ? (
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Typography>
                  {t("Clicking the below button will delete your P&ID diagram")}
                </Typography>
                <Typography>{isDeleteModal.title}</Typography>
                <Alert
                  message={deleteMessage}
                  showAlert={!!deleteMessage}
                  severity={deleteStatus}
                />
                <Button
                  onClick={handleDeleteWorkflow}
                  disabled={deleteStatus === "loading"}
                >
                  {t("Confirm Delete")}
                </Button>
              </Stack>
            ) : (
              <EditPidWorkflowForm
                currentName={isDeleteModal?.workflow?.name || ""}
                currentPlantArea={isDeleteModal?.workflow?.plantArea || ""}
                currentDetails={isDeleteModal?.workflow?.details || ""}
                handleEdit={handleEdit}
                handleSetUnsavedChanges={handleSetUnsavedChanges}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
          </>
        ) : (
          <CreatePidWorkflowForm
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
      </Modal>
    </Box>
  );
};

export default PidCharts;

interface CreateNewWorkflowProps {
  handleOpenModal: () => void;
}

const CreateNewWorkflow: React.FC<CreateNewWorkflowProps> = ({ handleOpenModal }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <IconButton aria-label="create new workflow" onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        {t("Add")}
      </Typography>
    </Stack>
  );
};
