import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { useState } from "react";
import { Formik } from "formik";
import { bool, object } from "yup";
import {
  YUP_DATE_REQUIRED,
  YUP_REQUIRED_STRING,
} from "../../../../Global/Constants/yupConstants";
import { Grid, Stack, Typography } from "@mui/material";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import ContentBox from "../../../MaterialUI/ContentBox";
import DatePicker from "../../../MaterialUI/DateTimePickers/DatePicker";
import Select from "../../../MaterialUI/FormFields/Select";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import Button from "../../../MaterialUI/Button";
import OrderSummary from "./OrderSummary";
import Alert from "../../../MaterialUI/Alert";
import { Link, useParams } from "react-router-dom";
import {
  CheckedItem,
  ManufacturingOrderFormValues,
  ingredientsCheckboxes,
  operationsCheckboxes,
  productsSchemeOptions,
} from "./staticFormsUtils";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useTranslateFields, useTranslateSchemeArray } from "../../../../Global/Hooks/useTranslations";

const fieldValidation = object({
  companyName: YUP_REQUIRED_STRING,
  firstName: YUP_REQUIRED_STRING,
  lastName: YUP_REQUIRED_STRING,
  email: YUP_REQUIRED_STRING,
  creatingDate: YUP_DATE_REQUIRED,
  productionDeadline: YUP_DATE_REQUIRED,
  product: YUP_REQUIRED_STRING,
  termsAccepted: bool().oneOf([true], "You must accept the terms and conditions."),
});

const initialValues: ManufacturingOrderFormValues = {
  companyName: "",
  firstName: "",
  lastName: "",
  email: "",
  creatingDate: null,
  productionDeadline: null,
  product: "",
  isWoodChecked: false,
  isGlueChecked: false,
  isPaintChecked: false,
  isCuttingChecked: false,
  isGluingChecked: false,
  isAssemblyChecked: false,
  isPackagingChecked: false,
  termsAccepted: false,
};

const ManufacturingOrderStaticForm: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const params = useParams();

  const handleCheckboxChange = (label: string, price: number, isChecked: boolean) => {
    if (isChecked) {
      setCheckedItems((prevItems) => [...prevItems, { label, price, total: price }]);
      setTotalPrice((prevPrice) => prevPrice + price);
    } else {
      setCheckedItems((prevItems) => prevItems.filter((item) => item.label !== label));
      setTotalPrice((prevPrice) => prevPrice - price);
    }
  };

  const handleFormSubmit = (values: ManufacturingOrderFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (
        !values.companyName ||
        !values.firstName ||
        !values.lastName ||
        !values.email ||
        !values.creatingDate ||
        !values.productionDeadline ||
        !values.product ||
        values.termsAccepted === false
      ) {
        setFormStatus("warning");
        setAlertMessage(t("All required fields must be filled out"));
        return;
      }

      setFormStatus("success");
      setAlertMessage(
        t(
          "Thank you for your order! An email confirmation has been sent to your inbox with all the details."
        )
      );
    } catch (err) {
      console.error("ManufacturingOrderStaticForm handleFormSubmit error:", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Typography align="center" mb={4} mt={2} variant="h2">
            {t("Manufacturing Order Form")}
          </Typography>

          <ContentBox css={styles.fullPadding3}>
            <Grid container spacing={8} css={styles.contentBreak}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="companyName"
                      label={t("Company Name")}
                      error={touched["companyName"] && !!errors["companyName"]}
                      helperText={touched["companyName"] && errors["companyName"]}
                      onChange={handleChange}
                      value={values.companyName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="firstName"
                      label={t("First Name")}
                      error={touched["firstName"] && !!errors["firstName"]}
                      helperText={touched["firstName"] && errors["firstName"]}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="lastName"
                      label={t("Last Name")}
                      error={touched["lastName"] && !!errors["lastName"]}
                      helperText={touched["lastName"] && errors["lastName"]}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      label={t("Email")}
                      error={touched["email"] && !!errors["email"]}
                      helperText={touched["email"] && errors["email"]}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      name="creatingDate"
                      label={t("Creating Date")}
                      error={touched["creatingDate"] && !!errors["creatingDate"]}
                      helperText={touched["creatingDate"] && errors["creatingDate"]}
                      onChange={(value) => setFieldValue("creatingDate", value)}
                      value={values.creatingDate}
                      css={styles.width100}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      name="productionDeadline"
                      label={t("Production Deadline")}
                      error={
                        touched["productionDeadline"] && !!errors["productionDeadline"]
                      }
                      helperText={
                        touched["productionDeadline"] && errors["productionDeadline"]
                      }
                      onChange={(value) => setFieldValue("productionDeadline", value)}
                      value={values.productionDeadline}
                      css={styles.width100}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Select
                      selectOptions={useTranslateSchemeArray(productsSchemeOptions)}
                      name="product"
                      label={t("Product")}
                      error={touched["product"] && !!errors["product"]}
                      helperText={touched["product"] && errors["product"]}
                      onChange={handleChange}
                      value={values.product}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      mb={0.5}
                      variant="h4"
                      style={theme.customizedTextStyles.labelStyles}
                    >
                      {t("Ingredients")}
                    </Typography>

                    <Stack direction={"row"}>
                      {useTranslateFields(ingredientsCheckboxes, ["label"]).map((checkbox, index) => (
                        <Checkbox
                          key={`${checkbox}-${index}`}
                          label={`${checkbox.label} - ${checkbox.price}$`}
                          onChange={(e) => {
                            handleCheckboxChange(
                              checkbox.label,
                              checkbox.price,
                              e.target.checked
                            );
                            handleChange(e);
                          }}
                          value={checkbox.price}
                          name={checkbox.name}
                          size="small"
                        />
                      ))}
                    </Stack>
                  </Grid>

                  <Grid item xs={9}>
                    <Typography
                      mb={0.5}
                      variant="h4"
                      style={theme.customizedTextStyles.labelStyles}
                    >
                      {t("Operations")}
                    </Typography>
                    <Grid container spacing={1}>
                      {useTranslateFields(operationsCheckboxes, ["label"]).map((checkbox, index) => (
                        <Grid item xs={6} key={`${checkbox}-${index}`}>
                          <Checkbox
                            label={`${checkbox.label} - ${checkbox.price}$`}
                            onChange={(e) => {
                              handleCheckboxChange(
                                checkbox.label,
                                checkbox.price,
                                e.target.checked
                              );
                              handleChange(e);
                            }}
                            value={checkbox.price}
                            name={checkbox.name}
                            size="small"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <OrderSummary
                  checkedItems={checkedItems}
                  totalPrice={totalPrice}
                  isWithDiscount={true}
                />
              </Grid>
            </Grid>

            <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
              <Checkbox
                name="termsAccepted"
                onChange={handleChange}
                checked={values.termsAccepted}
                label={
                  <Typography variant="body1">
                    {t("I agree with the")}{" "}
                    <Typography
                      style={{ color: theme.palette.primary.main }}
                      variant="body1"
                      component={Link}
                      to={`/my-forms/${params.id}`}
                    >
                      {t("Terms & Conditions")}.
                    </Typography>
                  </Typography>
                }
              />

              {touched.termsAccepted && errors.termsAccepted && (
                <Typography color="error" style={{ marginBottom: 16 }}>
                  {errors.termsAccepted}
                </Typography>
              )}

              <Button
                type="submit"
                onClick={() => handleFormSubmit(values)}
                loading={formStatus === "loading"}
                css={[styles.width100, styles.widthLimit10]}
              >
                {t("Submit")}
              </Button>

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
                css={styles.reverseLabelBreak}
              />
            </Stack>
          </ContentBox>
        </form>
      )}
    </Formik>
  );
};

export default ManufacturingOrderStaticForm;
