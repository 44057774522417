import { SerializedStyles } from "@emotion/react";
import { Box, Dialog, DialogProps, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import Button from "./Button";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssFontStyles from "../../Global/Styles/font";

interface IModalProps extends DialogProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  onClose: () => void;
  label?: React.ReactNode | string;
  unsavedChanges?: boolean;
  setUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  doOpenUnsavedChangesModal?: boolean;
  setDoOpenUnsavedChangesModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<IModalProps> = ({
  className,
  onClose,
  label,
  children,
  unsavedChanges,
  setUnsavedChanges,
  doOpenUnsavedChangesModal,
  setDoOpenUnsavedChangesModal,
  ...props
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);

  useEffect(() => {
    if (doOpenUnsavedChangesModal) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doOpenUnsavedChangesModal]);

  const handleClose = () => {
    if (unsavedChanges) {
      setOpenUnsavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleConfirm = () => {
    if (setUnsavedChanges) {
      setUnsavedChanges(false);
    }
    if (setDoOpenUnsavedChangesModal) {
      setDoOpenUnsavedChangesModal(false);
    }
    setOpenUnsavedDialog(false);
    onClose();
  };

  const handleCancel = () => {
    setOpenUnsavedDialog(false);
    if (setDoOpenUnsavedChangesModal) {
      setDoOpenUnsavedChangesModal(false);
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} {...props}>
        <Box component="div" css={styles.fullPadding2}>
          <Stack
            css={styles.labelBreak}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            {typeof label === "string" ? (
              <Typography variant="h3">{label}</Typography>
            ) : (
              label
            )}

            <IconButton css={styles.marginLeftAuto} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Box component="div" style={{ overflowX: "hidden" }} className={className}>
            {children}
          </Box>
        </Box>
      </Dialog>

      {unsavedChanges && (
        <Dialog open={openUnsavedDialog} onClose={handleCancel}>
          <Box component="div" css={styles.fullPadding2}>
            <Stack
              css={styles.labelBreak}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography variant="h3">Are you sure?</Typography>

              <IconButton css={styles.marginLeftAuto} onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Box component="div" style={{ overflowX: "hidden" }} className={className}>
              <Typography variant="body1" mb={3}>
                {
                  "You have unsaved changes. Are you sure you want to close without saving?"
                }
              </Typography>
              <Stack
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Button onClick={handleConfirm} color="error">
                  {"Proceed and Lose Changes"}
                </Button>
                <Button onClick={handleCancel}>{"Cancel"}</Button>
              </Stack>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default Modal;
