import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import {
  getQuerySupplyProducts,
  getQuerySupplySemiProducts,
} from "../../Api/SupplyChain/apiSupplyChainGetQueries";
import {
  GetQuerySupplyProductsSnippet,
  GetQuerySupplySemiProductsSnippet,
} from "../../Api/SupplyChain/apiSupplyChainSnippets";
import { SupplyProduct } from "./productsUtils";
import ProductsTable from "./ProductsTable";
import SemiProductsTable from "./SemiProductsTable";
import { useLanguageContext } from "../../context/LanguageContext";

const ProductsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [loading, setLoading] = useState<boolean>(true);
  const [productsData, setProductsData] = useState<SupplyProduct[]>([]);
  const [semiProductsData, setSemiProductsData] = useState<any[]>([]);
  const [tabView, setTabView] = useState<number>(0);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        const products = await callApi<GetQuerySupplyProductsSnippet>({
          query: getQuerySupplyProducts(),
          auth: { setAuthedUser },
        });
        const semiProducts = await callApi<GetQuerySupplySemiProductsSnippet>({
          query: getQuerySupplySemiProducts(),
          auth: { setAuthedUser },
        });

        setProductsData(products);
        setSemiProductsData(semiProducts);
      } catch (error) {
        console.log("There was an error: ", error);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">{t("Products")}</Typography>
        <Tabs
          css={styles.sectionBreak}
          value={tabView}
          onChange={(_: any, val: SetStateAction<number>) => setTabView(val)}
          aria-label="configure chart tabs"
        >
          <Tab label={t("Products")} disabled={loading} />
          <Tab label={t("Semi-Products")} disabled={loading} />
        </Tabs>
      </Stack>

      {tabView === 0 ? (
        <ProductsTable tableData={productsData} loading={loading} />
      ) : (
        <SemiProductsTable tableData={semiProductsData} loading={loading} />
      )}
    </Box>
  );
};

export default ProductsPage;
