import { useEffect, useState } from "react";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import {
  ExcellenceChartProps,
  IndicatorWidgetData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import IndicatorWidget from "../../../../ExcellenceWidgets/IndicatorWidget/IndicatorWidget";
import {
  IndicatorDataSchema,
  IndicatorWidgetButtonTypes,
  IndicatorWidgetConfiguration,
} from "../../../../ExcellenceWidgets/IndicatorWidget/indicatorWidgetTypes";
import { Box, useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceIndicatorData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import Alert from "../../../../MaterialUI/Alert";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import indicatorWidgetDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/indicatorWidgetDefaultData";
import { endOfMonth, startOfMonth, startOfYear, subMonths, subYears } from "date-fns";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { buildGraphqlFilters } from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellenceIndicatorProps extends ExcellenceChartProps {
  data: IndicatorWidgetData | null;
  configuration: IndicatorWidgetConfiguration;
  schema: IndicatorDataSchema | null;
}

const ExcellenceIndicator: React.FC<ExcellenceIndicatorProps> = ({
  className,
  data,
  configuration,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  isStatic,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [updatedData, setUpdatedData] = useState<IndicatorWidgetData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);

  const [getIndicatorData, { loading: loadingPieData }] = useLazyQuery(
    graphQlQueryExcellenceIndicatorData
  );

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        await handleFetch(schema);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  const handleFetch = async (dataSchema: IndicatorDataSchema) => {
    try {
      setFetchStatus("loading");
      const result = await getIndicatorData({
        variables: {
          input: {
            ...dataSchema,
            filters: buildGraphqlFilters(configuration.graphqlFilters),
          },
        },
      });
      if (!result.data || result.error) {
        throw new Error(JSON.stringify(result));
      }
      const resultData = result.data.indicatorData.data;

      setUpdatedData(resultData);
      setFetchStatus(null);
    } catch (err) {
      console.log("err ", err);
      setFetchStatus("error");
    }
  };

  const handleButtonClick = async (type: IndicatorWidgetButtonTypes) => {
    if (!schema) {
      return;
    }

    let startDate = new Date();
    let endDate = new Date();
    switch (type) {
      case "lastMonth": {
        startDate = startOfMonth(subMonths(new Date(), 1));
        endDate = endOfMonth(subMonths(new Date(), 1));
        break;
      }
      case "thisMonth": {
        startDate = startOfMonth(new Date());
        endDate = new Date();
        break;
      }
      case "lastYear": {
        startDate = startOfYear(subYears(new Date(), 1));
        endDate = subYears(new Date(), 1);
        break;
      }
      case "thisYear": {
        startDate = startOfYear(new Date());
        endDate = new Date();
        break;
      }
    }

    const dataSchema = {
      ...schema,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    };
    await handleFetch(dataSchema);
  };

  return (
    <Box component="div" css={[styles.width100, styles.height100, styles.relative]}>
      <IndicatorWidget
        className={className}
        configuration={configuration}
        data={updatedData || indicatorWidgetDefaultData}
        handleButtonClick={handleButtonClick}
        dataSchema={schema}
      />

      {isStatic ? null : <LoadingBackdrop loading={loadingPieData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={"Something went wrong"}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </Box>
  );
};

export default ExcellenceIndicator;
