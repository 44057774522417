import { useEffect, useState } from "react";
import Modal from "../../Components/MaterialUI/Modal";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsibleModalLayout from "../../Components/PageComponents/Maintenance/ResponsibleModalLayout";
import MaintenanceDocuments from "../../Components/PageComponents/Maintenance/DocumentsModalLayout";
import CellActionButton, {
  ModalLayoutData,
} from "../../Components/SmallComponents/TableGrid/CellActionButton";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { getQueryMaintenanceMachines } from "../../Api/Maintenance/apiMaintenanceGetQueries";
import { GetQueryMaintenanceMachinesSnippet } from "../../Api/Maintenance/apiMaintenanceSnippets";
import { IconButton, Stack, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MaintenanceParkForm from "../../Components/PageComponents/Maintenance/MaintenanceParkForm";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../Components/MaterialUI/Button";
import { postQueryDeleteMachine } from "../../Api/Maintenance/apiMaintenancePostQueries";
import { MachineStatus } from "../../Api/Maintenance/apiMaintenanceDataTypes";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";

interface Setup {
  setupType: string;
  setup: string;
  configuration: string;
  setupStatus: MachineStatus;
  lastMaintenance: string;
  nextMaintenance: string;
  measurementStatus: MachineStatus;
  costPerHour: number;
  responsible: React.ReactNode;
  documents: React.ReactNode;
}

const colSchema: TableGridColumnSchema[] = [
  { id: "setupType", label: "Machine Type", type: "string" },
  { id: "setup", label: "Machine", type: "string" },
  { id: "configuration", label: "Configuration", type: "string" },
  { id: "setupStatus", label: "Machine Status", type: "string", marked: true },
  { id: "lastMaintenance", label: "Last Maintenance", type: "dateTime", marked: true },
  { id: "nextMaintenance", label: "Next Maintenance", type: "dateTime", marked: true },
  { id: "measurementStatus", label: "Measurement Status", type: "string", marked: true },
  { id: "capacity", label: "Capacity", type: "number" },
  { id: "costPerHour", label: "Cost Per Hour", type: "number" },
  { id: "responsible", label: "Maintenance Responsible", type: "button" },
  { id: "documents", label: "Documents", type: "button" },
  { id: "actions", label: "Actions", type: "button" },
];

const MachinePark = () => {
  const { t } = useLanguageContext();
  const [machineData, setMachineData] = useState<GetQueryMaintenanceMachinesSnippet>();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const getModalLabel = (modalData: ModalLayoutData | null): string => {
    if (!modalData) return "";

    switch (modalData.type) {
      case "responsible":
        return t("Maintenance Responsible");
      case "machineParkForm":
        return modalData.data ? t("Update Machine") : t("Create Machine");
      case "metaData":
        return t("Information");
      case "deleteForm":
        return t("Delete Machine");
      case "documents":
        return t("Attached Documents");
      default:
        return "";
    }
  };

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <CreateNewMachineMenu
          handleOpenModal={() => {
            setOpenInfo(true);
            setModalData({ type: "machineParkForm", data: "" });
          }}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      try {
        const data = await callApi<GetQueryMaintenanceMachinesSnippet>({
          query: getQueryMaintenanceMachines,
          auth: { setAuthedUser },
        });

        setMachineData(data);
      } catch (error) {
        console.log("There was an error fetcing machines ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteTask = async (machineId: string) => {
    try {
      const deletedMachine = await callApi({
        query: postQueryDeleteMachine(machineId),
        auth: { setAuthedUser },
      });

      if (deletedMachine) {
        setMachineData((prevData) => {
          if (prevData) {
            const updatedData = prevData.filter((machine) => machine.id !== machineId);
            return updatedData;
          }
          return prevData;
        });
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("MaintenanceMachineDelete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {machineData ? (
        <ResponsiveTableGrid
          rows={handleFilterData(setOpenInfo, setModalData, machineData)}
          colSchema={useTranslateFields(colSchema, ["label"]).filter(
            (col) =>
              col.id !== "lastMaintenance" &&
              col.id !== "nextMaintenance" &&
              col.id !== "costPerHour" &&
              col.id !== "capacity"
          )}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
        />
      ) : (
        <Typography component="p" variant="body1">
          {t("Loading...")}
        </Typography>
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "responsible" ? (
            <ResponsibleModalLayout responsible={modalData.data} />
          ) : null}
          {modalData.type === "documents" ? (
            <MaintenanceDocuments modalType="setup" setupId={modalData.data} />
          ) : null}
          {modalData.type === "machineParkForm" ? (
            <MaintenanceParkForm
              maintenanceMachineData={modalData.data}
              setOpenInfo={setOpenInfo}
              setMachineData={setMachineData}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData.type === "metaData" ? (
            <ResponsiveTableGrid
              rows={handleFilterData(setOpenInfo, setModalData, [modalData.data])}
              colSchema={colSchema.filter((col) => col.id !== "actions")}
              editMode={false}
              responsive="mobile"
              configuration={{
                density: "compact",
                hideMobilePagination: true,
                hideMobileSearch: true,
              }}
            />
          ) : null}
          {modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this machine?")}
              </Typography>

              <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Button onClick={() => setOpenInfo(false)} fullWidth>
                  {t("Cancel")}
                </Button>
                <Button
                  onClick={() => handleDeleteTask(modalData.data)}
                  color="error"
                  fullWidth
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default MachinePark;

interface CreateNewMachineMenuProps {
  handleOpenModal: () => void;
}

const CreateNewMachineMenu: React.FC<CreateNewMachineMenuProps> = ({
  handleOpenModal,
}) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <IconButton aria-label="create new project" onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        {t("Add")}
      </Typography>
    </Stack>
  );
};

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  machineData: GetQueryMaintenanceMachinesSnippet | undefined
): Setup[] => {
  const { t } = useLanguageContext();
  if (!machineData) return [];

  return machineData.map((machine) => {
    const responsibleName =
      machine.responsible.length > 0 ? machine.responsible[0].persons[0].name : "";

    return {
      setupType: machine.machine_type,
      setup: machine.machine,
      configuration: machine.configuration,
      setupStatus: machine.machine_status,
      lastMaintenance: machine.last_maintenance,
      nextMaintenance: machine.next_maintenance,
      measurementStatus: machine.measurement_status,
      costPerHour: machine.cost_per_hour,
      capacity: machine.capacity,
      responsible: (
        <CellActionButton
          text={responsibleName}
          icon={<VisibilityIcon />}
          handleOnClick={() => {
            setOpenInfo(true);
            setModalData({ type: "responsible", data: machine.responsible });
          }}
        />
      ),
      documents: (
        <CellActionButton
          text={t("View All")}
          icon={<VisibilityIcon />}
          handleOnClick={() => {
            setOpenInfo(true);
            setModalData({ type: "documents", data: machine.id });
          }}
        />
      ),
      actions: (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "metaData", data: machine });
            }}
          >
            <VisibilityIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "machineParkForm", data: machine });
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "deleteForm", data: machine.id });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    };
  });
};
