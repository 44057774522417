import React from "react";
import { Grid, FormControlLabel } from "@mui/material";
import Switch from "../../MaterialUI/FormFields/Switch";
import { useLanguageContext } from "../../../context/LanguageContext";

interface ShareDashboardTabProps {
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShareDashboardPublic: React.FC<ShareDashboardTabProps> = ({
  isPublic,
  setIsPublic,
  setUnsavedEditChanges,
}) => {
  const { t } = useLanguageContext();
  return (
    <Grid container>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPublic}
                  onChange={() => {
                    setIsPublic(!isPublic);
                    setUnsavedEditChanges(true);
                  }}
                  color="primary"
                />
              }
              label={t("Share dashboard with all users (read-only)")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShareDashboardPublic;
