import { FileWithPath } from "react-dropzone";
import { LeftNavSingleItem } from "../../../Layout/layoutVariables";
import { allLeftNavRoutes } from "../../../Layout/Router/routesMapping";

export const findTextInNavItems = (path: string): string | null => {
  if (path === "/") {
    return "Home";
  }

  const searchItems = (navItems: LeftNavSingleItem[], path: string): string | null => {
    for (const item of navItems) {
      if (item.url === path) {
        return item.text;
      }

      if (item.nested) {
        const nestedResult = searchItems(item.nested, path);
        if (nestedResult) return nestedResult;
      }
    }
    return null;
  };

  return searchItems(allLeftNavRoutes, path);
};

export type PageKey = {
  page_key: string;
  id: string;
};

export const modalHelpTitleTranslations = {
  "Report Bug": "Report Bug",
  "Propose an Improvement": "Propose an Improvement",
  "Add Help Content": "Add Help Content",
} as const;

export type ModalHelpTitle = keyof typeof modalHelpTitleTranslations;

export const ReportBugProposeAnImprovementFormInitialValues = {
  title: "",
  editorContent: "",
  files: null as FileWithPath[] | null,
};
