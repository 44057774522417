import { COOKIE_REFRESH_TOKEN } from "../Global/Constants/commonConstants";
import { getCookie } from "../Global/Utils/commonFunctions";
import { AuthedUser } from "../context/authContextTypes";
import {
  handleFetchUserAccessToken,
  handleUserSignOut,
} from "../context/authContextUtils";

const API_ENDPOINT = "/api/v1/";

export type ResponseError = {
  detail: string;
};
export type Query = {
  endpoint: string;
  method: "GET" | "POST" | "DELETE" | "PUT";
  variables?: { [key: string]: any };
  endpointBase?: string;
  receiveErrorMessage?: boolean;
  multipartForm?: boolean;
  returnJson?: boolean;
  multipartCustomKey?: string;
};
export type CallApiParams = {
  query: Query;
  auth: {
    setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>;
  } | null;
};

/**
 * "query" contains the information needed to make the back-end request.
 * "auth" can be optionally provided. If it's provided, it means that the
 * back-end request is auth protected and requires valid user access token.
 * "requestIsReMade" flag passed as true means that the request has already
 * been made once and this is the second call to it.
 */
const callApi = async <T>(
  params: CallApiParams,
  requestIsReMade: boolean = false
): Promise<T> => {
  const { query, auth } = params;
  const {
    endpoint,
    method,
    variables,
    endpointBase,
    receiveErrorMessage,
    multipartForm,
    returnJson = true,
    // multipartCustomKey,
  } = query;
  const endpointToUse = endpointBase || API_ENDPOINT;
  let response: Response;

  // DELETE / GET logic
  if (method === "GET" || method === "DELETE") {
    let input: string = "";
    if (variables) {
      input =
        "?" +
        Object.keys(variables)
          .map((key) => key + "=" + variables[key])
          .join("&");
    }
    response = await fetch(`${endpointToUse}${endpoint}${input}`, {
      method: method,
    });
  } else {
    // POST and PUT logic
    if (multipartForm) {
      // accept multipart/form-data
      const formData = new FormData();

      if (variables) {
        Object.keys(variables).forEach((key) => {
          const value = variables[key];

          if (Array.isArray(value) && value[0] instanceof File) {
            value.forEach((file: File) => formData.append(key, file));
          } else if (value instanceof File) {
            formData.append(key, value);
          } else {
            formData.append(key, value); 
          }
        });
      }

      response = await fetch(`${endpointToUse}${endpoint}`, {
        method: method,
        body: formData,
      });
    } else {
      // accept JSON
      response = await fetch(`${endpointToUse}${endpoint}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        ...(variables && {
          body: JSON.stringify(variables),
        }),
      });
    }
  }

  // if expired accessToken and valid refresh token -> fetch new access token
  // this block of code only runs if the callApi function is not called for
  // a second time and the <auth> param is present.
  if (auth && !requestIsReMade && response.status === 401) {
    const jsonData: { detail: string } = await response.json();
    if (jsonData.detail === "Invalid access token") {
      const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
      const accessToken = await handleFetchUserAccessToken(refreshToken);
      if (accessToken) {
        // we have fetched and saved the accessToken
        return await callApi({ query, auth }, true);
      } else {
        // accessToken not fetched, log out the user due to invalid
        // or expired refresh token
        handleUserSignOut();
      }
    }
  }

  // if status code is not within 200, there is an error
  if (!response.status.toString().startsWith("2") && !receiveErrorMessage) {
    console.log("API err ", response);
    throw new Error(`API error - ${response.url}`);
  }

  if (returnJson) {
    const jsonData: T = await response.json();
    return jsonData;
  } else {
    return response as T;
  }
};

export default callApi;
