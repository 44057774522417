import React, { useEffect, useState } from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import BasicTable from "../../MaterialUI/BasicTable/BasicTable";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { RolePermissionType, UserPrivilege } from "../../../context/authContextUtils";
import {
  findExcellencePermissionChanges,
  getShareDashboardModalLabel,
  updateExcellencePermissions,
} from "./excellenceUtils";
import { PostQueryCreateDashboardPermission } from "../../../Api/Excellence/apiExcellenceInputs";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import callApi from "../../../Api/callApi";
import {
  DeleteExcellenceUserRolePermissions,
  GetExcellenceRolesPrivileges,
} from "../../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceRolesWithPermissions } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Button from "../../MaterialUI/Button";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import { deleteExcellenceDasboardPermission } from "../../../Api/Excellence/apiExcellencePostQueries";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Modal from "../../MaterialUI/Modal";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";

interface SharedRolesTableProps {
  addedRolesPermissions: RolePermissionType[];
  setAddedPermissions: React.Dispatch<React.SetStateAction<RolePermissionType[]>>;
  setChangedPermissions: React.Dispatch<
    React.SetStateAction<PostQueryCreateDashboardPermission[] | []>
  >;
  initialPermissions: RolePermissionType[];
  dashboardId: string;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  fetchDashboardPermissions: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  selectedRole: AutocompleteOption | null;
  setSelectedRole: React.Dispatch<React.SetStateAction<AutocompleteOption | null>>;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const SharedRolesTable: React.FC<SharedRolesTableProps> = ({
  addedRolesPermissions,
  setAddedPermissions,
  setChangedPermissions,
  initialPermissions,
  dashboardId,
  setFormStatus,
  setAlertMessage,
  fetchDashboardPermissions,
  searchTerm,
  setSearchTerm,
  selectedRole,
  setSelectedRole,
  setUnsavedEditChanges,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [rolesOptions, setRolesOptions] = useState<SelectOption[]>([]);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRoles = async () => {
    const rolesData = await callApi<GetExcellenceRolesPrivileges>({
      query: getQueryExcellenceRolesWithPermissions(),
      auth: { setAuthedUser },
    });

    const options: SelectOption[] = rolesData.map((role) => ({
      value: role.role_id,
      description: role.role_name,
    }));
    setRolesOptions(options);
  };

  const filteredData = formatRoleDataForTable(
    (addedRolesPermissions as RolePermissionType[]).filter((role) =>
      role.role_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearchChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const handlePrivilegeToggle = (identifier: string, privilege: UserPrivilege) => {
    setAddedPermissions((prevRoles: RolePermissionType[]) => {
      let updatedRoles = updateExcellencePermissions(
        prevRoles,
        identifier,
        privilege,
        "role_name"
      );

      const changedRolePermissions = findExcellencePermissionChanges(
        initialPermissions,
        updatedRoles,
        "role_id"
      );
      setUnsavedEditChanges(true);
      setChangedPermissions(changedRolePermissions);
      return updatedRoles;
    });
  };

  const hasPermissions = selectedRole
    ? addedRolesPermissions.some((perm) => perm.role_id === selectedRole.value)
    : false;

  const handleAddRole = () => {
    if (selectedRole && !hasPermissions) {
      const newRolePermissions: RolePermissionType = {
        id: "",
        role_id: selectedRole.value,
        role_name: selectedRole.description,
        dashboard_id: dashboardId,
        view: true,
        add: false,
        update: false,
        delete: false,
        share: false,
      };
      setUnsavedEditChanges(true);
      setAddedPermissions((prevUsers) => [newRolePermissions, ...prevUsers]);
      handlePrivilegeToggle(selectedRole.description, "view");
    }
  };

  const handleRemovePermissions = async (permissionId: string) => {
    setFormStatus("loading");
    setAlertMessage(t("Loading..."));

    try {
      await callApi<DeleteExcellenceUserRolePermissions>({
        query: deleteExcellenceDasboardPermission(dashboardId, permissionId),
        auth: { setAuthedUser },
      });

      setUnsavedEditChanges(false);
      setOpenInfo(false);
      fetchDashboardPermissions();
      setFormStatus("success");
      setAlertMessage(t("Seccessfilly deleted role permissions"));
    } catch (error) {
      console.log("There was an error deleting role permissions ", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={5}>
              <Autocomplete
                options={rolesOptions}
                label={t("Role")}
                value={selectedRole}
                handleOnChange={(val: AutocompleteOption) => {
                  setSelectedRole(val);
                }}
                onInputChange={(_, inputValue) => {
                  handleSearchChange(inputValue);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={0.5}>
              {selectedRole && !hasPermissions && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddRole}
                  fullWidth
                >
                  <AddOutlinedIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {hasPermissions ? (
            <Typography variant="body2">
              {t(
                "This role already has added permissions. You can edit them from the Shared Roles Table."
              )}
            </Typography>
          ) : selectedRole ? (
            <Typography variant="body2">
              {t(
                "This role has no permissions yet. Add them to manage their permissions."
              )}
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <BasicTable
        data={{
          rows: filteredData,
          columns: useTranslateFields(ROLE_COLUMNS, ["label"]),
        }}
        renderCell={(row, column) => {
          if (["view", "add", "update", "delete", "share"].includes(column.id)) {
            if (column.id === "view") {
              return <Checkbox checked={true} disabled={true} />;
            }
            return (
              <Checkbox
                checked={row[column.id as keyof typeof row] as boolean}
                onChange={() =>
                  handlePrivilegeToggle(row.role_name, column.id as UserPrivilege)
                }
              />
            );
          }
          if (column.id === "deleteAction") {
            return (
              <IconButton
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "deletePermissions", data: row.deleteAction });
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            );
          }
          return row[column.id as keyof typeof row];
        }}
        defaultOrderBy={"role_name"}
        emptyTableMessage={t("No roles shared yet.")}
        dense={true}
        pageRows={5}
      />

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getShareDashboardModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
        >
          {modalData.type === "deletePermissions" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete role permissions?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleRemovePermissions(modalData.data)}
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default SharedRolesTable;

const ROLE_COLUMNS = [
  { id: "role_name", label: "Role Name", width: "30%" },
  { id: "view", label: "View", width: "10%" },
  { id: "add", label: "Add", width: "10%" },
  { id: "update", label: "Edit", width: "10%" },
  { id: "delete", label: "Delete", width: "10%" },
  { id: "share", label: "Share", width: "10%" },
  { id: "deleteAction", label: "", width: "2%" },
];

const formatRoleDataForTable = (roles: RolePermissionType[]) => {
  return roles.map((role) => ({
    role_name: role.role_name,
    view: role.view,
    add: role.add,
    update: role.update,
    delete: role.delete,
    share: role.share,
    deleteAction: role.id,
  }));
};
