import { UaSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import { useEffect, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import callApi from "../../../../../Api/callApi";
import { PostQueryUANodeSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import { postQueryUANode } from "../../../../../Api/DataSources/apiDSPostQueries";
import { compareArrayVersions } from "../../../../../Global/Utils/commonFunctions";
import Alert from "../../../../MaterialUI/Alert";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import {
  prepareUaSubColumns,
  prepareUaSubRows,
} from "../../CreateOrEditConnection/UaConnection/uaUtils";
import { useAuthedContext } from "../../../../../context/AuthContext";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";
import { handleConnectionOldNewString } from "../opcConnectionUtils";
import { useLanguageContext } from "../../../../../context/LanguageContext";

type OldNew = {
  old: string;
  new: string;
};
type PreviousVersionDiff = {
  nodes?: {
    added: string[];
    removed: string[];
  };
  serverUrl?: OldNew;
  name?: OldNew;
  updateRate?: OldNew;
};

interface OpcUaHistoryModalContentProps {
  sub: UaSubscription;
  isShowDifference: boolean;
  previousSub: UaSubscription | null;
}

const OpcUaHistoryModalContent: React.FC<OpcUaHistoryModalContentProps> = ({
  sub,
  isShowDifference,
  previousSub,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [columns, setColumns] = useState<TableGridColumnSchema[]>([]);
  const [previousVersionDiff, setPreviousVersionDiff] =
    useState<PreviousVersionDiff | null>(null);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setFetchStatus("loading");
        setAlertMessage(t("Loading..."));
        if (isShowDifference) {
          handleDiffFromPreviousVersion();
        } else {
          await handlePrepareTableData();
        }
        setFetchStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("OpcUaHistoryModalContent, useEffect on mount err ", err);
        setFetchStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrepareTableData = async () => {
    const cols = prepareUaSubColumns();
    const subNodes = sub?.nodes.map((node) => node.node_id);

    const input = {
      url: sub.server_url,
      nodes: subNodes,
    };
    const nodeData = await callApi<PostQueryUANodeSnippet>({
      query: postQueryUANode(input),
      auth: { setAuthedUser },
    });
    const formattedNodeData = prepareUaSubRows(nodeData);
    setRows(formattedNodeData);
    setColumns(cols);
  };

  const handleDiffFromPreviousVersion = () => {
    if (previousSub) {
      const nodesDiff = compareArrayVersions(sub.nodes, previousSub.nodes);

      const addedNodes = nodesDiff.added.map((node) => node.node_id);
      const removedNodes = nodesDiff.removed.map((node) => node.node_id);

      const serverUrlDiff = handleConnectionOldNewString(
        sub.server_url,
        previousSub.server_url
      );
      const nameDiff = handleConnectionOldNewString(sub.name, previousSub.name);
      const updateRateDiff = handleConnectionOldNewString(
        sub.update_rate,
        previousSub.update_rate
      );

      setPreviousVersionDiff({
        nodes: {
          added: addedNodes,
          removed: removedNodes,
        },
        serverUrl: serverUrlDiff,
        name: nameDiff,
        updateRate: updateRateDiff,
      });
    }
  };

  if (fetchStatus !== "success") {
    return (
      <Alert
        css={styles.widthLimit25}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={fetchStatus}
      />
    );
  }

  if (!isShowDifference) {
    return (
      <ResponsiveTableGrid
        css={styles.sectionBreak}
        responsive="desktop"
        rows={rows}
        setRows={setRows}
        colSchema={columns}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack
          css={styles.height100}
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="new" />
          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="old" />
      </Grid>
    </Grid>
  );
};

export default OpcUaHistoryModalContent;

const DifferenceList: React.FC<{
  previousVersionDiff: PreviousVersionDiff | null;
  keyValue: keyof OldNew;
}> = ({ previousVersionDiff, keyValue }) => {
  const { t } = useLanguageContext();
  const nodesValue =
    keyValue === "new"
      ? previousVersionDiff?.nodes?.added.join(", ")
      : previousVersionDiff?.nodes?.removed.join(", ");

  const nodesText = keyValue === "new" ? t("New Nodes Added") : t("Old Nodes Removed");

  return (
    <Box component="div" sx={{ width: "100%" }}>
      <Typography sx={{ marginBottom: "1rem" }} variant="h4">
        {keyValue === "new" ? t("Current Version") : t("Previous Version")}
      </Typography>
      <Stack spacing={2}>
        <LabelWithBoldedPart
          text={t("Server URL")}
          bolded={
            previousVersionDiff?.serverUrl
              ? previousVersionDiff.serverUrl[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart
          text={t("Name")}
          bolded={
            previousVersionDiff?.name
              ? previousVersionDiff.name[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart
          text={t("Update Rate")}
          bolded={
            previousVersionDiff?.updateRate
              ? previousVersionDiff.updateRate[keyValue]
              : t("No Change")
          }
        />
        <LabelWithBoldedPart text={nodesText} bolded={nodesValue || t("No Change")} />
      </Stack>
    </Box>
  );
};
