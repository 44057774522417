import { Box, IconButton, Menu, Typography } from "@mui/material";
import { DynamicGridLayoutSettings } from "../../Components/SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useCallback, useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useOutletContext, useParams } from "react-router-dom";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { GetQueryOperationsFullFormSnippet } from "../../Api/Operations/apiOperationsSnippets";
import { getQueryOperationsFullForm } from "../../Api/Operations/apiOperationsGetQueries";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import { AppRouterProps } from "../../Layout/layoutVariables";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import ManufacturingOrderStaticForm from "../../Components/PageComponents/Operations/MyForms/ManufacturingOrderStaticForm";
import QualityParametersStaticForm from "../../Components/PageComponents/Operations/MyForms/QualityParametersStaticForm";
import { staticForms } from "../../Components/PageComponents/Operations/MyForms/staticFormsUtils";
import WidgetsGridLayout from "../../Components/LargeComponents/WidgetsGrid/WidgetsGridLayout";
import { WidgetGridItem } from "../../Components/LargeComponents/WidgetsGrid/widgetsGridUtils";
import DynamicFormField from "../../Components/PageComponents/Operations/DynamicForms/DynamicFormField";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import cssComponentsStyles from "../../Global/Styles/components";
import {
  DynamicFormGridItemData,
  dynamicFromHandlePrepareWidgetsData,
} from "../../Components/PageComponents/Operations/DynamicForms/dynamicFormsUtils";
import DynamicFormItemRightMenu from "../../Components/PageComponents/Operations/DynamicForms/DynamicFormItemRightMenu";
import DynamicFormItemExtraPageMenu from "../../Components/PageComponents/Operations/DynamicForms/DynamicFormItemExtraPageMenu";
import { useLanguageContext } from "../../context/LanguageContext";

type SelectedItem = {
  item: WidgetGridItem<DynamicFormGridItemData>;
  index: number;
} | null;

const OperationsFormPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [settings, setSettings] = useState<DynamicGridLayoutSettings | null>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null); // initial loading
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [isDynamic, setIsDynamic] = useState<boolean>(true);

  const [widgetItems, setWidgetItems] = useState<
    WidgetGridItem<DynamicFormGridItemData>[]
  >([]);
  const [initialWidgetItems, setInitialWidgetItems] = useState<
    WidgetGridItem<DynamicFormGridItemData>[]
  >([]);

  const params = useParams();
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  const openMenu = Boolean(menuAnchor);

  useEffect(() => {
    const isStatic = staticForms.some((form) => form.id === params.id);
    setIsDynamic(!isStatic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, staticForms]);

  useEffect(() => {
    (async () => {
      try {
        if (!params.id) {
          throw new Error("ID from params not found for this operations form");
        }

        if (staticForms.some((form) => form.id === params.id)) {
          return;
        }

        setPageStatus("loading");
        setAlertMessage(t("Loading..."));
        const data = await callApi<GetQueryOperationsFullFormSnippet>({
          query: getQueryOperationsFullForm(params.id),
          auth: { setAuthedUser },
        });
        setSettings({
          id: data.id,
          name: data.name,
        });

        setWidgetItems(dynamicFromHandlePrepareWidgetsData(data.widgets));
        setInitialWidgetItems(dynamicFromHandlePrepareWidgetsData(data.widgets));
        setTitle(data.name);
        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect on mount err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      if (settings) {
        setExtraTopNavMenu(
          <DynamicFormItemExtraPageMenu
            setWidgetItems={setWidgetItems}
            setInitialWidgetItems={setInitialWidgetItems}
            widgetItems={widgetItems}
            settings={settings}
            setUnsavedChanges={setUnsavedChanges}
            setTitle={setTitle}
          />
        );
      }
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, widgetItems, settings]);

  useEffect(() => {
    if (widgetItems && initialWidgetItems) {
      const areEqual = JSON.stringify(widgetItems) === JSON.stringify(initialWidgetItems);
      setUnsavedChanges(!areEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItems]);

  const handleOpenItemMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: WidgetGridItem<any>,
    index: number
  ) => {
    setMenuAnchor(event.currentTarget);
    setSelectedItem({ item, index });
  };
  const handleCloseItemMenu = () => {
    setMenuAnchor(null);
    setSelectedItem(null);
  };

  const handleUpdateWidgetsLayout = useCallback((data: WidgetGridItem<any>[]) => {
    setWidgetItems(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isDynamic) {
    return (
      <>
        {params.id === "manufacturing-order" && <ManufacturingOrderStaticForm />}
        {params.id === "quality-parameters" && <QualityParametersStaticForm />}
      </>
    );
  }

  if (!isDynamic) {
    return (
      <>
        {params.id === "manufacturing-order" && <ManufacturingOrderStaticForm />}
        {params.id === "quality-parameters" && <QualityParametersStaticForm />}
      </>
    );
  }

  return (
    <Box component="div">
      {smMediaQuery && settings ? (
        <DynamicFormItemExtraPageMenu
          setWidgetItems={setWidgetItems}
          setInitialWidgetItems={setInitialWidgetItems}
          widgetItems={widgetItems}
          settings={settings}
          setUnsavedChanges={setUnsavedChanges}
          setTitle={setTitle}
        />
      ) : null}

      {/* TODO: below alert isn't shown at the moment */}
      <Alert
        css={[styles.widthLimit25]}
        message={alertMessage}
        showAlert={false}
        severity={pageStatus}
      />

      {title && (
        <Typography
          css={styles.sectionBreak}
          variant="h2"
          align="center"
          color="textSecondary"
        >
          {title}
        </Typography>
      )}

      {settings && (
        <>
          <WidgetsGridLayout
            dataItems={widgetItems}
            updateDataItems={handleUpdateWidgetsLayout}
            rowHeight={35}
            renderRightMenu={(item, index) => (
              <IconButton
                aria-label="widget setting menu"
                onClick={(e) => handleOpenItemMenu(e, item, index)}
              >
                <TuneOutlinedIcon css={styles.greyIcon} />
              </IconButton>
            )}
            render={(item) => <DynamicFormField formField={item.widget.formField} />}
          />

          <Menu
            anchorEl={menuAnchor}
            open={openMenu}
            onClose={handleCloseItemMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            {selectedItem ? (
              <DynamicFormItemRightMenu
                widgetItem={selectedItem.item}
                widgetIndex={selectedItem.index}
                setWidgetItems={setWidgetItems}
                handleCloseMenu={handleCloseItemMenu}
              />
            ) : null}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default OperationsFormPage;
