import { addMonths, format, isWeekend, nextMonday } from "date-fns";
import { Query } from "../../../Api/callApi";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";

export const modalTitleTranslations = {
  "Create New Order": "Create New Order",
} as const;

export type ModalTitle = keyof typeof modalTitleTranslations;

export type SampleOrderFormValues = {
  sample_name: string;
  contact_person_development: string;
  contact_person_ppm: string;
  business_unit: "";
  cost_center: string;
  sap_order_number: number | null;
  quantity_panels: number | null;
  me_sampling_needed_panels: number | null;
  quantity_pcbs: number | null;
  preferred_date: Date | null;
  material_availability_date: Date | null;
  panels: string;
  assembly_board: string;
  number_of_board_bare_pcb: string;
  number_of_board_bare_panel: string;
  windchill_link: string;
  order_new_stencil: string;
  stencil_order_speed: string;
  spi_report: string;
  thermoprofile_measurement: string;
  aoi: string;
  coverage_report: string;
  spc_report: string;
  cross_section_analisys: string;
};

export const convertDateToString = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const ORDER_STAGES = [
  "Sample Details",
  "Panel and PCB Details",
  "PCB and Assembly Details",
  "Additional Options",
  "Order Summary",
];

export type orderNewStencilScheme = "Complete Redesign (+1500 €)" | "Yes" | "No";
export const orderNewStencilSchemeArray: orderNewStencilScheme[] = [
  "Complete Redesign (+1500 €)",
  "Yes",
  "No",
];
export const orderNewStencilSchemeOptions = handleGetSelectOption(
  orderNewStencilSchemeArray,
  true
);

export type stencilOrderSpeedScheme =
  | "Standard (up to 14 working days)"
  | "Express (up to 3 working days) (+800 €)"
  | "Urgent (next working day) (+5000 €)";
export const stencilOrderSpeedSchemeArray: stencilOrderSpeedScheme[] = [
  "Standard (up to 14 working days)",
  "Express (up to 3 working days) (+800 €)",
  "Urgent (next working day) (+5000 €)",
];
export const stencilOrderSpeedSchemeOptions = handleGetSelectOption(
  stencilOrderSpeedSchemeArray,
  true
);

export type spiReportScheme = "Yes (+200 €)" | "No";
export const spiReportSchemeArray: spiReportScheme[] = ["Yes (+200 €)", "No"];
export const spiReportSchemeOptions = handleGetSelectOption(spiReportSchemeArray, true);

export type thermoprofileMeasurementScheme =
  | "Yes, with report (+400 €)"
  | "Use similar profile (no measurement)";
export const thermoprofileMeasurementSchemeArray: thermoprofileMeasurementScheme[] = [
  "Yes, with report (+400 €)",
  "Use similar profile (no measurement)",
];
export const thermoprofileMeasurementSchemeOptions = handleGetSelectOption(
  thermoprofileMeasurementSchemeArray,
  true
);

export type aoiScheme = "Yes (+400 €)" | "No";
export const aoiSchemeArray: aoiScheme[] = ["Yes (+400 €)", "No"];
export const aoiSchemeOptions = handleGetSelectOption(aoiSchemeArray, true);

export type coverageReportScheme = "Yes (+200 €)" | "No";
export const coverageReportSchemeArray: coverageReportScheme[] = ["Yes (+200 €)", "No"];
export const coverageReportSchemeOptions = handleGetSelectOption(
  coverageReportSchemeArray,
  true
);

export type spcReportScheme = "Yes (+200 €)" | "No";
export const spcReportSchemeArray: spcReportScheme[] = ["Yes (+200 €)", "No"];
export const spcReportSchemeOptions = handleGetSelectOption(spcReportSchemeArray, true);

export type crossSectionAnalysisScheme = "Yes (+800 €)" | "No";
export const crossSectionAnalysisSchemeArray: crossSectionAnalysisScheme[] = [
  "Yes (+800 €)",
  "No",
];
export const crossSectionAnalysisSchemeOptions = handleGetSelectOption(
  crossSectionAnalysisSchemeArray,
  true
);

export type OrdersValidPointsFieldsInput = {
  D?: number;
  L?: number;
  tolerance?: string;
  material?: string;
  surface?: string;
  A?: number;
  KA?: number;
  B?: number;
  KB?: number;
  C?: number;
  KC?: number;
  version?: string;
};

export type OrdersValidPointsFieldsSchema = {
  product_code: null | string;
  D: {
    options: string[];
    value: string;
  };
  L: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  tolerance: {
    options: string[];
    value: string;
  };
  material: {
    options: string[];
    value: string;
  };
  surface: {
    options: string[];
    value: string;
  };
  A: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  KA: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  B: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  KB: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  C: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  KC: {
    max: number;
    min: number;
    step: null;
    value: number;
  };
  version: {
    options: string[];
    value: string;
  };
};

export const postQueryOrderValidPoints = (
  input: OrdersValidPointsFieldsInput
): Query => ({
  endpoint: "project_management/valid-options",
  method: "POST",
  variables: input,
});

export const ORDERS_FIELD_MAPPING: Record<string, string> = {
  shaftDiameter_d: "D",
  material: "material",
  surface: "surface",
  tolerance: "tolerance",
  length_l: "L",
  length_a: "A",
  distance_ka: "KA",
  version: "version",
  length_b: "B",
  distance_kb: "KB",
  length_c: "C",
  distance_kc: "KC",
};

export const ordersGetSavedFields = (fields: Record<string, string>) => {
  const result = {} as OrdersValidPointsFieldsInput;

  Object.entries(fields).map(([key, value]) => {
    const inputKey = ORDERS_FIELD_MAPPING[key];
    if (value !== "") {
      // @ts-ignore
      result[inputKey] = isNaN(value) ? value : +value;
    }
  });

  return result;
};

export type OrderTaskDatum = {
  id: string;
  name: string;
  duration: number;
  project_id: string;
  parent_name: string;
  parent_id: string;
  project_name: string;
};
export type OrderTableDatum = {
  product_code: string;
  product_family: string;
  shipping_date: Date;
  order_date: Date;
  quantity: number;
  ssd: number;
  id: string;
};
export type OrderTableRow = {
  product_code: string;
  product_family: string;
  shipping_date: Date;
  order_date: Date;
  quantity: number;
  ssd: number;
  id: string;
  tasks: React.ReactNode;
};

export type OrderOptions = { option_name: string; option_value: string };

export type OrderManagementTableRow = {
  order_number: string;
  sample_name: string;
  contact_person_development: string;
  contact_person_ppm: string;
  quantity_panels: number;
  me_sampling_needed_panels: number;
  quantity_pcbs: number;
  business_unit: string;
  preferred_date: string;
  material_availability_date: string;
  cost_center: string;
  panels: string;
  assembly_board: string;
  number_of_board_bare_pcb: string;
  number_of_board_bare_panel: string;
  windchill_link: string;
  sap_order_number: number;
  status: string;
  order_new_stencil: string;
  stencil_order_speed: string;
  spi_report: string;
  thermoprofile_measurement: string;
  aoi: string;
  coverage_report: string;
  spc_report: string;
  cross_section_analisys: string;
};

export type OrderDetailsTableRow = {
  sample_name: string;
  task_name: string;
  data_field: string;
  data_value: string;
};

export type StatusStages = "in_progress" | "finished" | "planned" | "pending";

export const setPreferredDateToDefault = () => {
  const today = new Date();
  let preferredDate = addMonths(today, 6);
  if (isWeekend(preferredDate)) {
    preferredDate = nextMonday(preferredDate);
  }
  return preferredDate;
};
