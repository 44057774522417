import { SelectOption } from "../../../Global/Types/commonTypes";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";

type GraphqlFiltersDataTypes =
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "time"
  | "dateTime";

export type GraphqlFiltersData = {
  paramName: string;
  paramContainer?: string;
  paramID: string;
  type: GraphqlFiltersDataTypes;
};

export type GraphqlFilter = {
  paramID: string;
  paramName: string;
  filterOperation: GraphqlFilterOperation | "";
  filterValue: string;
  type: GraphqlFiltersDataTypes | "";
};

export const handleExcellenceParamToGraphqlFilters = (
  parameters: ExcellenceParameter[]
): GraphqlFiltersData[] => {
  return parameters.map((param) => ({
    paramName: param.name,
    paramContainer: param.container,
    paramID: param.id,
    type: param.type as GraphqlFiltersDataTypes,
  }));
};

export type GraphqlFilterOperation =
  | "EQ"
  | "NE"
  | "LE"
  | "LTE"
  | "GE"
  | "GTE"
  | "CONTAINS"
  | "STARTS_WITH"
  | "ENDS_WITH";

export const GRAPHQL_FILTER_OPERATIONS_NUMBER: SelectOption<GraphqlFilterOperation>[] = [
  { value: "LE", description: "Less Than" },
  { value: "LTE", description: "Less Than or Equal to" },
  { value: "GE", description: "Greater Than" },
  { value: "GTE", description: "Greater Than or Equal to" },
  { value: "EQ", description: "Equals" },
  { value: "NE", description: "Not Equals" },
];
export const GRAPHQL_FILTER_OPERATIONS_STRING: SelectOption<GraphqlFilterOperation>[] = [
  { value: "CONTAINS", description: "Contains" },
  { value: "STARTS_WITH", description: "Starts with" },
  { value: "ENDS_WITH", description: "Ends with" },
  { value: "EQ", description: "Equals" },
  { value: "NE", description: "Not Equals" },
];

export const buildGraphqlFilters = (graphqlFilters: GraphqlFilter[] | undefined) => {
  if (!graphqlFilters?.length) {
    return undefined;
  }
  return graphqlFilters
    .filter((filter) => filter.filterOperation && filter.filterValue)
    .map((filter) => ({
      field: filter.paramID,
      operator: filter.filterOperation,
      value: filter.type === "number" ? Number(filter.filterValue) : filter.filterValue,
    }));
};
