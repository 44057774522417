import { SerializedStyles } from "@emotion/react";
import TimeChart from "../../../../ExcellenceWidgets/TimeChart/TimeChart";
import {
  ChartXScaleMinMax,
  TimeChartCircle,
  TimeChartConfigAndData,
} from "../../../../ExcellenceWidgets/TimeChart/timeChartTypes";
import { useEffect, useRef, useState } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceTimeSeriesData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceWidgetItemMode } from "../../excellenceUtils";
import { ExcellenceDateTimeConfigurationData } from "../../../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import { ExcellenceChartDataSchema } from "../../../../../GraphQL/Excellence/graphQLTypesExcellence";
import {
  getTimeChartStartEndTimes,
  getTimeChartXScaleMinMax,
} from "../../../../ExcellenceWidgets/TimeChart/timeChartUtils";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import ChartScopeMode from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartScopeMode";
import ChartPeriodViewer from "../../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import { PeriodModeDates } from "../../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { add, isBefore } from "date-fns";
import { buildGraphqlFilters } from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

const MODE_HEIGHT = "25px";

interface ExcellenceTimeChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  timeChart: TimeChartConfigAndData;
  isStatic?: boolean;
  loading: boolean;
  chartMode: ExcellenceWidgetItemMode | null;
  titleComponent: React.ReactNode;
  resetChartFetch: boolean;
}

const ExcellenceTimeChart: React.FC<ExcellenceTimeChartProps> = ({
  className,
  timeChart,
  isStatic,
  loading,
  chartMode,
  titleComponent,
  resetChartFetch,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const [stateChart, setStateChart] = useState<TimeChartConfigAndData>(timeChart);
  const [formStatus, setFormStatus] = useState<FormStatuses>(loading ? "loading" : null);
  const [chartAnimate, setChartAnimate] = useState<boolean>(false);

  const [xScaleMinMax, setXScaleMinMax] = useState<ChartXScaleMinMax | undefined>(
    undefined
  );
  const [dates, setDates] = useState<PeriodModeDates | null>(null);
  const [circles, setCircles] = useState<TimeChartCircle[] | null>(null);

  const [getLineData, { loading: loadingLineData }] = useLazyQuery(
    graphQlQueryExcellenceTimeSeriesData
  );

  const periodPreviewMode = chartMode?.timeChart?.periodPreviewMode;
  const scopeMode = chartMode?.timeChart?.scopeMode;

  /** Initial data fetch for time chart */
  useEffect(() => {
    (async () => {
      if (formStatus === "loading" && !resetChartFetch) {
        return;
      }
      setFormStatus("loading");
      try {
        console.log("here");
        const { data, dataSchema, dateTimeConfig, ...rest } = timeChart;
        let updatedChart: any = null;

        if (dateTimeConfig && dataSchema) {
          const chartWithUpdatedData = await handleFetchData(
            dateTimeConfig,
            dataSchema,
            true
          );
          if (chartWithUpdatedData) {
            updatedChart = chartWithUpdatedData;
          }
        }

        setStateChart(() => {
          if (updatedChart) {
            return {
              ...timeChart,
              ...updatedChart,
              ...rest,
            };
          }
          return {
            ...timeChart,
            ...rest,
          };
        });
        setFormStatus("success");
      } catch (err) {
        console.log("ExcellenceTimeChart - useEffect ", err);
        setFormStatus("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, resetChartFetch]);

  /** Interval re-fetch data logic */
  useEffect(() => {
    (async () => {
      if (!isStatic && formStatus === "success") {
        const periodPreviewMode = chartMode?.timeChart?.periodPreviewMode;
        const scopeMode = chartMode?.timeChart?.scopeMode;

        try {
          const dateTimeConfig = timeChart?.dateTimeConfig;
          stopInterval();
          startInterval();

          if (
            periodPreviewMode ||
            scopeMode ||
            (!dateTimeConfig?.liveDataUpdateRate &&
              !dateTimeConfig?.commonPeriodFetchRate)
          ) {
            stopInterval();
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();

    return () => {
      stopInterval();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus, chartMode]);

  /** Chart mode changes */
  useEffect(() => {
    (async () => {
      const dateTimeConfig = timeChart?.dateTimeConfig;
      const schema = timeChart?.dataSchema;
      const periodMode = chartMode?.timeChart?.periodPreviewMode;
      const zoomMode = chartMode?.timeChart?.scopeMode;

      if (periodMode && schema?.startTime && schema.endTime) {
        const currentDate = new Date();
        setDates({
          startDate: add(currentDate, { hours: -1 }),
          endDate: currentDate,
        });
      }

      if (zoomMode && schema?.startTime && schema.endTime) {
        const startEndTimes = dateTimeConfig
          ? getTimeChartStartEndTimes(dateTimeConfig)
          : null;
        setDates({
          startDate: new Date(startEndTimes?.startTime || schema.startTime),
          endDate: new Date(startEndTimes?.endTime || schema.endTime),
        });
      }

      if (!periodMode && !zoomMode && schema) {
        setDates(null);
        setCircles(null);
        await handleFetchData(dateTimeConfig || null, schema);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartMode]);

  /** New data based on mode */
  useEffect(() => {
    (async () => {
      const dataSchema = timeChart?.dataSchema;

      if (dataSchema && dates) {
        // 1. get the schema start and end point
        const schemaToUse = {
          ...dataSchema,
          startTime: dates.startDate.toISOString(),
          endTime: dates.endDate.toISOString(),
        };
        console.log("schemaToUse ", schemaToUse);

        // fetch data and update state
        await handleFetchData(null, schemaToUse);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  /** Circle placing logic */
  useEffect(() => {
    if (circles?.length && circles.length > 1) {
      const circle1Date = new Date(circles[0].xFormatted);
      const circle2Date = new Date(circles[1].xFormatted);
      const firstCircleIsStartDate = isBefore(circle1Date, circle2Date);

      setDates({
        startDate: firstCircleIsStartDate ? circle1Date : circle2Date,
        endDate: firstCircleIsStartDate ? circle2Date : circle1Date,
      });
      setCircles(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circles]);

  const startInterval = () => {
    const dateTimeConfig = timeChart?.dateTimeConfig;
    const dataSchema = timeChart?.dataSchema;
    let intervalRate: number | null = null;

    if (dataSchema) {
      if (dateTimeConfig?.liveDataUpdateRate) {
        intervalRate = +dateTimeConfig.liveDataUpdateRate;
      } else if (dateTimeConfig?.commonPeriodFetchRate) {
        intervalRate = +dateTimeConfig.commonPeriodFetchRate;
      }
    }

    if (dataSchema && intervalRate && dateTimeConfig) {
      intervalIdRef.current = setInterval(async () => {
        await handleFetchData(dateTimeConfig, dataSchema);
      }, intervalRate);
    }
  };

  const stopInterval = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleOnMouseOver = () => {
    if (!chartAnimate) {
      setChartAnimate(true);
    }
  };

  const handleFetchData = async (
    timeConfig: ExcellenceDateTimeConfigurationData | null,
    existingSchema: ExcellenceChartDataSchema,
    noStateUpdate?: boolean
  ): Promise<TimeChartConfigAndData | undefined> => {
    try {
      let schemaToUse = { ...existingSchema };

      // 1. get start / end times
      if (timeConfig) {
        const { startTime, endTime } = getTimeChartStartEndTimes(timeConfig);
        schemaToUse = {
          ...existingSchema,
          startTime,
          endTime,
        };
      }

      // 2. fetch the data
      const result = await getLineData({
        variables: {
          input: {
            ...schemaToUse,
            filters: buildGraphqlFilters(timeChart.config.graphqlFilters),
          },
        },
      });
      if (!result.data || result.error) {
        throw new Error(JSON.stringify(result));
      }

      // 3. update the state data
      const resultData = result.data.timeSeriesData.chartData;
      const updatedChart = stateChart
        ? {
            ...stateChart,
            data: resultData,
            config: timeChart.config,
          }
        : stateChart;

      if (!noStateUpdate) {
        setStateChart(updatedChart);
      }

      // 4. adjust the X-axis values
      const newXScaleMinMax = getTimeChartXScaleMinMax(timeConfig, resultData, undefined);
      setXScaleMinMax(newXScaleMinMax);
      return updatedChart;
    } catch (err) {
      console.log("time chart - handleFetchData() err ", err);
    }
  };

  return (
    <Box component="div" className={className} onMouseOver={handleOnMouseOver}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {titleComponent}

        {periodPreviewMode && dates ? (
          <ChartPeriodViewer dates={dates} setDates={setDates} />
        ) : null}

        {scopeMode ? <ChartScopeMode /> : null}
      </Stack>

      <Box
        component="div"
        sx={{
          width: "100%",
          height: scopeMode || periodPreviewMode ? `calc(100% - ${MODE_HEIGHT})` : "100%",
        }}
      >
        <TimeChart
          css={[styles.width100, styles.height100]}
          configuration={stateChart.config}
          data={stateChart.data}
          isStatic={isStatic}
          animate={chartAnimate}
          xScaleMinMax={xScaleMinMax}
          circles={
            !scopeMode
              ? undefined
              : {
                  circles,
                  setCircles,
                }
          }
        />

        {isStatic ? null : <LoadingBackdrop loading={loadingLineData} />}
      </Box>
    </Box>
  );
};

export default ExcellenceTimeChart;
