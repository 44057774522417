import { Grid, useTheme } from "@mui/material";
import QuillEditor from "../../SmallComponents/QuillEditor/QuillEditor";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useState } from "react";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  postQueryHelpCreateSelection,
  updateQueryHelpSelection,
} from "../../../Api/Help/apiHelpPostQueries";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import { PostQueryHelpSelectionInput } from "../../../Api/Help/apiHelpInputs";
import { ModalHelpTitle } from "./helpUtils";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";
import { HelpSelection } from "../../../Api/Help/apiHelpSnippets";
import { useLanguageContext } from "../../../context/LanguageContext";

interface AddHelpContentFormProps {
  pageKeyId: string | undefined;
  order: number;
  setModalTitle: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
  fetchHelpContent: () => void;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  editorContent: string;
  setEditorContent: React.Dispatch<React.SetStateAction<string>>;
  helpSelection?: HelpSelection;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddHelpContentForm: React.FC<AddHelpContentFormProps> = ({
  pageKeyId,
  order,
  setModalTitle,
  fetchHelpContent,
  handleSetUnsavedChanges,
  editorContent,
  setEditorContent,
  helpSelection,
  setUnsavedEditChanges,
  setOpenInfo,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  useDetectFormsUnsavedChanges(
    helpSelection ? helpSelection.content : "",
    editorContent,
    handleSetUnsavedChanges
  );

  const handleSave = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage(t("Loading..."));

      const input: PostQueryHelpSelectionInput = {
        order: helpSelection ? helpSelection.order : order,
        content: editorContent,
        type: "text",
      };

      if (helpSelection) {
        await callApi<string>({
          query: updateQueryHelpSelection(helpSelection.id, input),
          auth: { setAuthedUser },
        });
      }

      if (!helpSelection && pageKeyId) {
        await callApi<HelpSelection>({
          query: postQueryHelpCreateSelection(pageKeyId, input),
          auth: { setAuthedUser },
        });
      }

      setUnsavedEditChanges(false);
      fetchHelpContent();
      setFormStatus("success");
      setAlertMessage(t("Successfully added content"));
      setModalTitle(null);
      if (setOpenInfo) {
        setOpenInfo(false);
      }
    } catch (error) {
      console.log(error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Grid container gap={2} justifyContent="space-between">
      <Grid item xs={12}>
        <QuillEditor
          label={t("Add your text here")}
          css={[styles.width100, styles.labelBreak]}
          editorState={editorContent}
          onEditorStateChange={(state: string) => {
            setEditorContent(state);
          }}
          disabled={formStatus === "loading" || formStatus === "success"}
        />
      </Grid>

      <Grid item xs={12} mt={2} container justifyContent="center">
        <Button
          css={[styles.widthLimit20]}
          onClick={handleSave}
          disabled={formStatus === "loading" || formStatus === "success"}
        >
          {t("Save Changes")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={formStatus}
        />
      </Grid>
    </Grid>
  );
};

export default AddHelpContentForm;
