import { memo, useEffect, useState } from "react";
import {
  PostQueryDaNodesSnippet,
  GetQueryDAAttributesSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { DaFormattedCanonical, prepareDaSubColumns, prepareDaSubRows } from "./daUtils";
import callApi from "../../../../../Api/callApi";
import { postQueryDaNodes } from "../../../../../Api/DataSources/apiDSPostQueries";
import { Box, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { PostQueryDaNodesInput } from "../../../../../Api/DataSources/apiDSInputs";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import { useTranslateFields } from "../../../../../Global/Hooks/useTranslations";

interface DaDataSnapshotProps {
  serverName: string | null;
  selectedItems: string[];
  selectedGroupProperties: GetQueryDAAttributesSnippet;
  updateTime: string | number;
  canonicalDetails: DaFormattedCanonical | null;
}

const DaDataSnapshot: React.FC<DaDataSnapshotProps> = ({
  serverName,
  selectedItems,
  selectedGroupProperties,
  updateTime,
  canonicalDetails,
}) => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const { setAuthedUser } = useAuthedContext();
  const columns: TableGridColumnSchema[] = prepareDaSubColumns(selectedGroupProperties);

  useEffect(() => {
    // initiate interval for fetching data based
    // on the updateRate provided
    (async () => {
      await handleFetchItemDetailsData();
    })();

    const interval = setInterval(async () => {
      await handleFetchItemDetailsData();
    }, +updateTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchItemDetailsData = async () => {
    try {
      if (!serverName) {
        throw new Error("Server not found");
      }

      const input: PostQueryDaNodesInput = {
        server_name: serverName,
        nodes: selectedItems,
      };
      const itemDetails = await callApi<PostQueryDaNodesSnippet>({
        query: postQueryDaNodes(input),
        auth: { setAuthedUser },
      });
      const formattedItemDetails = prepareDaSubRows(itemDetails, canonicalDetails || {});
      setRows(formattedItemDetails);
      setInitialLoading(false);
    } catch (err) {
      console.log("handleFetchItemDetailsData() err", err);
    }
  };

  return (
    <Box component="div" css={styles.width100}>
      <Stack
        css={styles.width100}
        spacing={4}
        justifyContent="center"
        alignItems="center"
      >
        <Typography css={styles.widthLimit55} variant="body2" align="center">
          {t(
            "Below, you can find a snapshot of how your data will look like, once you start monitoring it. If something doesn't look right, you can modify the settings, before starting the monitoring data process."
          )}
        </Typography>

        <Typography variant="body1" align="center">
          {t("Table data is being refreshed every")} {updateTime} {t("milliseconds")}
        </Typography>

        <ResponsiveTableGrid
          css={styles.width100}
          responsive="desktop"
          rows={rows}
          setRows={setRows}
          colSchema={useTranslateFields(columns, ["label"])}
          loading={initialLoading}
        />
      </Stack>
    </Box>
  );
};

export default memo(DaDataSnapshot);
