import { FileWithPath } from "react-dropzone";
import { Query } from "../callApi";
import { PostQueryHelpSelectionInput } from "./apiHelpInputs";

export const postQueryHelpUploadPageFile = (
  page_key_id: string,
  file: FileWithPath
): Query => ({
  endpoint: `help/page/${page_key_id}/upload`,
  method: "POST",
  variables: {
    file,
  },
  multipartForm: true,
});

export const postQueryHelpCreateSelection = (
  page_key_id: string,
  input: PostQueryHelpSelectionInput
): Query => ({
  endpoint: `help/page/${page_key_id}/selection`,
  method: "POST",
  variables: input,
});

export const deleteQueryHelpSelection = (selection_id: string): Query => ({
  endpoint: `help/selection/${selection_id}`,
  method: "DELETE",
});

export const updateQueryHelpSelection = (
  selection_id: string,
  input: PostQueryHelpSelectionInput
): Query => ({
  endpoint: `help/selection/${selection_id}`,
  method: "PUT",
  variables: input,
});
