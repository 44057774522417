import useTheme from "@mui/material/styles/useTheme";
import { SerializedStyles, css } from "@emotion/react";
import {
  IndicatorDataSchema,
  IndicatorWidgetButtonTypes,
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { IndicatorWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import IndicatorWidgetChart from "./IndicatorWidgetChart";
import IndicatorWidgetAvatarIcon from "./IndicatorWidgetAvatarIcon";
import IndicatorButtons from "./IndicatorButtons";
import indicatorWidgetDefaultData from "../ExcellenceDefaultConfig/indicatorWidgetDefaultData";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

const cssStyles = (theme: Theme) => ({
  stackContainerStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  }),
  subtitleStyle: css({
    color: theme.palette.text.secondary,
    marginTop: 4,
  }),
});

interface IndicatorWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: IndicatorWidgetConfiguration;
  data: IndicatorWidgetData;
  handleButtonClick: (type: IndicatorWidgetButtonTypes) => Promise<void>;
  dataSchema: IndicatorDataSchema | null;
}

const IndicatorWidget: React.FC<IndicatorWidgetProps> = ({
  configuration,
  data,
  handleButtonClick,
  dataSchema,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };
  const dataToUse = data || indicatorWidgetDefaultData;
  const displayedValue = getDisplayedValue(dataToUse, configuration);

  const getTrendDescription = () => {
    const deviation = configuration.deviation ? `${configuration.deviation}%` : null;
    const target =
      configuration.targetValue && !isNaN(Number(configuration.targetValue))
        ? Number(configuration.targetValue)
        : null;

    const getDescriptionForValue = (value: number) => {
      const deviationText = deviation
        ? `Deviating by ${configuration.deviation}%`
        : "No deviation available";

      if (target !== null && typeof configuration.deviation === "number") {
        const percentageDeviation = Math.abs((value - target) / target) * 100;
        if (percentageDeviation > configuration.deviation) {
          return `${configuration.valueType} deviating from Target ${target} by more than ${configuration.deviation}%`;
        } else {
          return `${configuration.valueType} within Target ${target} (${configuration.deviation}% Deviation)`;
        }
      }

      return `${configuration.valueType} ${
        target ? `Target ${target}` : ""
      } ${deviationText}`;
    };

    if (configuration.valueType === "Average") {
      return getDescriptionForValue(dataToUse.average);
    } else {
      return getDescriptionForValue(dataToUse.latest);
    }
  };

  return (
    <Stack p={2} spacing={4}>
      <Stack css={styles.stackContainerStyle}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <IndicatorWidgetAvatarIcon
            configuration={configuration}
            trendAverage={dataToUse.trendAverage}
            trendLatest={dataToUse.trendLatest}
            threshold={dataSchema?.threshold || null}
            latest={dataToUse.latest}
            average={dataToUse.average}
          />
          <Typography
            variant="h6"
            sx={{ color: theme.palette.customColors.greyText, fontWeight: 400, ml: 3 }}
          >
            {getTrendDescription()}
          </Typography>
        </Box>
        <IndicatorButtons handleButtonClick={handleButtonClick} />
      </Stack>

      <Stack css={styles.stackContainerStyle}>
        <Stack spacing={3}>
          <Typography variant="h1">{displayedValue}</Typography>
          <Box component="div">
            <Typography variant="h4">{configuration.title}</Typography>
            <Typography variant="body2" css={styles.subtitleStyle}>
              {configuration.subtitle}
            </Typography>
          </Box>
        </Stack>
        <IndicatorWidgetChart isInteractive={false} chartData={dataToUse.chart} />
      </Stack>
    </Stack>
  );
};

export default IndicatorWidget;

const getDisplayedValue = (
  data: IndicatorWidgetData,
  configuration: IndicatorWidgetConfiguration
) => {
  const val = configuration.valueType === "Average" ? data.average : data.latest;

  const result = formatNumber(
    val,
    configuration.fixedDecimalValue,
    configuration.decimalPoints
  );

  return `${result} ${configuration.symbol.replace(/[()]/g, "")}`;
};
