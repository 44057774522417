import { SerializedStyles, css } from "@emotion/react";
import { Box, Drawer, Theme, useTheme } from "@mui/material";
import HelpPage from "../../pages/Help/HelpPage";

const cssStyles = (theme: Theme, openRightNav: boolean) => ({
  drawer: css({
    width: openRightNav ? "50vw" : 0,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "50vw",
      boxSizing: "border-box",
      border: "none",
    },
  }),
  rightNavContent: css({
    marginTop: "70px",
    overflow: "auto",
    height: "100%",
    padding: theme.spacing(1),
    background:
      theme.palette.mode === "light"
        ? theme.palette.background.paper
        : theme.palette.background.default,
  }),
});

interface RightNavigationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  openRightNav: boolean;
  setOpenRightNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightNavigation: React.FC<RightNavigationProps> = ({
  className,
  openRightNav,
  setOpenRightNav,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme, openRightNav) };

  const handleClose = () => {
    setOpenRightNav(false);
  };

  return (
    <Drawer
      className={className}
      css={styles.drawer}
      anchor="right"
      open={openRightNav}
      onClose={handleClose}
      variant="temporary"
    >
      <Box component="div" css={styles.rightNavContent}>
        <HelpPage locationPath={location.pathname} />
      </Box>
    </Drawer>
  );
};

export default RightNavigation;
