import { Box, IconButton, Stack, Theme, Typography } from "@mui/material";
import { css } from "@emotion/react";
import {
  AUTH_LAYOUT_PADDING,
  AppRouterProps,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
} from "../../Layout/layoutVariables";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Modal from "../../Components/MaterialUI/Modal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "../../Components/MaterialUI/Button";
import Alert from "../../Components/MaterialUI/Alert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import BoschWorkflow from "../../Components/PageComponents/TestPlans/BoschWorkflow";
import { useAuthedContext } from "../../context/AuthContext";
import { BoschWorkflowType } from "../../Api/BoschWorkflows/apiBoschWorkflowsTypes";
import { getQueryBoschWorkflow } from "../../Api/BoschWorkflows/apiBoschWorkflowsGetQueries";
import { GetQueryBoschWorkflowSnippet } from "../../Api/BoschWorkflows/apiBoschWorkflowsSnippets";
import callApi from "../../Api/callApi";
import {
  deleteQueryBoschWorkflow,
  putQueryBoschWorkflow,
} from "../../Api/BoschWorkflows/apiBoschWorkflowsPostQueries";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import EditBoschWorkflowForm from "../../Components/PageComponents/TestPlans/EditBoschWorkflowForm";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { PutQueryBoschWorkflowInput } from "../../Api/BoschWorkflows/apiBoschWorkflowsInputs";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";

const cssStyles = (theme: Theme) => ({
  flowWrapper: css({
    width: "100%",
    // minHeight: parent's minHeight - the padding
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING})`,
    [theme.breakpoints.down("sm")]: {
      // minHeight: parent's minHeight - the padding - extra_nav_menu
      minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT} - ${AUTH_LAYOUT_PADDING} - ${AUTH_LAYOUT_PADDING} - 75px)`,
    },
  }),
  flow: css({
    minHeight: "inherit",
    "& > div": {
      minHeight: "inherit",
    },
  }),
});

const modalTitleTranslations = {
  "Edit Workflow": "Edit Workflow",
  "Delete Workflow": "Delete Workflow",
} as const;

type ModalType = keyof typeof modalTitleTranslations;

const TestPlanPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssSpacingStyles(theme) };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [pageAlert, setPageAlert] = useState<string | null>(null);
  const [workflow, setWorkflow] = useState<BoschWorkflowType | null>(null);

  const [modalType, setModalType] = useState<ModalType | null>(null);
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [initialNodeUpdated, setInitialNodeUpdated] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!params.id) {
          throw new Error("ID from params not found");
        }
        setPageStatus("loading");
        setPageAlert(t("Loading..."));

        const result = await callApi<GetQueryBoschWorkflowSnippet>({
          query: getQueryBoschWorkflow(params.id),
          auth: { setAuthedUser },
        });

        setWorkflow(result.data);
        setPageStatus("success");
        setPageAlert(null);
      } catch (err) {
        console.log("useEffect err ", err);
        setPageStatus("error");
        setPageAlert(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <ManageWorkflow setModalType={setModalType} workflow={workflow} />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, workflow]);

  const handleDeleteWorkflow = async () => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      if (params.id) {
        await callApi({
          query: deleteQueryBoschWorkflow(params.id),
          auth: { setAuthedUser },
        });
      }

      setFormStatus("success");
      setAlertMessage(null);
      navigate({
        pathname: `${ROUTES_MAPPING["Test Plans"]}`,
      });
    } catch (err) {
      console.log("error - handleDeleteWorkflow() ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleEditWorkflow = (updatedWorkflow: BoschWorkflowType) => {
    setWorkflow(updatedWorkflow);
    setInitialNodeUpdated((prev) => !prev);
    setModalType(null);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  if (pageAlert) {
    return <Alert message={pageAlert} severity={pageStatus} showAlert />;
  }

  return (
    <Box component="div" css={styles.flowWrapper}>
      {smMediaQuery ? (
        <Box component="div" css={styles.labelBreak}>
          <ManageWorkflow setModalType={setModalType} workflow={workflow} />
        </Box>
      ) : null}

      {workflow?.id ? (
        <BoschWorkflow
          css={styles.flow}
          workflow={workflow}
          initialNodeUpdated={initialNodeUpdated}
          setWorkflow={setWorkflow}
        />
      ) : null}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth="sm"
        label={modalType ? getTranslatedModalTitle(modalType) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Workflow" && workflow?.id ? (
          <EditBoschWorkflowForm
            workflow={workflow}
            handleEditWorkflow={handleEditWorkflow}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {modalType === "Delete Workflow" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete your workflow")}
            </Typography>
            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Button onClick={handleDeleteWorkflow}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default TestPlanPage;

interface ManageWorkflowProps {
  setModalType: React.Dispatch<React.SetStateAction<ModalType | null>>;
  workflow: BoschWorkflowType | null;
}

const ManageWorkflow: React.FC<ManageWorkflowProps> = ({ setModalType, workflow }) => {
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const handleSave = async () => {
    try {
      if (!workflow?.id) {
        return;
      }
      const updatedWorkflow: BoschWorkflowType = {
        ...workflow,
      };
      const input: PutQueryBoschWorkflowInput = {
        data: updatedWorkflow,
      };
      await callApi({
        query: putQueryBoschWorkflow(input, workflow.id),
        auth: { setAuthedUser },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack spacing={1} justifyContent="flex-end" direction="row">
      <Stack alignItems="center">
        <IconButton aria-label="save changes" onClick={handleSave}>
          <SaveOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Save")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton
          aria-label="edit workflow"
          onClick={() => setModalType("Edit Workflow")}
        >
          <EditOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Edit")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton aria-label="export" disabled>
          <FileDownloadOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textSecondary">
          {t("Export")}
        </Typography>
      </Stack>

      <Stack alignItems="center">
        <IconButton
          aria-label="delete workflow"
          onClick={() => setModalType("Delete Workflow")}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="textPrimary">
          {t("Delete")}
        </Typography>
      </Stack>
    </Stack>
  );
};
