import { SerializedStyles, css } from "@emotion/react";
import { Box, Divider, Drawer } from "@mui/material";
import { LEFT_NAV_WIDTH, TOP_NAV_SPACING_WITH_SITE_CONTENT } from "../layoutVariables";
import LeftNavListMenu from "./LeftNavListMenu";
import {
  useTranslatedElpromNav,
  useTranslatedNav,
} from "../../Global/Hooks/useTranslatedNav";
import { getCurrentEnv } from "../../Global/Utils/commonFunctions";

const cssStyles = (openLeftNav: boolean) => ({
  drawer: css({
    width: openLeftNav ? LEFT_NAV_WIDTH : 0,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: LEFT_NAV_WIDTH,
      boxSizing: "border-box",
      border: "none",
    },
  }),
  leftNavContent: css({
    marginTop: TOP_NAV_SPACING_WITH_SITE_CONTENT,
    overflow: "auto",
    height: "100%",
  }),
});

const IS_ELRPOM_ENV = getCurrentEnv() === "elprom";

interface LeftNavigationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  openLeftNav: boolean;
  setOpenLeftNav: React.Dispatch<React.SetStateAction<boolean>>;
  mobileLeftNav: boolean;
}

const LeftNavigation: React.FC<LeftNavigationProps> = ({
  className,
  openLeftNav,
  setOpenLeftNav,
  mobileLeftNav,
}) => {
  const styles = { ...cssStyles(openLeftNav) };
  const {
    NAV_GIANT_HOME,
    NAV_GIANT_PLANNING,
    NAV_GIANT_ANALYTICS,
    NAV_GIANT_OPEX,
    NAV_GIANT_QUALITY,
    NAV_GIANT_SUPPLY_CHAIN,
    NAV_GIANT_TOOLBOX,
  } = useTranslatedNav();
  const { NAV_GIANT_ELPROM } = useTranslatedElpromNav();

  const handleClose = () => {
    setOpenLeftNav(false);
    window.dispatchEvent(new Event("resize"));
  };

  return (
    <Drawer
      className={className}
      css={styles.drawer}
      anchor="left"
      open={openLeftNav}
      onClose={handleClose}
      variant={mobileLeftNav ? "temporary" : "persistent"}
    >
      <Box component="div" css={styles.leftNavContent}>
        {IS_ELRPOM_ENV ? (
          <LeftNavListMenu
            navList={NAV_GIANT_ELPROM.list}
            listTitle={NAV_GIANT_ELPROM.title}
          />
        ) : (
          <>
            <LeftNavListMenu navList={NAV_GIANT_HOME.list} listTitle="" />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_ANALYTICS.list}
              listTitle={NAV_GIANT_ANALYTICS.title}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_OPEX.list}
              listTitle={NAV_GIANT_OPEX.title}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_PLANNING.list}
              listTitle={NAV_GIANT_PLANNING.title}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_QUALITY.list}
              listTitle={NAV_GIANT_QUALITY.title}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_SUPPLY_CHAIN.list}
              listTitle={NAV_GIANT_SUPPLY_CHAIN.title}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_TOOLBOX.list}
              listTitle={NAV_GIANT_TOOLBOX.title}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default LeftNavigation;
