import { useEffect, useState } from "react";
import { IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GetQueryProjectsSnippet } from "../../Api/ProjectManagement/apiProjectSnippets";
import { getQueryProjects } from "../../Api/ProjectManagement/apiProjectGetQueriest";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import Modal from "../../Components/MaterialUI/Modal";
import PlanningProjectsForm from "../../Components/PageComponents/GanttChartProjects/PlanningProjectForm";
import { OpExProject } from "../../Api/ProjectManagement/apiProjectDataTypes";
import GanttChart from "../../Components/PageComponents/GanttChartProjects/GanttChart/GanttChart";
import { Task } from "gantt-task-react";
import { TaskType } from "gantt-task-react/dist/types/public-types";
import {
  deleteQueryProject,
  putQueryProject,
} from "../../Api/ProjectManagement/apiProjectPostQueries";
import PlanningTaskForm from "../../Components/PageComponents/GanttChartProjects/PlanningTaskForm";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import Button from "../../Components/MaterialUI/Button";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { GanttTask } from "../../Components/PageComponents/GanttChartProjects/GanttChart/ganttChartUtils";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";

type ProjectsPageModalType = "project" | "package" | "task" | "milestone" | undefined;

const TABLE_COLUMNS: TableGridColumnSchema[] = [
  { id: "name", label: "Project name", type: "string" },
  { id: "progress", label: "Project progress %", type: "number" },
  {
    id: "start",
    label: "Start date",
    type: "dateTime",
  },
  { id: "end", label: "End date", type: "dateTime" },
];

const ProjectsPage = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [rows, setRows] = useState<OpExProject[]>([]);
  const [ganttChartData, setGanttChartData] = useState<GanttTask[]>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ProjectsPageModalType>();
  const [ganttEditTaskModal, setGanttEditTaskModal] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const result = await callApi<GetQueryProjectsSnippet>({
          query: getQueryProjects(),
          auth: { setAuthedUser },
        });

        const rowsToUse = result.map((item) => {
          return {
            ...item.data,
            id: item.id,
          };
        });

        const ganttData: GanttTask[] = [];

        rowsToUse
          .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
          .forEach((item) => {
            const project: Task = {
              id: item.name,
              type: "project",
              name: item.name,
              start: new Date(item.start),
              end: new Date(item.end),
              progress: item.progress,
            };

            if (item.tasks.length > 0) {
              project.hideChildren = false;
            }

            ganttData.push(project);

            item.tasks.forEach((task) => {
              const ganttTask: Task = {
                id: task.type === "package" ? task.name : task.id,
                type: task.type === "package" ? "project" : (task.type as TaskType),
                name: task.name,
                start: new Date(task.start),
                end: task.end ? new Date(task.end) : new Date(),
                progress: task.progress,
                dependencies: task.parentID ? [task.parentID] : [],
                project: item.name,
              };

              if (task.type === "package") {
                ganttTask.hideChildren = false;
              }

              if (task.type === "milestone" || task.type === "task") {
                if (task.parentID) {
                  const parentTask = item.tasks.find((t) => t.id === task.parentID);
                  if (!parentTask) return;
                  ganttTask.project = parentTask.name;
                }
              }

              ganttData.push(ganttTask);
            });
          });

        setRows(rowsToUse);
        setGanttChartData(ganttData);
      } catch (err) {
        console.log("Projects useEffect err ", err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <CreateNewProjectMenu
          handleOpenModal={() => setOpenModal(true)}
          setModalType={setModalType}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const onGanttChartTaskEdit = async (task: Task) => {
    if (task) {
      setGanttChartData((prevTasks) => {
        return (prevTasks ?? []).map((prevTask) => {
          if (prevTask.id === task.id) {
            return task;
          }
          return prevTask;
        });
      });

      const selectedProject = rows.find((row) => row.name === task.project);
      if (selectedProject) {
        const selectedTask = selectedProject.tasks.find(
          (selectedTask) => selectedTask.name === task.name
        );
        if (selectedTask) {
          Object.assign(selectedTask, task);
        }
      }

      if (selectedProject) {
        await callApi({
          query: putQueryProject({ data: selectedProject }, selectedProject.id),
          auth: { setAuthedUser },
        });
      }
    }
  };

  const onGanttChartTaskDelete = async (task: Task) => {
    if (task) {
      if (task.type === "project" && !task.project) {
        try {
          const selectedProject = rows.find((row) => row.name === task.name);

          if (selectedProject) {
            await callApi({
              query: deleteQueryProject(selectedProject.id),
              auth: { setAuthedUser },
            });

            setRows((prevTasks) =>
              (prevTasks ?? []).filter((prevTask) => prevTask.id !== task.id)
            );
            setGanttChartData((prevTasks) =>
              (prevTasks ?? []).filter((prevTask) => {
                if (prevTask.id === task.id || prevTask.project === task.name) {
                  return false;
                }
                return true;
              })
            );
          }
        } catch (err) {
          console.log(err);
        }
      } else if (task.type === "task" || task.type === "milestone") {
        try {
          const selectedProject = rows.find((row) => row.name === task.project);

          if (selectedProject) {
            selectedProject.tasks = selectedProject.tasks.filter(
              (selectedTask) => selectedTask.name !== task.name
            );
            await callApi({
              query: putQueryProject({ data: selectedProject }, selectedProject.id),
              auth: { setAuthedUser },
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else if (task.type === "project" && task.project) {
        try {
          const selectedProject = rows.find((row) => row.name === task.project);

          if (selectedProject) {
            selectedProject.tasks = selectedProject.tasks.filter(
              (selectedTask) => selectedTask.name !== task.name
            );
            await callApi({
              query: putQueryProject({ data: selectedProject }, selectedProject.id),
              auth: { setAuthedUser },
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Stack>
      <Stack
        css={{
          backgroundColor: theme.palette.background.paper,
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography variant="h2" align="center" color="textSecondary">
            {t("My Projects")}
          </Typography>
          <Button onClick={() => setShowTable(!showTable)}>
            {showTable ? t("Gantt Chart Data") : t("Table Data")}
          </Button>
        </Stack>

        <Stack>
          {showTable ? (
            <ResponsiveTableGrid
              rows={rows}
              colSchema={useTranslateFields(TABLE_COLUMNS, ["label"])}
              editMode={false}
              responsive="responsive"
              configuration={{
                disableHeader: true,
                disablePagination: true,
                density: "compact",
              }}
            />
          ) : null}

          {!showTable ? (
            ganttChartData ? (
              ganttChartData.length > 0 ? (
                <GanttChart
                  ganttChartData={ganttChartData}
                  setGanttChartData={setGanttChartData}
                  showPeriodSwitcher={false}
                  isEditable={{
                    editTaskModal: ganttEditTaskModal,
                    setEditTaskModal: setGanttEditTaskModal,
                    onTaskEdit: onGanttChartTaskEdit,
                    onTaskDelete: onGanttChartTaskDelete,
                  }}
                />
              ) : (
                <Typography px={2}>{t("No Added Projects")}</Typography>
              )
            ) : (
              <Typography px={2}>{t("Loading...")}</Typography>
            )
          ) : null}
        </Stack>
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="sm"
        label={
          modalType === "project"
            ? t("Create Project")
            : modalType === "task"
            ? t("Create Task")
            : modalType === "package"
            ? t("Create Package")
            : modalType === "milestone"
            ? t("Create Milestone")
            : ""
        }
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "project" ? (
          <PlanningProjectsForm
            setProjectsData={setRows}
            setGanttChartData={setGanttChartData}
            setGanttEditTaskModal={setGanttEditTaskModal}
            setOpenModal={setOpenModal}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
        {modalType !== "project" ? (
          <PlanningTaskForm
            projectsData={rows}
            setProjectsData={setRows}
            setGanttChartData={setGanttChartData}
            setOpenModal={setOpenModal}
            modalType={modalType}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </Stack>
  );
};

export default ProjectsPage;

interface CreateNewProjectMenuProps {
  handleOpenModal: () => void;
  setModalType: React.Dispatch<
    React.SetStateAction<"project" | "package" | "task" | "milestone" | undefined>
  >;
}

const CreateNewProjectMenu: React.FC<CreateNewProjectMenuProps> = ({
  handleOpenModal,
  setModalType,
}) => {
  const { t } = useLanguageContext();
  return (
    <Stack direction="row" gap={1}>
      <Tooltip title={t("Add Project")}>
        <Stack alignItems="center">
          <IconButton
            onClick={() => {
              setModalType("project");
              handleOpenModal();
            }}
          >
            <AddOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textPrimary">
            {t("Project")}
          </Typography>
        </Stack>
      </Tooltip>

      <Tooltip title={t("Add Package")}>
        <Stack alignItems="center">
          <IconButton
            onClick={() => {
              setModalType("package");
              handleOpenModal();
            }}
          >
            <PlaylistAddOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textPrimary">
            {t("Package")}
          </Typography>
        </Stack>
      </Tooltip>

      <Tooltip title={t("Add Task")}>
        <Stack alignItems="center">
          <IconButton
            onClick={() => {
              setModalType("task");
              handleOpenModal();
            }}
          >
            <PostAddOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textPrimary">
            {t("Task")}
          </Typography>
        </Stack>
      </Tooltip>

      <Tooltip title={t("Add Milestone")}>
        <Stack alignItems="center">
          <IconButton
            onClick={() => {
              setModalType("milestone");
              handleOpenModal();
            }}
          >
            <FlagOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textPrimary">
            {t("Milestone")}
          </Typography>
        </Stack>
      </Tooltip>
    </Stack>
  );
};
