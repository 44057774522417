import {
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_FilterFn,
  MRT_Header,
  MRT_RowData,
  MRT_SortingFn,
  MRT_TableInstance,
} from "material-react-table";
import {
  TableGridColAlign,
  TableGridColSymbol,
  TableGridColumnDataTypes,
  TableGridHandleSaveCellChanges,
  tableGridDateFilterFunc,
} from "./tableGridUtils";
import {
  renderCellValue,
  TableGridCellComponent,
  TableGridEditComponent,
} from "./TableGridColumnComponents";
import { ReactNode } from "react";
import {
  formatDateAndTime,
  formatNumber,
  underscoreCaseToTitleCase,
} from "../../../Global/Utils/commonFunctions";
import { Stack, Tooltip, Typography } from "@mui/material";

export enum TableAggregationFns {
  sum = "sum",
  min = "min",
  max = "max",
  mean = "mean",
  median = "median",
  unique = "unique",
  uniqueCount = "uniqueCount",
  count = "count",
}
type AggregationKey = keyof typeof TableAggregationFns;
type FooterData = Record<AggregationKey, number>;
export type TableDynamicCondition = {
  operation: "less" | "more" | "equal" | "always";
  cellValue: number;
};

type StringPair = Record<string, any>;
export type TableGridColumnSchema = {
  id: string;
  label: string;
  type: TableGridColumnDataTypes;
  width?: number;
  marked?: boolean;
  markedId?: string;
  columns?: TableGridColumnSchema[];
  disableEditing?: boolean;
  bolded100?: boolean;
  formatNumb?: boolean;
  formatNumbDecimals?: number;
  aggregationFn?: Array<AggregationKey>;
  footerAggregations?: FooterData;
  colBgColor?: string;
  dynamicBgColor?: TableDynamicCondition & {
    bgColor: string;
    partialFillBasedOnVal?: boolean;
  };
  alignCol?: TableGridColAlign;
  symbol?: TableGridColSymbol;
};
type ColumnFilter = (props: {
  column: MRT_Column<MRT_RowData, any>;
  header: MRT_Header<any>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<any>;
}) => ReactNode;
type FilterVariant =
  | "text"
  | "checkbox"
  | "multi-select"
  | "range"
  | "range-slider"
  | "select"
  | undefined;

const constructTableGridColumns = <T extends StringPair>(
  schema: TableGridColumnSchema[],
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T>[] => {
  return schema.map((col) => {
    if (col.columns?.length) {
      return {
        id: col.id,
        header: col.label,
        columns: constructTableGridColumns(col.columns, handleSaveCellChanges),
        size: col.width,
      };
    }

    return getColumnCellData(col, handleSaveCellChanges);
  });
};

export default constructTableGridColumns;

const getColumnCellData = <T extends StringPair>(
  col: TableGridColumnSchema,
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T> => {
  let columnFilterModeOptions = undefined;
  let accessorFn: ((originalRow: StringPair) => any) | undefined = undefined;
  let sortingFn: MRT_SortingFn<StringPair> | undefined = undefined;
  let filterFn: MRT_FilterFn<StringPair> | undefined = undefined;
  let Filter: ColumnFilter | undefined = undefined;
  let enableGlobalFilter: boolean | undefined = undefined;
  let enableSorting: boolean | undefined = undefined;
  let filterVariant: FilterVariant = undefined;
  let aggregationFn = col.aggregationFn as unknown as string[] | undefined;

  switch (col.type) {
    case "string": {
      columnFilterModeOptions = [
        "fuzzy",
        "contains",
        "startsWith",
        "endsWith",
        "empty",
        "notEmpty",
      ];
      sortingFn = "alphanumeric";
      break;
    }
    case "number": {
      sortingFn = "alphanumeric";
      break;
    }
    case "boolean": {
      enableGlobalFilter = false;
      filterVariant = "checkbox";
      enableSorting = false;
      break;
    }
    case "date": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "date") : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      // Filter = ({ header }) => <TableGridDateTimeFilter header={header} type="date" />;
      break;
    }
    case "time": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "time") : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      // Filter = ({ header }) => <TableGridDateTimeFilter header={header} type="time" />;
      break;
    }
    case "dateTime": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id]) : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      // Filter = ((props) => (
      //   <TableGridDateTimeFilter header={props.header} type="date" />
      // )) as ColumnFilter;
      break;
    }
    case "button": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
    case "dropdown": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
  }

  const result: MRT_ColumnDef<Record<string, any>, unknown> = {
    accessorKey: col.id,
    header: col.label,
    Header: ({ column }) => (
      <Tooltip title={column.columnDef.header}>
        <Typography
          variant="body2"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {column.columnDef.header}
        </Typography>
      </Tooltip>
    ),
    meta: {
      type: col.type,
    },
    size: col.width,
    columnFilterModeOptions,
    aggregationFn: aggregationFn as any,
    AggregatedCell: aggregationFn?.length
      ? ({ cell }) => (
          <Stack spacing={1} direction="row">
            {aggregationFn?.map((item, index) => (
              <Typography variant="body2">
                {underscoreCaseToTitleCase(item)}:{" "}
                <Typography fontWeight="bold" variant="body2" component="span">
                  {renderCellValue(
                    formatNumber(
                      cell.getValue<Array<number>>()?.[index],
                      true,
                      2,
                      true
                    ) ?? "",
                    col.symbol
                  )}
                </Typography>
              </Typography>
            ))}
          </Stack>
        )
      : undefined,
    Cell: ({ cell, row }) => (
      <TableGridCellComponent
        cellValue={cell.getValue<string>()}
        type={col.type}
        marked={col.marked}
        markedId={col.markedId}
        rowData={row.original}
        bolded100={col.bolded100}
        formatNumb={col.formatNumb}
        formatNumbDecimals={col.formatNumbDecimals}
        alignCol={col.alignCol}
        symbol={col.symbol}
      />
    ),
    Edit: ({ cell }) => (
      <TableGridEditComponent
        rowIndex={cell.row.index}
        colKey={cell.column.id}
        colValue={cell.getValue()}
        type={col.type}
        handleSaveCellChanges={handleSaveCellChanges}
        disableEditing={col.disableEditing}
      />
    ),
    Footer:
      aggregationFn?.length && col.footerAggregations
        ? () => (
            <Stack
              spacing={1}
              direction="row"
              sx={{
                backgroundColor: col.colBgColor,
                width: "100%",
                height: "100%",
              }}
            >
              {aggregationFn?.map((item) => (
                <Typography variant="body2">
                  {underscoreCaseToTitleCase(item)}:{" "}
                  <Typography fontWeight="bold" variant="body2" component="span">
                    {renderCellValue(
                      col.footerAggregations![item as AggregationKey] ?? "",
                      col.symbol
                    )}
                  </Typography>
                </Typography>
              ))}
            </Stack>
          )
        : undefined,
    accessorFn,
    sortingFn,
    filterFn,
    Filter,
    filterVariant,
    enableGlobalFilter,
    enableSorting,
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        background: getCellBgColor(col, cell),
      },
    }),
  };

  return result as MRT_ColumnDef<T>;
};

// AGGREGATION FUNCTIONS ------------------------

// Function to calculate median
const calculateMedian = (numbers: number[]): number => {
  const sorted = [...numbers].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
};

// The main function
export const calcTableFooterAggregations = (
  aggregationFns: AggregationKey[],
  numbers: number[],
  decimalPoints?: number
): FooterData => {
  const footer: FooterData = {} as FooterData;

  aggregationFns.forEach((fn) => {
    switch (fn) {
      case TableAggregationFns.sum:
        const sum = numbers.reduce((acc, curr) => acc + curr, 0);
        footer[fn] = formatNumber(sum, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.min:
        const min = Math.min(...numbers);
        footer[fn] = formatNumber(min, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.max:
        const max = Math.max(...numbers);
        footer[fn] = formatNumber(max, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.mean:
        const mean = numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;
        footer[fn] = formatNumber(mean, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.median:
        const median = calculateMedian(numbers);
        footer[fn] = formatNumber(median, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.unique:
        footer[fn] = [...new Set(numbers)].length;
        break;
      case TableAggregationFns.uniqueCount:
        footer[fn] = [...new Set(numbers)].length;
        break;
      case TableAggregationFns.count:
        footer[fn] = numbers.length;
        break;
    }
  });

  return footer;
};

const getDynamicCondition = (condition: TableDynamicCondition, cellVal: any): boolean => {
  switch (condition.operation) {
    case "less": {
      return cellVal < condition.cellValue;
    }
    case "more": {
      return cellVal > condition.cellValue;
    }
    case "equal": {
      return cellVal === condition.cellValue;
    }
    case "always": {
      return true;
    }
  }
};

const getCellBgColor = (
  col: TableGridColumnSchema,
  cell: MRT_Cell<Record<string, any>, unknown>
): string | undefined => {
  const cellVal = cell.getValue();
  const hasDynamicColor =
    col.dynamicBgColor && getDynamicCondition(col.dynamicBgColor, cellVal);

  let dynamicColor: string | undefined = col.dynamicBgColor?.bgColor;
  let staticColor: string | undefined = col.colBgColor;

  if (hasDynamicColor && dynamicColor) {
    if (col.dynamicBgColor?.partialFillBasedOnVal) {
      const secondColor = staticColor || "transparent";
      const percentage = cellVal && +cellVal > 100 ? 100 : cellVal;
      return `linear-gradient(to right, ${dynamicColor} ${percentage}%, ${secondColor} ${percentage}%)`;
    }
    return dynamicColor;
  }

  return staticColor;
};
