import { Formik } from "formik";
import { Stack } from "@mui/material";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../../Global/Constants/yupConstants";
import { SerializedStyles } from "@emotion/react";
import { useState } from "react";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  PID_WORKFLOW_INITIAL_NODE_CONFIG,
  PidWorkflowsInitialNodeType,
} from "../Nodes/Initial/pidInitialNodeUtils";
import callApi from "../../../../../Api/callApi";
import ROUTES_MAPPING from "../../../../../Layout/Router/routesMapping";
import { useDetectFormsUnsavedChanges } from "../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import TextField from "../../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../../Components/MaterialUI/Button";
import Alert from "../../../../../Components/MaterialUI/Alert";
import {
  PostQueryPidWorkflowCreateInput,
  PutQueryPidWorkflowInput,
} from "../../../../../Api/PidWorkflows/apiPidWorkflowsInputs";
import {
  postQueryPidWorkflowCreate,
  putQueryPidWorkflow,
} from "../../../../../Api/PidWorkflows/apiPidWorkflowsPostQueries";
import { PID_FLOW_NODE_TYPE } from "../Nodes/pidNodesUtils";
import { PidWorkflowViewMode } from "../../pidWorkflowUtils";
import { PostQueryPidProcessCreateSnippet } from "../../../../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import { useLanguageContext } from "../../../../../context/LanguageContext";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  plantArea: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
  details: string;
  plantArea: string;
};

interface CreatePidWorkflowFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatePidWorkflowForm: React.FC<CreatePidWorkflowFormProps> = ({
  className,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const navigate = useNavigate();

  const initialValues: FormValues = {
    name: "",
    details: "",
    plantArea: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const initialNodeId = uuidv4().split("-")[0];

      const initialNode: PidWorkflowsInitialNodeType = {
        id: initialNodeId,
        data: {
          id: initialNodeId,
          name: values.name,
          createdOn: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
          type: PID_FLOW_NODE_TYPE.initial,
          label: values.name,
          mode: "View Mode" as PidWorkflowViewMode,
        },
        position: { x: 0, y: 1 },
        ...PID_WORKFLOW_INITIAL_NODE_CONFIG,
      };

      const INITIAL_FLOW = {
        id: uuidv4().split("-")[0],
        name: values.name,
        details: values.details,
        plantArea: values.plantArea || "",
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
        nodes: [initialNode],
        edges: [],
      };

      const input: PostQueryPidWorkflowCreateInput = {
        workflow: INITIAL_FLOW,
        data_fields: [],
      };

      const res: PostQueryPidProcessCreateSnippet = await callApi({
        query: postQueryPidWorkflowCreate(input),
        auth: { setAuthedUser },
      });

      // Update DB entry
      const putInput: PutQueryPidWorkflowInput = {
        workflow: { ...INITIAL_FLOW, id: res.id },
        data_fields: [],
      };
      await callApi({
        query: putQueryPidWorkflow(putInput, res.id),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setUnsavedChanges(false);
      navigate({
        pathname: `${ROUTES_MAPPING["P&ID Charts"]}/${res.id}`,
      });
    } catch (err) {
      console.log("CreatePidWorkflowForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label={t("Area")}
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
              <TextField
                name="plantArea"
                label={t("Name")}
                error={touched["plantArea"] && !!errors["plantArea"]}
                helperText={touched["plantArea"] && errors["plantArea"]}
                onChange={handleChange}
                value={values.plantArea}
              />
              <TextField
                name="details"
                label={t("Description")}
                error={touched["details"] && !!errors["details"]}
                helperText={touched["details"] && errors["details"]}
                onChange={handleChange}
                value={values.details}
                multiline
                minRows={2}
              />

              <Button type="submit" loading={formStatus === "loading"}>
                {t("Create New P&ID")}
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreatePidWorkflowForm;
