import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  SelectOption,
  AutocompleteGroupedOption,
} from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceParamMapping,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  IndicatorDataSchema,
  IndicatorParameter,
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { startOfMonth } from "date-fns";
import Alert from "../../MaterialUI/Alert";
import Select from "../../MaterialUI/FormFields/Select";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLanguageContext } from "../../../context/LanguageContext";
import { positiveTrendSelectOptions } from "./indicatorWidgetUtils";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});
type ParamRow = AutocompleteGroupedOption | null;
const EMPTY_PARAM_ROW: ParamRow = null;

interface EditIndicatorWidgetDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: IndicatorDataSchema | null;
  handleUpdateDataSchema: (schema: IndicatorDataSchema | null) => void;
  config: ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<IndicatorWidgetConfiguration>>
  >;
  selectedConnections: SelectOption[];
  setSelectedConnections: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
}

const EditIndicatorWidgetDataSchema: React.FC<EditIndicatorWidgetDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  config,
  setUpdatedConfig,
  selectedConnections,
  setSelectedConnections,
  graphqlFilters,
  setGraphqlFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeNumbOptions, setNodeNumbOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);
  // selected values
  const [selectedThreshold, setSelectedThreshold] = useState<string | number>("");
  const [selectedParam, setSelectedParam] = useState<ParamRow>(EMPTY_PARAM_ROW);

  // system-used
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [connectionsSet, setConnectionsSet] = useState<boolean>(false);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionsSet: Set<string> = new Set();
      const param: AutocompleteGroupedOption = {
        value: dataSchema.parameter.parameterId,
        description:
          paramMapping?.[dataSchema.parameter.parameterId].paramName ||
          dataSchema.parameter.parameterId,
        groupName: dataSchema.parameter.container,
      };
      const threshold = dataSchema.threshold;
      selectedConnectionsSet.add(dataSchema.parameter.container);

      const selectedConnectionsArr: SelectOption[] = Array.from(
        selectedConnectionsSet
      ).map((item) => {
        const conn = Object.values(paramMapping).find(
          (param) => param.connection === item
        );
        const connName = conn?.connectionName || item;

        return {
          value: item,
          description: connName,
        };
      });

      setSelectedConnections(selectedConnectionsArr);
      setSelectedThreshold(threshold);
      setSelectedParam(param);
      setConnectionsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);

    const mapping: ExcellenceParamMapping = {};
    parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });

    setConnectionOptions(allConnectionOptions);
    setParamMapping(mapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (paramMapping) {
      const paramOptions = handleGetParamOptions(parameters, selectedConnections);
      const numbOnlyOptions = paramOptions.filter(
        (node) =>
          paramMapping?.[node.value]?.type && paramMapping[node.value].type === "number"
      );

      setNodeNumbOptions(numbOnlyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnections, paramMapping]);

  useEffect(() => {
    if (connectionsSet && !selectedConnections.length) {
      handleUpdateDataSchema(null);
    } else {
      const param: IndicatorParameter | null =
        selectedParam && paramMapping
          ? {
              container: paramMapping[selectedParam.value].connection,
              parameterId: selectedParam.value,
            }
          : null;

      if (selectedThreshold && param) {
        if (+selectedThreshold > 100 || +selectedThreshold < 0) {
          return;
        }
        const startTime = dataSchema?.startTime || startOfMonth(new Date()).toISOString();
        const endTime = dataSchema?.endTime || new Date().toISOString();
        const updatedSchema: IndicatorDataSchema = {
          threshold: +selectedThreshold,
          parameter: param,
          startTime,
          endTime,
        };
        handleUpdateDataSchema(updatedSchema);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const handleOnConnectionChange = (val: SelectOption[]) => {
    setAlertMessage(null);
    setSelectedConnections(val || []);
    setReUpdateSchema((prev) => !prev);
  };

  const handleOnThresholdChange = (value: string) => {
    setAlertMessage(null);
    const val = value;
    if (+val < 0 || +val > 100) {
      setAlertMessage(t("Trend Deviation must be between 0 and 100"));
    }
    setSelectedThreshold(value);
  };

  const handleOnThresholdBlur = (value: string) => {
    const val = value ? +value : null;
    if ((val && val < 0) || (val && val > 100)) {
      return;
    }
    setReUpdateSchema((prev) => !prev);
    setUpdatedConfig((prev) => ({
      ...prev,
      deviation: val,
    }));
  };

  const handleOnParamChange = (val: AutocompleteGroupedOption) => {
    const newParam = val?.value
      ? {
          ...val,
        }
      : null;

    setGraphqlFilters((prev) =>
      prev.filter((item) => item.paramID === newParam?.value || "")
    );
    setSelectedParam(newParam);
    setReUpdateSchema((prev) => !prev);
  };

  const handleOnTrendChange = (val: string) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      positiveDirection: val as any,
      targetValue: prev.targetValue === undefined ? 0 : prev.targetValue,
    }));
  };

  const handleOnTargetChange = (val: string) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      targetValue: val as any,
    }));
  };

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Autocomplete
        css={styles.width100}
        label={t("Selected Connections")}
        multiple
        options={connectionOptions}
        value={selectedConnections}
        handleOnChange={handleOnConnectionChange}
      />

      {selectedConnections.length ? (
        <Grid style={{ margin: 0 }} container spacing={3}>
          <Grid style={{ paddingLeft: theme.spacing(1.5) }} item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <AutocompleteGrouped
                css={styles.width100}
                label={t("Parameter")}
                options={nodeNumbOptions}
                value={selectedParam}
                handleOnChange={handleOnParamChange}
              />
              <Tooltip title={t("Only parameters with numeric values are available")}>
                <InfoOutlinedIcon style={{ color: theme.palette.text.secondary }} />
              </Tooltip>
            </Stack>

            <Box css={styles.width100} component="div">
              <GraphqlFilters
                data={handleExcellenceParamToGraphqlFilters(parameters)}
                graphqlFilters={graphqlFilters}
                setGraphqlFilters={setGraphqlFilters}
                selectedParams={[selectedParam?.value || ""]}
              />
            </Box>
          </Grid>

          <Grid style={{ paddingLeft: theme.spacing(1.5) }} item xs={4}>
            <Select
              selectOptions={positiveTrendSelectOptions}
              defaultValue=""
              label={t("Positive Trend")}
              onChange={(e) => handleOnTrendChange(e.target.value)}
              value={config.positiveDirection}
              fullWidth
            />
          </Grid>
          <Grid item xs={config.positiveDirection === "target" ? 4 : 5}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                fullWidth
                label={t("Trend Deviation")}
                value={config.deviation}
                onChange={(e) => handleOnThresholdChange(e.target.value)}
                onBlur={(e) => handleOnThresholdBlur(e.target.value)}
                numberField
                noThousandSeparator
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />

              {config.positiveDirection === "target" ? null : (
                <Tooltip
                  title={t(
                    "The deviation determines the maximal relative change the current value can have compared to the value of the previous period in order to be on target"
                  )}
                >
                  <InfoOutlinedIcon style={{ color: theme.palette.text.secondary }} />
                </Tooltip>
              )}
            </Stack>
          </Grid>

          {config.positiveDirection === "target" ? (
            <Grid style={{ paddingRight: theme.spacing(1.5) }} item xs={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  label={t("Target")}
                  onChange={(e) => handleOnTargetChange(e.target.value)}
                  value={config.targetValue}
                  numberField
                />

                <Tooltip
                  title={t(
                    "The deviation determines the maximal relative change the current value can have compared to the value of the previous period in order to be on target"
                  )}
                >
                  <InfoOutlinedIcon style={{ color: theme.palette.text.secondary }} />
                </Tooltip>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}></Grid>
          )}
        </Grid>
      ) : null}

      <Alert
        css={[styles.reverseContentBreak, styles.contentBreak]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={"warning"}
      />
    </Stack>
  );
};

export default EditIndicatorWidgetDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnectionsArr: SelectOption[]
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        selectedConnectionsArr.some((sub) => sub.value === node.groupName)
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
