import { Box, IconButton, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Button from "../../Components/MaterialUI/Button";
import { useEffect, useState } from "react";
import Modal from "../../Components/MaterialUI/Modal";
import CreateCustomLayoutForm from "../../Components/Forms/DynamicGridLayoutForms/CreateDynamicGridLayoutForm";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useAuthedContext } from "../../context/AuthContext";
import {
  GetQueryOperationsFormsSnippet,
  PostQueryOperationsFormSnippet,
} from "../../Api/Operations/apiOperationsSnippets";
import callApi from "../../Api/callApi";
import { getQueryOperationsForms } from "../../Api/Operations/apiOperationsGetQueries";
import { PostQueryOperationsFormInput } from "../../Api/Operations/apiOperationsInputs";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import { postQueryOperationsForm } from "../../Api/Operations/apiOperationsPostQueries";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import TextField from "../../Components/MaterialUI/FormFields/TextFields";
import BasicTable from "../../Components/MaterialUI/BasicTable/BasicTable";
import {
  BasicTableColumnCell,
  BasicTableRow,
} from "../../Components/MaterialUI/BasicTable/basicTableUtils";
import { staticForms } from "../../Components/PageComponents/Operations/MyForms/staticFormsUtils";
import { useLanguageContext } from "../../context/LanguageContext";

const MyFormsPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [forms, setForms] = useState<BasicTableRow[]>([]);
  const [open, setOpen] = useState(false);

  const [displayedForms, setDisplayedForms] = useState<BasicTableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const translatedStaticForms = staticForms.map((form) => ({
    ...form,
    name: t(form.name as string),
    type: t(form.type as string),
  }));

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage(t("Loading..."));
        const data = await callApi<GetQueryOperationsFormsSnippet>({
          query: getQueryOperationsForms(),
          auth: { setAuthedUser },
        });

        const rows: BasicTableRow[] = data.map((item) => ({
          id: item.id,
          name: item.name,
          type: t("Dynamic"),
        }));

        setForms(rows);
        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<CreateNewFormMenu handleOpenModal={handleOpenModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    const combinedForms = [...forms, ...translatedStaticForms];
    setDisplayedForms(combinedForms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  const TABLE_COLUMNS: BasicTableColumnCell[] = [
    { id: "name", label: t("Form Name") },
    { id: "type", label: t("Form Type") },
  ];

  const handleCreateNewForm = async (name: string) => {
    try {
      const input: PostQueryOperationsFormInput = {
        name: name,
      };

      const data = await callApi<PostQueryOperationsFormSnippet>({
        query: postQueryOperationsForm(input),
        auth: { setAuthedUser },
      });
      setUnsavedChanges(false);
      return `${data.id}`;
    } catch (err) {
      console.log("MyOperationsPage err: ", err);
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const combinedForms = [...forms, ...translatedStaticForms];
    if (!val) {
      setDisplayedForms(combinedForms);
    } else {
      setDisplayedForms(() =>
        combinedForms.filter((item) =>
          `${item.name}`.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
    setSearchQuery(val);
  };

  const handleOnClick = {
    handleRowLink: (id?: string) => {
      return `/my-forms/${id}`;
    },
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box component="div">
      <Typography
        css={styles.sectionBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        {t("My Forms")}
      </Typography>
      {smMediaQuery ? (
        <Button css={styles.sectionBreak} onClick={handleOpenModal}>
          {t("Create New Form")}
        </Button>
      ) : null}
      <Alert
        css={[styles.width100, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      {forms.length ? (
        <Stack>
          <ContentBox css={[styles.height100, styles.contentBreak]}>
            <TextField
              label={t("Search")}
              value={searchQuery}
              onChange={handleOnSearch}
            />
          </ContentBox>
        </Stack>
      ) : null}

      <BasicTable
        css={styles.sectionBreak}
        data={{ rows: displayedForms, columns: TABLE_COLUMNS }}
        defaultOrderBy="name"
        isLoading={pageStatus === "loading"}
        dense
        emptyTableMessage={
          searchQuery
            ? t("No results found. Try searching for something else.")
            : t("You haven't created any forms yet.")
        }
        handleOnClick={handleOnClick}
      />

      <Modal
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        label={t("Create New Form")}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <CreateCustomLayoutForm
          category="operations"
          handleOnSubmit={handleCreateNewForm}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
    </Box>
  );
};

export default MyFormsPage;

interface CreateNewFormMenuProps {
  handleOpenModal: () => void;
}

const CreateNewFormMenu: React.FC<CreateNewFormMenuProps> = ({ handleOpenModal }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <IconButton aria-label="create new form" onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        {t("Add")}
      </Typography>
    </Stack>
  );
};
