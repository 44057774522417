import { IconButton, Stack } from "@mui/material";
import { OrderManagementTableRow, StatusStages } from "./ordersUtils";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../MaterialUI/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";

const colSchema: TableGridColumnSchema[] = [
  { id: "order_number", label: "Order Number", type: "string", width: 200 },
  { id: "sample_name", label: "Sample Name", type: "string", width: 200 },
  {
    id: "contact_person_development",
    label: "Contact Person Development",
    type: "string",
    width: 200,
  },
  { id: "contact_person_ppm", label: "Contact Person PPM", type: "string", width: 200 },
  { id: "quantity_panels", label: "Quantity Panels", type: "number", width: 220 },
  {
    id: "me_sampling_needed_panels",
    label: "ME Sampling Needed Panels",
    type: "number",
    width: 200,
  },
  { id: "quantity_pcbs", label: "Quantity PCBs", type: "number", width: 200 },
  { id: "business_unit", label: "Business Unit", type: "string", width: 200 },
  { id: "preferred_date", label: "Preferred Date", type: "string", width: 200 },
  {
    id: "material_availability_date",
    label: "Material Availability Date",
    type: "string",
    width: 200,
  },
  { id: "cost_center", label: "Cost Center", type: "string", width: 200 },
  { id: "sap_order_number", label: "SAP Order Number", type: "number", width: 200 },
  { id: "status", label: "Status", type: "string", width: 200 },
  { id: "panels", label: "Panels", type: "string", width: 200 },
  { id: "assembly_board", label: "Assembly board", type: "string", width: 200 },
  {
    id: "number_of_board_bare_pcb",
    label: "Number of board (bare PCB)",
    type: "string",
    width: 200,
  },
  {
    id: "number_of_board_bare_panel",
    label: "Number of board (bare Panel)",
    type: "string",
    width: 200,
  },
  { id: "windchill_link", label: "Windchill Link", type: "string", width: 200 },
  { id: "order_new_stencil", label: "Order New Stencil", type: "string", width: 200 },
  { id: "stencil_order_speed", label: "Stencil Order Speed", type: "string", width: 200 },
  { id: "spi_report", label: "SPI Report", type: "string", width: 200 },
  {
    id: "thermoprofile_measurement",
    label: "Thermoprofile Measurement",
    type: "string",
    width: 200,
  },
  { id: "aoi", label: "AOI", type: "string", width: 200 },
  { id: "coverage_report", label: "Coverage Report", type: "string", width: 200 },
  { id: "spc_report", label: "SPC Report", type: "string", width: 200 },
  {
    id: "cross_section_analisys",
    label: "Cross Section Analisys",
    type: "string",
    width: 200,
  },
  { id: "actions", label: "Actions", type: "button", width: 150 },
];

interface OrderManagementTableProps {
  orderTableRows: OrderManagementTableRow[];
  loading: boolean;
}

const OrderManagementTable: React.FC<OrderManagementTableProps> = ({
  orderTableRows,
  loading,
}) => {
  const { t } = useLanguageContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);

  return (
    <>
      <ResponsiveTableGrid
        rows={handleFilterData(setOpenInfo, setModalData, orderTableRows)}
        colSchema={useTranslateFields(colSchema, ["label"])}
        editMode={false}
        responsive="responsive"
        loading={loading}
        configuration={{
          density: "compact",
          columnVisibility: {
            contact_person_development: false,
            me_sampling_needed_panels: false,
            contact_person_ppm: false,
            quantity_pcbs: false,
            business_unit: false,
            material_availability_date: false,
            cost_center: false,
            panels: false,
            assembly_board: false,
            number_of_board_bare_pcb: false,
            number_of_board_bare_panel: false,
            windchill_link: false,
            order_new_stencil: false,
            stencil_order_speed: false,
            spi_report: false,
            thermoprofile_measurement: false,
            aoi: false,
            coverage_report: false,
            spc_report: false,
            cross_section_analisys: false,
          },
        }}
      />

      <Modal
        open={openInfo}
        fullWidth
        label={t("Information")}
        onClose={() => setOpenInfo(false)}
      >
        {modalData && modalData.type === "metaData" ? (
          <ResponsiveTableGrid
            rows={handleFilterData(setOpenInfo, setModalData, [modalData.data])}
            colSchema={useTranslateFields(colSchema, ["label"]).filter(
              (col) => col.id !== "actions"
            )}
            editMode={false}
            responsive="mobile"
            configuration={{
              density: "compact",
              hideMobilePagination: true,
              hideMobileSearch: true,
            }}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OrderManagementTable;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  tableRows: OrderManagementTableRow[] | undefined
) => {
  const { t } = useLanguageContext();

  const statusDisplayMap: { [key in StatusStages]: string } = {
    in_progress: "IP",
    finished: "Y",
    planned: "N",
    pending: t("Pending"),
  };

  if (!tableRows) return [];

  return tableRows.map((order) => {
    return {
      order_number: order.order_number,
      sample_name: order.sample_name,
      contact_person_development: order.contact_person_development,
      contact_person_ppm: order.contact_person_ppm,
      quantity_panels: order.quantity_panels,
      me_sampling_needed_panels: order.me_sampling_needed_panels,
      quantity_pcbs: order.quantity_pcbs,
      business_unit: order.business_unit,
      preferred_date: order.preferred_date,
      material_availability_date: order.material_availability_date,
      cost_center: order.cost_center,
      panels: order.panels,
      assembly_board: order.assembly_board,
      number_of_board_bare_pcb: order.number_of_board_bare_pcb,
      number_of_board_bare_panel: order.number_of_board_bare_panel,
      windchill_link: order.windchill_link,
      sap_order_number: order.sap_order_number,
      status: statusDisplayMap[order.status as StatusStages],
      order_new_stencil: order.order_new_stencil,
      stencil_order_speed: order.stencil_order_speed,
      spi_report: order.spi_report,
      thermoprofile_measurement: order.thermoprofile_measurement,
      aoi: order.aoi,
      coverage_report: order.coverage_report,
      spc_report: order.spc_report,
      cross_section_analisys: order.cross_section_analisys,
      actions: (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          {order.status === "pending" ? (
            <IconButton>
              <CheckCircleOutlineIcon />
            </IconButton>
          ) : null}
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "metaData", data: order });
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Stack>
      ),
    };
  });
};
