import { Box, Stack, Typography, useTheme } from "@mui/material";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { getQueryKanOrders, KanOrder } from "./ordersPage3Utils";
import { Task, ViewMode } from "gantt-task-react";
import GanttChart from "../../Components/PageComponents/GanttChartProjects/GanttChart/GanttChart";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Alert from "../../Components/MaterialUI/Alert";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../context/LanguageContext";

type GanttTask = Task & {
  orderData: KanOrder;
};

const OrdersPage3: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [allOrders, setAllOrders] = useState<GanttTask[]>([]);
  const [pageStatus, setaPageStatus] = useState<FormStatuses>("loading");
  const [pageAlert, setPageAlert] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setPageAlert(t("Fetching data..."));
        setaPageStatus("loading");

        const orders = await callApi<KanOrder[]>({
          query: getQueryKanOrders(),
          auth: { setAuthedUser },
        });
        const ganttData: GanttTask[] = orders.map((item) => {
          return {
            id: item.id,
            type: "task",
            name: item.sample_name,
            start: new Date(item.start_date),
            end: new Date(item.end_date),
            progress: item.progress,
            orderData: item,
          };
        });

        setAllOrders(ganttData);
        setPageAlert(null);
        setaPageStatus(null);
      } catch (err) {
        setPageAlert(t("Something went wrong"));
        setaPageStatus("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div">
      <Stack spacing={3} alignItems="center" justifyContent="center">
        <Typography css={styles.contentBreak} textAlign="center" variant="h2">
          {t("Orders")}
        </Typography>
        <Alert message={pageAlert || ""} showAlert={!!pageAlert} severity={pageStatus} />
      </Stack>

      {pageStatus !== "loading" ? (
        <>
          {allOrders.length ? (
            <GanttChart
              ganttChartData={allOrders}
              TooltipContent={({ task }) => <TooltipData data={task.orderData} />}
              initialViewMode={ViewMode.Month}
              showPeriodSwitcher
            />
          ) : (
            <Typography variant="h3" textAlign="center">
              {t("You don't have any orders yet")}
            </Typography>
          )}
        </>
      ) : null}
    </Box>
  );
};

export default OrdersPage3;

interface TooltipDataProps {
  data: KanOrder;
}

const TooltipData: React.FC<TooltipDataProps> = ({ data }) => {
  const { t } = useLanguageContext();

  return (
    <Stack spacing={1}>
      {data.name ? (
        <LabelWithBoldedPart variant="body2" text={t("Name")} bolded={data.name} />
      ) : null}
      {data.business_unit ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Business Unit")}
          bolded={data.business_unit}
        />
      ) : null}
      {data.contact_person_development ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Contact Person Development")}
          bolded={data.contact_person_development}
        />
      ) : null}
      {data.contact_person_ppm ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Contact Person PPM")}
          bolded={data.contact_person_ppm}
        />
      ) : null}
      {data.cost_center ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Cost Center")}
          bolded={data.cost_center}
        />
      ) : null}
      <LabelWithBoldedPart variant="body2" text="id" bolded={data.id} />
      {data.me_sampling_needed_panels ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("ME Sampling Needed Panels")}
          bolded={data.me_sampling_needed_panels}
        />
      ) : null}
      {data.quantity_panels ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Quantity Panels")}
          bolded={data.quantity_panels}
        />
      ) : null}
      {data.quantity_pcbs ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Quantity PCBS")}
          bolded={data.quantity_pcbs}
        />
      ) : null}
      {data.sap_order_number ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("SAP Order Number")}
          bolded={data.sap_order_number}
        />
      ) : null}
      {data.status ? (
        <LabelWithBoldedPart variant="body2" text={t("Status")} bolded={data.status} />
      ) : null}
      {data.number_of_board_bare_panel ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Number of Board Bare Panel")}
          bolded={data.number_of_board_bare_panel}
        />
      ) : null}
      {data.number_of_board_bare_pcb ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Number of Board Bare PCB")}
          bolded={data.number_of_board_bare_pcb}
        />
      ) : null}
      {data.assembly_board ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Assembly Board")}
          bolded={data.assembly_board}
        />
      ) : null}
      {data.order_number ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Order Number")}
          bolded={data.order_number}
        />
      ) : null}
      {data.panels ? (
        <LabelWithBoldedPart variant="body2" text={t("Panels")} bolded={data.panels} />
      ) : null}
      {data.windchill_link ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Windchill Link")}
          bolded={data.windchill_link}
        />
      ) : null}

      {data.preferred_date ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Preferred Date")}
          bolded={formatDateAndTime(new Date(data.preferred_date))}
        />
      ) : null}
      {data.start_date ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Start Date")}
          bolded={formatDateAndTime(new Date(data.start_date))}
        />
      ) : null}
      {data.end_date ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("End Date")}
          bolded={formatDateAndTime(new Date(data.end_date))}
        />
      ) : null}
      {data.finished_date ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Finished Date")}
          bolded={formatDateAndTime(new Date(data.finished_date))}
        />
      ) : null}
      {data.material_availability_date ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Material Availability Date")}
          bolded={formatDateAndTime(new Date(data.material_availability_date))}
        />
      ) : null}
      {data.placed_at ? (
        <LabelWithBoldedPart
          variant="body2"
          text={t("Placed at")}
          bolded={formatDateAndTime(new Date(data.placed_at))}
        />
      ) : null}
    </Stack>
  );
};
