import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { getQueryElpromKsoClientOrder } from "./API/apiElpromGetQueries";
import Alert from "../../Components/MaterialUI/Alert";
import cssFontStyles from "../../Global/Styles/font";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import { ElpromTableData, ElpromTableDataCols } from "./API/apiElpromSnippets";
import {
  calcTableFooterAggregations,
  TableAggregationFns,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useLanguageContext } from "../../context/LanguageContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";

type TableData = {
  cols: TableGridColumnSchema[];
  rows: Record<string, any>[];
};

const ElpromKsoClientOrderPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [data, setData] = useState<TableData | null>(null);

  const { id } = useParams();
  const { setAuthedUser } = useAuthedContext();
  const { language } = useLanguageContext();
  const navigate = useNavigate();
  const { t } = useLanguageContext();

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        setPageStatus("loading");
        const limit = 5000;
        const offset = 0;
        const language_code = language as any;

        const result = await callApi<ElpromTableData>({
          query: getQueryElpromKsoClientOrder(id, limit, offset, language_code),
          auth: { setAuthedUser },
        });

        setData({
          cols: handleColsData(result.columns, result.rows),
          rows: result.rows,
        });
        setPageStatus(null);
        setAlertMessage(null);
      } catch (err) {
        console.log("err ", err);
        setPageStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box component="div">
      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        mb={3}
      >
        <div />
        <Typography css={styles.contentBreak} variant="h2" textAlign="center">
          {t("Not Distributed for Client Order")}
        </Typography>
        <IconButton onClick={() => navigate(`${ROUTES_MAPPING["projectHubData"]}`)}>
          <KeyboardBackspaceIcon />
        </IconButton>
      </Stack>

      <Alert
        css={[styles.widthLimit20, styles.contentBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <LoadingBackdrop loading={pageStatus === "loading"} />

      {data ? (
        <ResponsiveTableGrid
          rows={data.rows}
          colSchema={data.cols}
          responsive="responsive"
          configuration={{
            density: "compact",
            initialRowsPerPage: 15,
            columnVisibility: { id: false },
            enableStickyHeader: true,
            maxTableHeight: 700,
          }}
        />
      ) : null}
    </Box>
  );
};

export default ElpromKsoClientOrderPage;

const aggregations = [TableAggregationFns.sum];
const handleColsData = (
  cols: ElpromTableDataCols[],
  rows: Record<string, any>[]
): TableGridColumnSchema[] => {
  const result: TableGridColumnSchema[] = cols.map((item) => {
    const isNumb = item.type === "number";

    return {
      id: item.id,
      label: item.name,
      type: item.type,
      formatNumb: true,
      aggregationFn: isNumb ? aggregations : undefined,
      footerAggregations: isNumb
        ? calcTableFooterAggregations(
            aggregations,
            rows.map((row) => row[item.id])
          )
        : undefined,
    };
  });

  return result as TableGridColumnSchema[];
};
