import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { AppRouterProps } from "../../Layout/layoutVariables";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Modal from "../../Components/MaterialUI/Modal";
import {
  ModalTitle,
  getOEEMainCategories,
  oEEModalTitleTranslations,
} from "../../Components/PageComponents/OEE/oEEUtils";
import callApi from "../../Api/callApi";
import {
  GetQueryOEEMainCategoriesSnippet,
  GetQueryOEEMappedCategoriesWithSubcategoriesSnippet,
} from "../../Api/OEE/apiOEESnippets";
import {
  getQueryOEEMainCategories,
  getQueryOEEMappedCategoriesWithSubcategories,
} from "../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../context/AuthContext";
import { AutocompleteOption } from "../../Global/Types/commonTypes";
import OEEAddSubcategoryForm from "../../Components/PageComponents/OEE/OEEAddSubcategoryForm";
import OEESubcategoriesTable from "../../Components/PageComponents/OEE/OEESubcategoriesTable";
import { useTranslatedModalTitle } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";

const OEECategoriesPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const getTranslatedModalTitle = useTranslatedModalTitle(oEEModalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [oEEMainCategoriesOptions, setOEEMainCategoriesOptions] = useState<
    AutocompleteOption[]
  >([]);
  const [oEEMappedCategoriesWithSubcategories, setOEEMappedCategoriesWithSubcategories] =
    useState<GetQueryOEEMappedCategoriesWithSubcategoriesSnippet>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const fetchOEEData = async () => {
    try {
      const mainCategories = await callApi<GetQueryOEEMainCategoriesSnippet>({
        query: getQueryOEEMainCategories,
        auth: { setAuthedUser },
      });
      setOEEMainCategoriesOptions(getOEEMainCategories(mainCategories));

      const categoriesWithSubcategories =
        await callApi<GetQueryOEEMappedCategoriesWithSubcategoriesSnippet>({
          query: getQueryOEEMappedCategoriesWithSubcategories,
          auth: { setAuthedUser },
        });
      setOEEMappedCategoriesWithSubcategories(categoriesWithSubcategories);
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<CreateNewOEEAsset setModalTitle={setModalTitle} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        OEE {t("Categories")}
      </Typography>

      <Stack spacing={2}>
        <OEESubcategoriesTable
          oEEMappedCategoriesWithSubcategories={oEEMappedCategoriesWithSubcategories}
          fetchOEEData={fetchOEEData}
          oEEMainCategoriesOptions={oEEMainCategoriesOptions}
          setModalTitle={setModalTitle}
        />
      </Stack>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalTitle === "Add Subcategory" ? (
          <OEEAddSubcategoryForm
            oEEMainCategoriesOptions={oEEMainCategoriesOptions}
            setModalTitle={setModalTitle}
            fetchOEEData={fetchOEEData}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default OEECategoriesPage;

interface CreateNewOEEAssetProps {
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle | null>>;
}

const CreateNewOEEAsset: React.FC<CreateNewOEEAssetProps> = ({ setModalTitle }) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <IconButton
        aria-label="create new subcategory"
        onClick={() => setModalTitle("Add Subcategory")}
      >
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        {t("Add")}
      </Typography>
    </Stack>
  );
};
