import { Menu, MenuItem, Typography } from "@mui/material";
import Button from "../../MaterialUI/Button";
import { useEffect, useState } from "react";
import { useAuthedContext } from "../../../context/AuthContext";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import callApi from "../../../Api/callApi";
import {
  postQueryHelpCreateSelection,
  postQueryHelpUploadPageFile,
  updateQueryHelpSelection,
} from "../../../Api/Help/apiHelpPostQueries";
import { PostQueryHelpSelectionInput } from "../../../Api/Help/apiHelpInputs";
import { HelpSelection } from "../../../Api/Help/apiHelpSnippets";
import { ModalHelpTitle, PageKey } from "./helpUtils";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { FileWithPath } from "react-dropzone";
import FileDropzone from "../../SmallComponents/Dropzone/FileDropzone";
import { useLanguageContext } from "../../../context/LanguageContext";
import { FormStatuses } from "../../../Global/Types/commonTypes";

interface UploadHelpFileProps {
  currentPage: PageKey | undefined;
  order: number;
  fetchHelpContent: () => void;
  uploadType: "video" | "image" | null;
  setUploadType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;
  helpSelectionId?: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setAlertStatus: React.Dispatch<React.SetStateAction<FormStatuses | null>>;
  setOpenInfo?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalTitle?: React.Dispatch<React.SetStateAction<ModalHelpTitle | null>>;
}

const UploadHelpFile: React.FC<UploadHelpFileProps> = ({
  currentPage,
  order,
  fetchHelpContent,
  uploadType,
  setUploadType,
  helpSelectionId,
  setAlertMessage,
  setAlertStatus,
  setOpenInfo,
  setModalTitle,
}) => {
  const { t } = useLanguageContext();
  const styles = {
    ...cssLayoutStyles,
  };
  const { setAuthedUser } = useAuthedContext();
  const [selectedFile, setSelectedFile] = useState<FileWithPath | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleFileUpload = async () => {
    if (setOpenInfo && setModalTitle) {
      setOpenInfo(false);
      setModalTitle(null);
    }

    if (!selectedFile || !uploadType || !currentPage) return;

    try {
      setAlertMessage(t("Uploading..."));
      setAlertStatus("loading");

      const fileUrl = await callApi<string>({
        query: postQueryHelpUploadPageFile(currentPage.id, selectedFile),
        auth: { setAuthedUser },
      });

      const input: PostQueryHelpSelectionInput = {
        order: order,
        content: fileUrl,
        type: uploadType,
      };

      if (helpSelectionId) {
        await callApi<HelpSelection>({
          query: updateQueryHelpSelection(helpSelectionId, input),
          auth: { setAuthedUser },
        });
      } else {
        await callApi<HelpSelection>({
          query: postQueryHelpCreateSelection(currentPage.id, input),
          auth: { setAuthedUser },
        });
      }

      setAlertMessage(t("Successfully uploaded"));
      setAlertStatus("success");
      fetchHelpContent();
    } catch (error) {
      console.error("Failed to upload file:", error);
      setAlertMessage(t("Failed to upload file"));
      setAlertStatus("error");
    }
  };

  const handleUploadTypeChange = (type: "video" | "image") => {
    setUploadType(type);
    setSelectedFile(null);
    setMenuAnchor(null);
  };

  const renderDropzoneMenuItem = (
    label: string,
    icon: JSX.Element,
    fileType: "video" | "image"
  ) => (
    <FileDropzone
      setFiles={(files) => setSelectedFile(files[0])}
      setErrorMessage={setAlertMessage}
      acceptVideos={fileType === "video"}
      acceptImages={fileType === "image"}
    >
      <MenuItem onClick={() => handleUploadTypeChange(fileType)}>
        {icon}
        <Typography>{label}</Typography>
      </MenuItem>
    </FileDropzone>
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        startIcon={<DriveFolderUploadIcon />}
        css={[styles.width100, styles.widthLimit15]}
      >
        {t("Upload File")}
      </Button>

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {renderDropzoneMenuItem(
          t("Upload Video"),
          <VideoLibraryIcon sx={{ mr: 1 }} />,
          "video"
        )}
        {renderDropzoneMenuItem(
          t("Upload Photo"),
          <PhotoLibraryIcon sx={{ mr: 1 }} />,
          "image"
        )}
      </Menu>
    </>
  );
};

export default UploadHelpFile;
