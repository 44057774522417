import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../Global/Types/commonTypes";
import { useEffect, useState } from "react";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import Alert from "../../Components/MaterialUI/Alert";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  getQueryElpromCurrencies,
  getQueryElpromCurrencySync,
} from "./API/apiElpromGetQueries";
import { ElpromCurrency } from "./API/apiElpromSnippets";
import { PostQueryElpromCurrenciesInput } from "./API/apiElpromInputs";
import { postQueryElpromCurrencies } from "./API/apiElpromPostQueries";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { TableGridConfiguration } from "../../Components/SmallComponents/TableGrid/tableGridUtils";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import Autocomplete from "../../Components/MaterialUI/FormFields/Autocomplete";

const cellWidth = 250;

const colSchema: TableGridColumnSchema[] = [
  { id: "code", label: "Code", type: "string", disableEditing: true, width: cellWidth },
  { id: "id", label: "ID", type: "string", disableEditing: true, width: cellWidth },
  {
    id: "rate",
    label: "Rate",
    type: "number",
    width: cellWidth,
    formatNumb: true,
    formatNumbDecimals: 4,
  },
  { id: "auto_update", label: "Auto Updated", type: "boolean", width: cellWidth },
  {
    id: "last_updated",
    label: "Last Updated",
    type: "dateTime",
    disableEditing: true,
    width: cellWidth,
  },
  { id: "name", label: "Name", type: "string", disableEditing: true, width: cellWidth },
];

const configuration: TableGridConfiguration = {
  density: "compact",
  initialRowsPerPage: 15,
  columnVisibility: { id: false },
  hideAddButton: true,
  hideDeleteRowButton: true,
  pinnedRows: {
    top: [
      "ffbb7017-82d7-4a7a-bc7d-002603ee61c8",
      "af3614e2-b756-4473-b238-7049c61cb112",
      "a4bfc096-605d-478d-9a45-3a9c7adbf2fd",
      "2bff1b85-74b6-4862-bac4-313f243f132a",
    ],
    bottom: [],
  },
};

const CurrenciesPage: React.FC = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const [syncStatus, setSyncStatus] = useState<FormStatuses>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rows, setRows] = useState<ElpromCurrency[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [selectedCurr, setSelectedCurr] = useState<string>("BGN");

  const options: SelectOption[] = handleGetSelectOption(rows.map((item) => item.code));

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      await handleFetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurr]);

  const handleFetch = async () => {
    try {
      setPageStatus("loading");

      const currenciesData = await callApi<ElpromCurrency[]>({
        query: getQueryElpromCurrencies(selectedCurr),
        auth: { setAuthedUser },
      });
      setRows(currenciesData);

      setPageStatus(null);
    } catch (err) {
      console.log("err ", err);
      setPageStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleCurrencySync = async () => {
    try {
      setAlertMessage(null);
      setSyncStatus("loading");

      await callApi({
        query: getQueryElpromCurrencySync(),
        auth: { setAuthedUser },
      });
      await handleFetch();

      setSyncStatus(null);
    } catch (err) {
      setSyncStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleSaveCurrencies = async (
    _: ElpromCurrency[],
    editedRows: ElpromCurrency[]
    // updatedConfig?: TableGridConfiguration
  ) => {
    try {
      setAlertMessage(null);
      setSyncStatus(null);
      setTableLoading(true);

      // @ts-ignore
      const input: PostQueryElpromCurrenciesInput = editedRows.map((item) => ({
        code: item.code,
        rate_to_bgn: item.rate,
        auto_update: item.auto_update,
        name: item.name,
      }));

      await callApi({
        query: postQueryElpromCurrencies(input),
        auth: { setAuthedUser },
      });
      await handleFetch();
      setEditMode(false);
    } catch (err) {
      console.log("err ", err);
      setSyncStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
    setTableLoading(false);
  };

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.labelBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Typography css={styles.textBreak} variant="h2" textAlign="center">
          {t("Currencies")}
        </Typography>

        <Stack spacing={1} alignItems="center">
          <IconButton
            aria-label="sync currencies"
            onClick={handleCurrencySync}
            disabled={syncStatus === "loading" || pageStatus === "loading" || editMode}
          >
            <PublishedWithChangesIcon color="primary" />
          </IconButton>
        </Stack>
      </Stack>
      <Alert
        css={[styles.widthLimit20]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={syncStatus || pageStatus}
      />

      <Box css={[styles.flexCenter, styles.contentBreak]} component="div">
        <Autocomplete
          css={[styles.widthLimit10, styles.width100]}
          value={{ value: selectedCurr, description: selectedCurr }}
          handleOnChange={(val: AutocompleteOption) => setSelectedCurr(val.value)}
          options={options}
          label="Rate to Currency"
        />
      </Box>

      {rows.length ? (
        <ResponsiveTableGrid
          rows={rows}
          colSchema={useTranslateFields(colSchema, ["label"])}
          responsive="desktop"
          configuration={configuration}
          setEditMode={setEditMode}
          editMode={editMode}
          onSave={handleSaveCurrencies}
          setRows={setRows}
          backdropLoading={tableLoading}
        />
      ) : null}
    </Box>
  );
};

export default CurrenciesPage;
