import {
  TableGridConfiguration,
  TableGridHandleSaveCellChanges,
  tableGridAddNewRowData,
} from "./tableGridUtils";
import { useState, useCallback, useEffect, useMemo } from "react";
import { Box, useMediaQuery } from "@mui/material";
import TableGrid from "./TableGrid";
import { SerializedStyles } from "@emotion/react";
import MobileTableGrid from "./MobileTableGrid/MobileTableGrid";
import {
  MobileTableGridFormattedRow,
  mobileTableGridHandleFormatRows,
} from "./MobileTableGrid/mobileTableGridUtils";
import constructTableGridColumns, { TableGridColumnSchema } from "./constructTableGrid";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";

interface ResponsiveTableGridProps<T extends Record<string, any>> {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  rows: T[];
  setRows?: React.Dispatch<React.SetStateAction<T[]>>;
  colSchema: TableGridColumnSchema[];
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  responsive: "mobile" | "desktop" | "responsive";
  configuration?: TableGridConfiguration;
  isStatic?: boolean;
  onRowClick?: (row: T) => void;
  loading?: boolean;
  backdropLoading?: boolean;
  onSave?: (
    rows: T[],
    editedRows: T[],
    updatedConfig?: TableGridConfiguration
  ) => Promise<void>;
}

const ResponsiveTableGrid = <T extends Record<string, any>>({
  className,
  rows,
  setRows,
  colSchema,
  editMode,
  setEditMode,
  responsive,
  configuration,
  isStatic,
  onRowClick,
  loading,
  backdropLoading,
  onSave,
}: ResponsiveTableGridProps<T>) => {
  const [internalRows, setInternalRows] = useState<T[]>(rows); // Current rows
  const [_, setEditedRows] = useState<T[]>([]); // Track edited rows
  const [editedRowIndex, setEditedRowIndex] = useState<number | null>(null);
  const [configurationState, setConfigurationState] = useState<
    TableGridConfiguration | undefined
  >(configuration);
  const mdMediaQuery = useMediaQuery("(max-width:899px)");

  useEffect(() => {
    setInternalRows(() => rows);
  }, [rows]);

  useEffect(() => {
    // reset edited rows on mode change
    setEditedRows(() => []);
  }, [editMode]);

  // Compare rows to detect changes
  useEffect(() => {
    handleCompareEditedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalRows]);

  const handleCompareEditedRows = () => {
    const newEditedRows = internalRows.filter((row, index) => {
      // Compare each row with the original row at the same index
      return JSON.stringify(row) !== JSON.stringify(rows[index]);
    });
    setEditedRows(newEditedRows);
    return newEditedRows;
  };

  const handleSaveButton = async () => {
    if (setRows) {
      if (onSave) {
        // func call needed, otherwise, the last change won't
        // be detected
        const newEditedRows = handleCompareEditedRows();
        await onSave(internalRows, newEditedRows, configurationState);
      }
      setRows(() => internalRows);
      // reset edited rows on save changes
      setEditedRows(() => []);
    }
  };

  const handleSaveCellChanges: TableGridHandleSaveCellChanges = useCallback(
    (rowIndex, colKey, value) => {
      setEditedRowIndex(rowIndex);
      setInternalRows((prev) => {
        const copyRows = structuredClone(prev);
        copyRows[rowIndex][colKey as keyof T] = value;
        return copyRows;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(() => {
    return constructTableGridColumns(colSchema, handleSaveCellChanges);
  }, [handleSaveCellChanges, colSchema]);

  const [mobileRows, setMobileRows] = useState<MobileTableGridFormattedRow[]>(() =>
    mobileTableGridHandleFormatRows(internalRows, columns)
  );

  useEffect(() => {
    setMobileRows(() => mobileTableGridHandleFormatRows(internalRows, columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalRows, columns]);

  const handleDeleteRow = useCallback((rowIndex: number) => {
    setInternalRows((prev) => {
      const copyRows = structuredClone(prev);
      copyRows.splice(rowIndex, 1);
      return copyRows;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewRow = useCallback(() => {
    setInternalRows((prev) => {
      const newRow: T = columns.reduce((acc, curr) => {
        const colData = curr.columns?.length
          ? curr.columns.reduce((colAcc, colCurr) => {
              const nestedColData = tableGridAddNewRowData(colCurr);
              return {
                ...colAcc,
                ...nestedColData,
              };
            }, {})
          : tableGridAddNewRowData(curr);

        return {
          ...acc,
          ...colData,
        };
      }, {} as T);

      return [newRow, ...prev];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const columnStyles = colSchema.map((col) => ({
    id: col.id,
    width: col.width ? `${col.width}px` : "auto",
  }));

  if (responsive === "mobile" || (responsive === "responsive" && mdMediaQuery)) {
    return (
      <Box component="div" style={{ position: "relative" }}>
        <MobileTableGrid
          className={className}
          mobileRows={mobileRows}
          columns={columns}
          editMode={
            editMode
              ? {
                  handleDeleteRow: handleDeleteRow,
                  handleAddNewRow: handleAddNewRow,
                  handleSaveCellChanges: handleSaveCellChanges,
                  handleSaveButton: handleSaveButton,
                }
              : undefined
          }
          editedRowIndex={editedRowIndex}
          configuration={configurationState}
          loading={loading}
        />
        <LoadingBackdrop loading={!!backdropLoading} />
      </Box>
    );
  }

  return (
    <Box component="div" style={{ position: "relative" }}>
      <TableGrid
        className={className}
        rows={internalRows}
        columns={columns}
        editMode={
          editMode
            ? {
                handleDeleteRow: handleDeleteRow,
                handleAddNewRow: handleAddNewRow,
                handleSaveCellChanges: handleSaveCellChanges,
                handleSaveButton: handleSaveButton,
              }
            : undefined
        }
        setEditMode={setEditMode}
        configuration={configurationState}
        setConfiguration={setConfigurationState}
        isStatic={isStatic}
        // @ts-ignore
        onRowClick={onRowClick}
        loading={loading}
        columnStyles={columnStyles}
      />
      <LoadingBackdrop loading={!!backdropLoading} />
    </Box>
  );
};

export default ResponsiveTableGrid;
